import { useEffect, useMemo, useState } from "react"
import { DROPSTATUS, DropType } from "../types/DropType/DropType"
import { getCountdownValues } from "../utils/getCountdownValues"
import { Flowty } from "flowty-sdk"
import { FlowNFTContract } from "flowty-common"

interface DropPageInterface {
	flowTokenBalance: number
	dropData: DropType
	flowty: Flowty
	loggedUser: string | undefined
}

export interface TimeSegmentsTypes {
	days?: number
	hours?: number
	mins?: number
	secs?: number
}

interface DropPageValues {
	closeModal: () => void
	countdownCurrentValue: TimeSegmentsTypes
	decreaseMintCount: () => void
	dropEndDateFormat: string
	dropPhasePrice: string
	dropStartDateFormat: string
	dropStartDate: string
	dropStatus: DROPSTATUS
	increaseMintCount: () => void
	isDropOwner: boolean
	isModalOpen: boolean
	maxPerMint: number
	mintCount: number
	openModal: () => void
	setShowNoFundsMessage: (value: boolean) => void
	showNoFundsMessage: boolean
	updateMintCount: (value: number) => void
}

export const useDropPage = ({
	flowTokenBalance,
	dropData,
	flowty,
	loggedUser,
}: DropPageInterface): DropPageValues => {
	const firstPhase = dropData?.phases?.[0]
	const maxPerMint = Number(firstPhase?.maxPerMint)

	const [isModalOpen, setIsModalOpen] = useState(false)
	const [mintCount, setMintCount] = useState(1)
	const [showNoFundsMessage, setShowNoFundsMessage] = useState(false)
	const [phaseStarted, setPhaseStarted] = useState(firstPhase?.hasStarted)
	const [phaseEnded, setPhaseEnded] = useState(firstPhase?.hasEnded)
	const [isDropOwner, setIsDropOwner] = useState(false)

	const dropStartDate = firstPhase?.start
	const dropEndDate = firstPhase?.end

	const dropStatus: DROPSTATUS = useMemo(() => {
		if (!firstPhase) {
			return "NO_PHASES"
		}

		if (!phaseStarted && !phaseEnded) {
			return "PENDING"
		}

		if (phaseStarted && !phaseEnded) {
			if (!firstPhase.start && !firstPhase?.end) {
				return "NEVER_ENDING"
			} else {
				return "ACTIVE"
			}
		}

		return "ENDED"
	}, [dropData, phaseStarted, phaseEnded])

	const dropStateDate = useMemo(() => {
		return dropStatus === "PENDING"
			? new Date(+dropStartDate).getTime() * 1000
			: new Date(+dropEndDate).getTime() * 1000
	}, [dropStartDate, dropEndDate, dropStatus])

	const [countDown, setCountDown] = useState(
		dropStateDate - new Date().getTime()
	)

	useEffect(() => {
		if (dropStatus === "ENDED" || dropStatus === "NEVER_ENDING") {
			return
		}
		const interval = setInterval(() => {
			const countDownValue = dropStateDate - new Date().getTime()
			setCountDown(countDownValue)
			if (countDownValue <= 1000) {
				if (dropStatus === "PENDING") {
					setPhaseStarted(true)
					setCountDown(
						new Date(+dropEndDate).getTime() * 1000 - new Date().getTime()
					)
				} else {
					setPhaseEnded(true)
				}
			}
		}, 1000)

		return () => clearInterval(interval)
	}, [dropStateDate, countDown, dropStatus])

	const countdownCurrentValue = useMemo(() => {
		return getCountdownValues(countDown)
	}, [countDown])

	function formatDate(unixTimeStamp: number): string {
		const date = new Date(unixTimeStamp * 1000)

		const getOrdinalSuffix = (dateValue: number): string => {
			if (dateValue > 3 && dateValue < 21) return "th"
			switch (dateValue % 10) {
				case 1:
					return "st"
				case 2:
					return "nd"
				case 3:
					return "rd"
				default:
					return "th"
			}
		}

		const day = date.getDate()
		const dateOptions = {
			day: "numeric",
			month: "long",
		} as const
		const timeOptions = {
			hour: "2-digit",
			hour12: true,
			minute: "2-digit",
			timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
			timeZoneName: "short",
		} as const

		const formattedDate = new Intl.DateTimeFormat("en-US", dateOptions)?.format(
			date
		)
		const formattedTime = new Intl.DateTimeFormat("en-US", timeOptions)?.format(
			date
		)

		const dateWithSuffix = formattedDate.replace(
			/\d+/,
			`${day}${getOrdinalSuffix(day)}`
		)

		return `${dateWithSuffix} at ${formattedTime.replace(/^0/, "")}`
	}

	const dropStartDateFormat = dropStartDate
		? formatDate(Number(dropStartDate))
		: ""

	const dropEndDateFormat = dropEndDate ? formatDate(Number(dropEndDate)) : ""

	const dropPhasePrice = firstPhase?.quote?.price

	const insufficientFunds = useMemo(() => {
		return flowTokenBalance < Number(dropPhasePrice) * Number(mintCount)
	}, [flowTokenBalance, dropPhasePrice, mintCount])

	useEffect(() => {
		if (insufficientFunds) {
			setShowNoFundsMessage(insufficientFunds)
		} else {
			setShowNoFundsMessage(false)
		}
	}, [flowTokenBalance, dropPhasePrice, mintCount])

	const openModal = (): void => {
		setIsModalOpen(true)
	}

	const updateMintCount = (value: number): void => {
		if (value > 0) {
			setMintCount(value)
		}
	}

	const closeModal = (): void => {
		updateMintCount(1)
		setIsModalOpen(false)
	}

	const increaseMintCount = (): void => {
		setMintCount(prev => prev + 1)
	}

	const decreaseMintCount = (): void => {
		if (mintCount === 1) return
		setMintCount(prev => (prev > 1 ? prev - 1 : prev))
	}

	type AccountContracts = {
		address: string
		contracts: FlowNFTContract[]
	}

	const checkIsLoggedInUserOwner = async (): Promise<void> => {
		try {
			const response = await flowty.scripts.getAccountCollections(
				loggedUser ?? ""
			)
			const res = response as AccountContracts
			const [_, address, name] = dropData.nftType.split(".") || []
			const userIsDropOwner =
				res.address === `0x${address}` &&
				res.contracts.some(
					contract =>
						contract?.collectionDisplay?.name?.replace(/\s+/g, "") === name
				)
			setIsDropOwner(userIsDropOwner)
		} catch (error) {
			console.log("error", error)
		}
	}

	useEffect(() => {
		checkIsLoggedInUserOwner()
	}, [loggedUser])

	return {
		closeModal,
		countdownCurrentValue,
		decreaseMintCount,
		dropEndDateFormat,
		dropPhasePrice,
		dropStartDate,
		dropStartDateFormat,
		dropStatus,
		increaseMintCount,
		isDropOwner,
		isModalOpen,
		maxPerMint,
		mintCount,
		openModal,
		setShowNoFundsMessage,
		showNoFundsMessage,
		updateMintCount,
	}
}
