import {
	OfferCreated,
	OpensearchFlowNFT,
	Order,
	OrdersType,
	SpotPrice,
	SupportedTokens,
	Valuation,
	InitiatedTransactionNotification,
	FlowNFT,
} from "flowty-common"
import { AccountSummaries, LoanRentalFilteredData, Flowty } from "flowty-sdk"
import { Formik } from "formik"
import React from "react"
import { PurchaseModalView } from "./components/PurchaseModalView/PurchaseModalView"
import { ModalWrapperPurchase } from "./components/common/ModalWrapper.Purchase"
import { FlowtyPurchaseModalContextProvider } from "./contexts/FlowtyPurchaseModalContext/FlowtyPurchaseModalContext"

export interface FlowtyPurchaseFormValues {
	automaticReturn: boolean
	offerAmount: number | string
	offerTokenType: SupportedTokens
	offerDuration: number
}

export interface FlowtyPurchaseModalProps {
	addressesWithCollectionPublic?: string[]
	isOpen: boolean
	isLoggedUser: boolean
	onClose: () => void
	singleListing?: Order
	nftOrders?: OrdersType | null
	isLoadingOrders?: boolean
	offer?: OfferCreated
	flowNft?: FlowNFT | null
	singleOffer?: "make-offer" | "cancel-offer"
	nftProviderPathIdentifier: string
	openSearchFlowNFT: OpensearchFlowNFT
	accountSummaries: AccountSummaries
	hasProvider: boolean
	purchaseType?: "purchase" | "loan" | "rental"
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	mixPanelFn: (event: string, data: any) => void
	createTransactionNotification?: (
		data: InitiatedTransactionNotification
	) => void
	valuation: Valuation
	isLoadingValuation: boolean
	spotPrice?: SpotPrice
	strapiUrl: string
	collectionImage: string | null
	loanRentalActionsData?: LoanRentalFilteredData
	flowty: Flowty
}

export const FlowtyPurchaseModal: React.FunctionComponent<
	FlowtyPurchaseModalProps
> = ({
	addressesWithCollectionPublic,
	isOpen,
	isLoggedUser,
	onClose,
	flowNft,
	openSearchFlowNFT,
	accountSummaries,
	purchaseType,
	mixPanelFn,
	hasProvider,
	createTransactionNotification,
	valuation,
	isLoadingValuation,
	singleListing,
	nftOrders,
	nftProviderPathIdentifier,
	collectionImage,
	isLoadingOrders,
	offer,
	singleOffer,
	spotPrice,
	strapiUrl,
	loanRentalActionsData,
	flowty,
}) => {
	const accountSummariesValues = Object.values(accountSummaries || {})
	const mainAccount = accountSummariesValues?.find(acct => acct.isMain)

	return (
		<Formik
			initialValues={
				{
					automaticReturn: false,
					offerAmount: "",
					offerDuration: 30,
					offerTokenType: mainAccount?.isDapper ? "DUC" : "USDC",
				} as FlowtyPurchaseFormValues
			}
			onSubmit={() => {}}
			validate={() => {}}
		>
			{({ values, resetForm }) => {
				return (
					<FlowtyPurchaseModalContextProvider
						createTransactionNotification={createTransactionNotification}
						accountSummaries={accountSummaries}
						collectionImage={collectionImage}
						addressesWithCollectionPublic={addressesWithCollectionPublic}
						isLoggedUser={isLoggedUser}
						resetForm={resetForm}
						nftOrders={nftOrders}
						singleListing={singleListing}
						offer={offer}
						singleOffer={singleOffer}
						purchaseType={purchaseType}
						values={values}
						mainAccount={mainAccount}
						nftProviderPathIdentifier={nftProviderPathIdentifier}
						openSearchFlowNFT={openSearchFlowNFT}
						flowNft={flowNft}
						valuation={valuation}
						isLoadingValuation={isLoadingValuation}
						onClose={onClose}
						spotPrice={spotPrice}
						strapiUrl={strapiUrl}
						mixPanelFn={mixPanelFn}
						isLoadingOrders={isLoadingOrders}
						loanRentalActionsData={loanRentalActionsData}
						flowty={flowty}
					>
						<ModalWrapperPurchase
							isOpen={isOpen}
							openSearchFlowNFT={openSearchFlowNFT}
							hasProvider={hasProvider}
							orderContent={
								<PurchaseModalView
									accountSummaries={accountSummaries}
									singleListing={singleListing}
									nftOrders={nftOrders}
									flowty={flowty}
									strapiUrl={strapiUrl}
								/>
							}
							mixPanelFn={mixPanelFn}
						/>
					</FlowtyPurchaseModalContextProvider>
				)
			}}
		</Formik>
	)
}
