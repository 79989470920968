import { CreatorHubTooltipIcon } from "../../../../../../../FlowtyCreatorHub/components/common/CreatorHubTooltipIcon/CreatorHubTooltipIcon"
import { CreatorHubUploadImage } from "../../../../../../../FlowtyCreatorHub/components/common/CreatorHubUploadImage/CreatorHubUploadImage"
import { FlowtyCreatorHubFormValues } from "../../../../../../../FlowtyCreatorHub/types/FlowtyCreatorHubTypes"
import {
	renderErrorMessage,
	renderInputError,
} from "../../../../../../../FlowtyCreatorHub/utils/formValidation"
import { useFlowtyCreatorHubContext } from "../../../../../../contexts/FlowtyCreatorHubContext"
import { CustomizeSocialIcons } from "./CustomizeSocialIcons/CustomizeSocialIcons"

export const CustomizeSecondStep: React.FunctionComponent = () => {
	const { errors, touched, uploadImage, setFieldError, loggedUser } =
		useFlowtyCreatorHubContext()

	return (
		<div className='flex flex-col gap-[16px] 3xl:gap-[24px] '>
			<div className='flex flex-1 w-full'>
				<CreatorHubUploadImage
					title={"Banner Image*"}
					name='nftBannerImage'
					imageType={"BANNER"}
					toolTip={
						<CreatorHubTooltipIcon
							toolTipCustomId='nftBannerImage'
							renderContent={
								<span>
									Currently, this is not displayed on the marketplace, but this
									may change in the future
								</span>
							}
						/>
					}
					inputError={renderInputError<FlowtyCreatorHubFormValues>(
						"nftBannerImage",
						touched,
						errors
					)}
					errorMessage={renderErrorMessage<FlowtyCreatorHubFormValues>(
						"nftBannerImage",
						touched,
						errors
					)}
					uploadImageFn={uploadImage}
					setFieldError={setFieldError}
					isLoggedUser={Boolean(loggedUser)}
				/>
			</div>
			<div className='flex flex-1 w-full'>
				<CreatorHubUploadImage
					title={"Thumbnail Image*"}
					name='nftThumbnailImage'
					imageType={"THUMBNAIL"}
					inputError={renderInputError<FlowtyCreatorHubFormValues>(
						"nftThumbnailImage",
						touched,
						errors
					)}
					errorMessage={renderErrorMessage<FlowtyCreatorHubFormValues>(
						"nftThumbnailImage",
						touched,
						errors
					)}
					uploadImageFn={uploadImage}
					setFieldError={setFieldError}
					isLoggedUser={Boolean(loggedUser)}
				/>
			</div>
			<div className='flex items-start justify-start'>
				<CustomizeSocialIcons />
			</div>
		</div>
	)
}
