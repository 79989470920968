/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from "react"
import SortableTable from "../../../../components/SortableTable"
import { Transactions } from "./UserActivityTab"
import { flowscanBaseURL } from "../../../../util/settings"

interface TransactionsTableProps {
	transactions: Transactions[]
}

export const TransactionTable: React.FC<TransactionsTableProps> = ({
	transactions,
}) => {
	const fields = [
		{
			customRender: ({ item }: any) => {
				const date = new Date(item.blockTimestamp)
				return (
					<div className='flex flex-row justify-start items-center'>
						{date.toDateString()}
					</div>
				)
			},
			name: "blockTimestamp",
			sortable: false,
			title: "Date",
		},
		{
			customRender: ({ item }: any) => {
				const nfts = item.collections?.map(
					(collection: string, index: number) => {
						const name = collection.split(".").slice(-1)
						const id = item.nftIDs[index].split(".").slice(-1)
						return `${name} #${id}`
					}
				)

				return (
					<div className='flex flex-row justify-start items-center'>
						{nfts?.length && nfts?.length > 1
							? "Multiple Items"
							: (nfts && nfts[0]) || null}
					</div>
				)
			},
			name: "nftData",
			sortable: false,
			title: "Item",
		},
		{
			customRender: ({ item }: any) => {
				const { events } = item
				const eventNames = events.reduce((result: any, event: any) => {
					const copyResult = { ...result }
					const eventProperty = copyResult[event.type.split(".").slice(-1)]
					if (eventProperty) {
						copyResult[event.type.split(".").slice(-1)] += 1
					} else {
						copyResult[event.type.split(".").slice(-1)] = 1
					}
					return copyResult
				}, {})
				const EventDetail: React.FunctionComponent = () => {
					const [show, setShow] = useState(false)

					return (
						<>
							<button
								className='drop-down text-white bg-blue-500 hover:bg-blue-600 rounded-md px-3 flex justify-around items-center'
								onClick={_ => setShow(true)}
								onBlur={_ => setShow(false)}
							>
								{Object.keys(eventNames)[0]}
							</button>
							{show && (
								<div
									onClick={() => setShow(false)}
									onKeyDown={event => {
										if (event.key === "Enter") {
											setShow(false)
										}
									}}
									className='drop-down absolute z-10 bg-black rounded border-2 border-white border-r-8 h-36 flex items-center overflow-hidden'
								>
									<ul className='drop-down overflow-y-auto px-2 py-3 overflow-x-hidden max-h-32'>
										{Object.keys(eventNames).map(event => (
											<li
												className='drop-down'
												key={event}
											>{`${eventNames[event]}x ${event} `}</li>
										))}
									</ul>
								</div>
							)}
						</>
					)
				}
				return (
					<div className='flex flex-row h-full w-full justify-center items-center  relative'>
						<EventDetail />
					</div>
				)
			},
			name: "events",
			sortable: false,
			title: "Event",
		},
	]

	return (
		<SortableTable
			fields={fields}
			items={transactions}
			composeRowHref={item => `${flowscanBaseURL}/${item.id}`}
			onClickRow={({ e, item }) => {
				const event = e as any
				if (event.target.classList[0] === "drop-down") return
				window.open(`${flowscanBaseURL}/${item.id}`, "_blank")
			}}
		/>
	)
}
