import {
	ExternalURLWarning,
	TraitGrid,
	TraitWithUrl,
	Traitbox,
} from "ds-flowty"
import {
	OpensearchFlowNFT,
	nftTypeToContractID,
	nftTypeAndIdToLocationData,
	getNFTIdentifier,
} from "flowty-common"
import React, { useCallback, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { Tooltip } from "react-tooltip"
import { ReactComponent as ExternalLink } from "../../assets/media/external-link.svg"
import { ReactComponent as RefreshArrow } from "../../assets/media/loadingArrow.svg"
import { useFetchTraitData } from "../../hooks/useFetchTraitData"
import { useSortTraits } from "../../hooks/useSortTraits"
import { OpensearchNftOrders } from "../../screens/AssetScreen/AssetScreen"
import { actions as Mixpanel } from "../../util/Mixpanel"
import { cn } from "../../util/cn"
import { generateTraitUrl } from "../../util/generateTraitUrl"
import { getTraitDisplayValue } from "../../util/getTraitDisplayValue"
import { AssetDisplay } from "../Shared/AssetDisplay/AssetDisplay"
import { SnackbarAlert } from "../Shared/Snackbar/SnackbarAlert"
import { AvailableListingsPillsList } from "./Components/AvailableListingsPillsList/AvailableListingsPillsList"
import { CollectionPreviewDisplay } from "./Components/CollectionDisplay/CollectionDisplay"
import { flowty } from "../../config/config"

interface NftDetailViewProps {
	asset: OpensearchFlowNFT
	orders: OpensearchNftOrders
}

export const NftDetailView: React.FC<NftDetailViewProps> = ({
	asset,
	orders,
}) => {
	const [isNftLoading, setIsNftLoading] = useState(false)
	const [isRefreshToolTipOpen, setIsRefreshToolTipOpen] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)

	const { collectionAddress, collectionName } = useParams()

	const [isExternalLinkToolTipOpen, setIsExternalLinkToolTipOpen] =
		useState(false)

	const refreshNft = useCallback(() => {
		const locationData = nftTypeAndIdToLocationData(asset.type, asset.id)
		const nftFullID = getNFTIdentifier(locationData)
		setIsNftLoading(true)
		flowty.api
			.refreshMetadata(nftFullID)
			.then(() => Mixpanel.track("REFRESH_NFT_METADATA_SUCCESS"))
			.catch(err => Mixpanel.track("REFRESH_NFT_METADATA_FAILED", err))
	}, [])

	const { traitDataLoading, collectionTraits } = useFetchTraitData({
		contractID: nftTypeToContractID(asset.type),
	})

	const sortedTraits = useSortTraits({
		collectionTraits,
		isLoading: traitDataLoading,
		traits: asset.nftView?.traits?.traits ?? [],
	}).map(trait => {
		const traitDisplayValue = getTraitDisplayValue(trait)

		return {
			...trait,
			url: generateTraitUrl({
				collectionAddress: collectionAddress ?? "",
				collectionName: collectionName ?? "",
				trait,
				traitDisplayValue: traitDisplayValue ?? "",
			}),
		}
	})

	return (
		<>
			<div className='flex flex-col mt-[1rem] lg:mt-[3rem]'>
				<div className='grid lg:flex lg:flex-row lg:gap-x-[38px] rounded-md bg-[#606e7d33] py-[18px] px-[32px] md:p-[2rem]'>
					<>
						<div className='flex flex-col lg:hidden mb-[1.4rem] gap-[0.5rem]'>
							<span className='font-extrabold font-montserrat text-[1.25rem]'>
								{asset.card?.title}
							</span>
							<span className='font-bold font-montserrat text-[1rem] text-[#C1C1C1]'>
								{asset.card?.additionalDetails}
							</span>
						</div>
						<div className='xl:w-[398px] xl:h-[398px] max-h-[25rem] md:w-[25rem] mx-auto flex justify-center'>
							<AssetDisplay
								asset={asset}
								classNames='rounded-[0.625rem] w-full md:w-auto md:m-auto h-auto max-h-full'
							/>
						</div>
						<div className='flex flex-col flex-1 !leading-[18px]'>
							<div>
								<div className='flex flex-row items-center justify-between w-full'>
									<span className='font-extrabold font-montserrat text-[1.5rem] hidden lg:block'>
										{asset.card?.title}
									</span>
									<div className='flex pt-[16px] lg:pt-[0px]'>
										<div
											data-tooltip-place='top'
											onMouseEnter={() => setIsRefreshToolTipOpen(true)}
											onMouseLeave={() => setIsRefreshToolTipOpen(false)}
											data-tooltip-id={`${asset.id}-refresh-tooltip`}
											onClick={() => refreshNft()}
											className='border-[1px] border-opacity-25 border-[#7c7c7c] group cursor-pointer bg-[#FFFFFF] backdrop-blur-sm p-2 mx-1 rounded-md hover:bg-opacity-[15%] bg-opacity-[8%]'
										>
											<RefreshArrow
												className={cn("w-6 h-6", {
													"animate-spin": isNftLoading,
												})}
											/>
										</div>
										{asset?.nftView?.externalURL && (
											<button onClick={() => setIsModalOpen(true)}>
												<div
													data-tooltip-place='top'
													onMouseEnter={() =>
														setIsExternalLinkToolTipOpen(true)
													}
													onMouseLeave={() =>
														setIsExternalLinkToolTipOpen(false)
													}
													data-tooltip-id={`${asset.id}-external-tooltip`}
													className='border-[1px] border-opacity-25 border-[#7c7c7c] group cursor-pointer bg-[#FFFFFF] backdrop-blur-sm p-2 mx-1 rounded-md hover:bg-opacity-[15%] bg-opacity-[8%]'
												>
													<ExternalLink className='w-6 h-6' />
												</div>
											</button>
										)}
									</div>
								</div>
								<span className='font-bold font-montserrat text-[1.125rem] text-[#C1C1C1] hidden lg:block mt-2 lg:mt-0'>
									{asset.card?.additionalDetails}
								</span>
							</div>
							<div className='flex flex-row items-center my-[1.4rem] flex-wrap gap-y-[0.25rem] md:gap-y-[0rem]'>
								<div className='flex flex-col md:flex-row flex-wrap'>
									<div className='flex flex-col'>
										<p className='font-montserrat text-[1rem] text-[#C1C1C1]'>
											Collection
										</p>
										<CollectionPreviewDisplay />
									</div>
									<div className='horizontal-border-gradient mx-[1.75rem]'></div>
									<div className='flex flex-col'>
										<p className='font-montserrat text-[1rem] text-[#C1C1C1]'>
											Owned By
										</p>
										{asset.owner && (
											<Link
												to={`/user/${asset.owner}`}
												className='text-primary text-[1rem] md:text-[1.125rem] leading-8 font-bold font-montserrat truncate max-w-[110px] sm:max-w-full'
											>
												{asset.owner}
											</Link>
										)}
									</div>

									<div className='horizontal-border-gradient mx-[1.75rem]'></div>
									<div className='flex flex-col'>
										<p className='font-montserrat text-[1rem] text-[#C1C1C1]'>
											Available Listings
										</p>
										<div>
											<AvailableListingsPillsList
												nft={asset}
												nftId={asset.id?.toString()}
												orders={orders}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className='flex flex-col'>
								<p className='font-montserrat text-[1rem] text-[#C1C1C1] mb-[0.5rem]'>
									Properties
								</p>
								<TraitGrid
									traits={sortedTraits as TraitWithUrl[]}
									customRender={item => (
										<Link to={item.url} key={item.name}>
											<Traitbox trait={item} />
										</Link>
									)}
								/>
							</div>
						</div>
					</>
				</div>
				<SnackbarAlert
					type={"SUCCESS"}
					setShow={setIsNftLoading}
					show={isNftLoading}
					seconds={5}
					message={
						"Refreshing NFT's metadata. It may take a few minutes for our records to update."
					}
				/>
				<Tooltip
					id={`${asset?.id}-refresh-tooltip`}
					style={{
						backdropFilter: "blur(100px)",
						backgroundColor: "#33333380",
						zIndex: 999,
					}}
					isOpen={isRefreshToolTipOpen}
				>
					<p className='text-sm font-normal leading-none text-white'>
						Something look off? Refresh NFT
					</p>
				</Tooltip>
				<Tooltip
					id={`${asset?.id}-external-tooltip`}
					style={{
						backdropFilter: "blur(100px)",
						backgroundColor: "#33333380",
						zIndex: 999,
					}}
					isOpen={isExternalLinkToolTipOpen}
				>
					<p className='text-sm font-normal leading-none text-white'>
						{`View NFT on ${asset?.contractName}`}
					</p>
				</Tooltip>
			</div>
			<ExternalURLWarning
				isOpen={isModalOpen}
				linkURL={asset?.nftView?.externalURL?.url ?? ""}
				onCloseModal={() => setIsModalOpen(false)}
			/>
		</>
	)
}
