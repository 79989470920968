import { FlowtyButton } from "../../../../FlowtyButton"
import { CreatorHubNavProps } from "../../../../FlowtyCreatorHub/hooks/useCreatorHubModal"

interface CreatorHubModalNavViewProps {
	modalNavProps: CreatorHubNavProps
	isSignedIn: boolean
	signIn: () => void
}

export const CreatorHubModalNavView: React.FunctionComponent<
	CreatorHubModalNavViewProps
> = ({ modalNavProps, isSignedIn, signIn }) => {
	if (!modalNavProps) {
		return null
	}

	if (!isSignedIn) {
		return (
			<div className='w-full flex flex-col gap-1'>
				<FlowtyButton
					text={"Sign In"}
					onClick={signIn}
					variant='secondary'
					btnSize='large'
					disabled={false}
				/>
			</div>
		)
	}

	if (Array.isArray(modalNavProps)) {
		return (
			<div className='w-full flex items-center gap-[24px]'>
				{modalNavProps.map((btn, index) => (
					<FlowtyButton
						key={index}
						text={btn.text}
						onClick={btn.onClick}
						variant='secondary'
						btnSize='large'
						disabled={btn.disabled}
						bgColor={btn.bgColor}
					/>
				))}
			</div>
		)
	}

	return (
		<div className='w-full flex flex-col gap-1'>
			<FlowtyButton
				text={modalNavProps.text}
				onClick={modalNavProps.onClick}
				variant='secondary'
				btnSize='large'
				disabled={modalNavProps.disabled}
				className='h-[35px] flex items-center justify-center px-4'
			/>
		</div>
	)
}
