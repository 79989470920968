import { format } from "date-fns"
import { getImageURL, Trait } from "flowty-common"
import noImage from "../../../../assets/media/image_not_available.svg"
import { formatImageSource } from "../../../../util/nftDataUtil"
import { ActivityTableImage } from "../../../Shared/ActivityTableFallbackImage/ActivityTableImage"
import { ActivityItem } from "../../../Shared/ActivityTableFields"
import { SortableTableField } from "../../../SortableTable"
import { isFlowRewards } from "../../../../util/settings"

export const userActivityTableFields: Array<SortableTableField<ActivityItem>> =
	[
		{
			customRender: ({ item }) => (
				<div className='flex flex-row justify-start items-center capitalize'>
					{item.type.toLowerCase()}
				</div>
			),
			name: "status",
			sortable: false,
			title: "Status",
		},
		{
			customRender: ({ item }) => (
				<div className='flex flex-row justify-start items-center'>
					{item.activityType}
				</div>
			),
			name: "type",
			sortable: false,
			title: "Type",
		},
		{
			customRender: ({ item }) =>
				item.blockTimestamp ? (
					<div className='flex flex-row justify-start items-center'>
						{format(item.blockTimestamp.toDate(), "yyyy-MM-dd HH:mm")}
					</div>
				) : null,
			name: "date",
			sortable: false,
			title: "Date",
		},
		{
			customRender: ({ item }) => {
				const itemType = `A.${item.additionalData?.card?.collectionAddress?.substring(
					2
				)}.${item.additionalData?.card?.collectionName}.NFT`

				return (
					<div>
						{!!item.nftInfo?.nft && (
							<div className='flex flex-row justify-start items-center'>
								<div className='w-[40px] mr-2'>
									<ActivityTableImage
										collectionAddress={
											item?.nftInfo?.card?.collectionAddress || ""
										}
										collectionName={item?.nftInfo?.card?.collectionName || ""}
										srcImg={getImageURL(
											item?.nftInfo?.card?.images?.[0]?.url ?? noImage,
											isFlowRewards(itemType)
										)}
									/>
								</div>
								<div className='flex flex-col'>
									{item.nftInfo?.card?.additionalDetails ? (
										<span className='text-[12px] text-primary font-montserrat font-extrabold truncate'>
											{item.nftInfo?.card?.title}
										</span>
									) : (
										<>
											<span className='text-[12px] text-primary font-montserrat font-extrabold truncate'>
												{item.nftInfo?.card?.title}
											</span>
											{item.nftInfo?.card?.num &&
												!item.nftInfo?.card?.title.includes("#") && (
													<span className='text-[12px] text-primary font-montserrat font-extrabold ml-2'>
														#{item.nftInfo?.card?.num}
													</span>
												)}
										</>
									)}
									{item?.nftInfo?.card?.additionalDetails ? (
										<>
											{item?.nftInfo?.card?.additionalDetails ? (
												item?.nftInfo?.card?.additionalDetails?.map(
													(detail: string) => (
														<span
															key={detail}
															className='font-montserrat text-primary text-[12px] font-light'
														>
															{detail}
														</span>
													)
												)
											) : (
												<div className='flex flex-row items-center justify-start mt-2 h-4'></div>
											)}
										</>
									) : (
										<>
											{item?.nftInfo?.card?.headerTraits &&
												item?.nftInfo?.card?.headerTraits?.map(
													(trait: Trait) => (
														<span
															key={trait.displayType}
															className='font-montserrat text-primary text-[12px] font-light'
														>
															{trait.value}
														</span>
													)
												)}
										</>
									)}
								</div>
							</div>
						)}
					</div>
				)
			},
			name: "detail",
			sortable: false,
			title: "Details",
		},
	]
