import { CreatorHubFieldInput } from "../../../../../../../FlowtyCreatorHub/components/common/CreatorHubFieldInput/CreatorHubFieldInput"
import { CreatorHubTooltipIcon } from "../../../../../../../FlowtyCreatorHub/components/common/CreatorHubTooltipIcon/CreatorHubTooltipIcon"
import { CreatorHubUploadImage } from "../../../../../../../FlowtyCreatorHub/components/common/CreatorHubUploadImage/CreatorHubUploadImage"
import { useFlowtyCreatorHubContext } from "../../../../../../../FlowtyCreatorHub/contexts/FlowtyCreatorHubContext"
import { FlowtyCreatorHubFormValues } from "../../../../../../../FlowtyCreatorHub/types/FlowtyCreatorHubTypes"
import {
	renderErrorMessage,
	renderInputError,
} from "../../../../../../../FlowtyCreatorHub/utils/formValidation"
import { CustomizeNumberInput } from "../CustomizeNumberInput/CustomizeNumberInput"

export const CustomizeFirstStep: React.FunctionComponent = () => {
	const {
		errors,
		touched,
		uploadImage,
		setFieldError,
		loggedUser,
		flowtyMarketplaceFee,
	} = useFlowtyCreatorHubContext()

	return (
		<div className='flex flex-col gap-[16px] 3xl:gap-[24px] items-center justify-center'>
			<div className='flex flex-1 w-full'>
				<CreatorHubUploadImage
					title={"Upload NFT Image*"}
					name='nftImage'
					inputError={renderInputError<FlowtyCreatorHubFormValues>(
						"nftImage",
						touched,
						errors
					)}
					errorMessage={renderErrorMessage<FlowtyCreatorHubFormValues>(
						"nftImage",
						touched,
						errors
					)}
					imageType={"THUMBNAIL"}
					uploadImageFn={uploadImage}
					setFieldError={setFieldError}
					isLoggedUser={Boolean(loggedUser)}
				/>
			</div>
			<CreatorHubFieldInput
				title={"Collection Name*"}
				name='collectionName'
				className={`h-[26.667px] 3xl:h-[40px] w-full text-xs 3xl:text-lg bg-[#04070B] border border-[#6C757D] font-semibold rounded-[4px] 3xl:rounded-md px-3 focus:outline-none text-white shrink-0 ${
					renderInputError<FlowtyCreatorHubFormValues>(
						"collectionName",
						touched,
						errors
					)
						? "border-[#FF6E25]"
						: "border-[#6C757D]"
				}`}
				inputError={renderInputError<FlowtyCreatorHubFormValues>(
					"collectionName",
					touched,
					errors
				)}
				errorMessage={renderErrorMessage<FlowtyCreatorHubFormValues>(
					"collectionName",
					touched,
					errors
				)}
			/>
			<CreatorHubFieldInput
				title='Description*'
				name='collectionDescription'
				className={`h-[26.667px] 3xl:h-[40px] w-full text-xs 3xl:text-lg bg-[#04070B] border border-[#6C757D] font-semibold rounded-[4px] 3xl:rounded-md px-3 focus:outline-none text-white shrink-0 ${
					renderInputError<FlowtyCreatorHubFormValues>(
						"collectionDescription",
						touched,
						errors
					)
						? "border-[#FF6E25]"
						: "border-[#6C757D]"
				}`}
				inputError={renderInputError<FlowtyCreatorHubFormValues>(
					"collectionDescription",
					touched,
					errors
				)}
				errorMessage={renderErrorMessage<FlowtyCreatorHubFormValues>(
					"collectionDescription",
					touched,
					errors
				)}
			/>
			<CustomizeNumberInput
				inputName='mintPrice'
				type='flow'
				dropDownText='Funds received per minted NFT'
				title='Mint Price'
				inputError={renderInputError<FlowtyCreatorHubFormValues>(
					"mintPrice",
					touched,
					errors
				)}
				errorMessage={renderErrorMessage<FlowtyCreatorHubFormValues>(
					"mintPrice",
					touched,
					errors
				)}
				placeHolder='0.00'
				toolTip={
					<CreatorHubTooltipIcon
						toolTipCustomId='cratorMintTooltip'
						renderContent={
							<span>
								Price per NFT that a user mints. For a Free Mint, please input
								zero or leave blank.
							</span>
						}
					/>
				}
			/>
			<CustomizeNumberInput
				inputName='royalty'
				type='percentage'
				dropDownText='Funds received if NFT is sold by a collector'
				title='Royalty'
				inputError={renderInputError<FlowtyCreatorHubFormValues>(
					"royalty",
					touched,
					errors
				)}
				errorMessage={renderErrorMessage<FlowtyCreatorHubFormValues>(
					"royalty",
					touched,
					errors
				)}
				flowtyMarketplaceFee={flowtyMarketplaceFee}
				toolTip={
					<CreatorHubTooltipIcon
						toolTipCustomId='creatorRoyaltytTooltip'
						renderContent={
							<span>
								The percentage of each marketplace sale that the collection
								creator receives (see example). Many collections choose a 5%
								royalty.
							</span>
						}
					/>
				}
			/>
		</div>
	)
}
