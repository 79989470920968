import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/solid"
import { Flowty } from "flowty-sdk"
import React, { Fragment, useMemo } from "react"
import { useCreatorHubModal } from "../../../FlowtyCreatorHub/hooks/useCreatorHubModal"
import { TransactionProcessing } from "../../../FlowtyModal/components/common/TransactionProcessing/TransactionProcessing"
import { FormTabs } from "../../types/FlowtyCreatorHubContextTypes"
import { FlowtyCreatorHubFormValues } from "../../types/FlowtyCreatorHubTypes"
import { CreatorHubModalNavView } from "./CreatorHubModalNavView/CreatorHubModalNavView"
import { CollectionFailed } from "./CreatorHubModalState/CollectionFailed"
import { CollectionSuccess } from "./CreatorHubModalState/CollectionSuccess"
import { ConfirmCollection } from "./CreatorHubModalState/ConfirmCollection"
import { CreatorHubSignIn } from "./CreatorHubModalState/CreatorHubSignIn"
import { cleanCreatorHubCache } from "../../utils/creatorHubCacheFn"
import {
	FLOW_SCAN_TX_MAINNET_URL,
	FLOW_SCAN_TX_TESTNET_URL,
} from "flowty-common"

interface CreatorHubModalProps {
	isOpen: boolean
	onCloseModal: () => void
	flowty: Flowty
	isLoggedUser: boolean
	creatorHubId?: string
	mixPanelFn: (event: string, data: unknown) => void
	resetForm: () => void
	values: FlowtyCreatorHubFormValues
	changeSelectedFormTab: (tab: FormTabs) => void
	uploadImageFn?: (file: File) => Promise<{ cid: string } | null>
}

export const CreatorHubModal: React.FunctionComponent<CreatorHubModalProps> = ({
	isOpen,
	onCloseModal,
	flowty,
	isLoggedUser,
	creatorHubId,
	mixPanelFn,
	changeSelectedFormTab,
	resetForm,
	uploadImageFn,
	values,
}) => {
	const {
		collectionState,
		modalNavProps,
		transactionID,
		isSignedIn,
		signIn,
		collectionPageURL,
		errMessage,
	} = useCreatorHubModal({
		changeSelectedFormTab,
		creatorHubId,
		flowty,
		mixPanelFn,
		onCloseModal,
		resetForm,
		uploadImageFn,
		values,
	})

	const { isCreating, isError, isSuccess } = collectionState

	const fullCollectionPageUrl = useMemo(() => {
		if (!collectionPageURL) return null
		const isMainnet = flowty.config.network === "mainnet"
		const listingBaseURL = isMainnet ? "flowty.io" : "testnet.flowty.io"
		return `https://${listingBaseURL}${collectionPageURL}`
	}, [collectionPageURL])

	const transactionExplorerLink = useMemo(() => {
		if (!transactionID) return ""
		const flowscanBaseURL =
			flowty.config.network === "mainnet"
				? FLOW_SCAN_TX_MAINNET_URL
				: FLOW_SCAN_TX_TESTNET_URL
		return `${flowscanBaseURL}/${transactionID}`
	}, [transactionID])

	const isUserLoggedIn = useMemo(() => {
		if (process.env.IS_STORYBOOK) {
			if (creatorHubId === "MOCK_SIGN_IN") {
				return isSignedIn
			} else {
				return isLoggedUser
			}
		}

		return isLoggedUser
	}, [isLoggedUser, isSignedIn])

	const renderTitle = useMemo(() => {
		return (
			<p className='text-lg md:text-2xl leading-6 text-white font-black'>
				{!isUserLoggedIn ? (
					"Wallet Required"
				) : (
					<>
						{!isCreating &&
							!isError &&
							!isSuccess &&
							"Confirm Collection Creation"}
						{isCreating && "Creating Collection"}
						{isSuccess && "Collection Created"}
						{isError && "Collection Creation Failed"}
					</>
				)}
			</p>
		)
	}, [isCreating, isError, isSuccess])

	const onCloseModalHandler = (): void => {
		if (isSuccess) {
			cleanCreatorHubCache()
			resetForm()
			changeSelectedFormTab(FormTabs.CreateCollection)
		}
		onCloseModal()
	}

	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog
				as='div'
				className='relative z-[1000]'
				onClose={onCloseModalHandler}
			>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'
				>
					<div className='fixed inset-0 bg-black bg-opacity-75' />
				</Transition.Child>
				<div
					data-testid='flowty-listing-modal'
					className='fixed max-md:top-[40px] inset-0 overflow-y-auto'
				>
					<div className='flex min-h-full items-center justify-center text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-0'
							leaveFrom='opacity-0 scale-100'
							leaveTo='opacity-0 scale-95'
						>
							<Dialog.Panel className='w-full md:w-[511px] max-h-[500px] h-full flex flex-col transform rounded-lg bg-[#04070B] text-left align-middle shadow-xl transition-all border border-[#495057]'>
								<Dialog.Title
									as='div'
									className='border-b border-[#495057] px-[40px] py-[30px] md:py-8 w-full flex justify-between items-center'
								>
									{renderTitle}
									<XIcon
										onClick={onCloseModal}
										className='w-6 h-6 md:h-8 md:w-8 cursor-pointer fill-white'
									/>
								</Dialog.Title>
								<div className={`w-full py-[32px] px-[40px]`}>
									{!isUserLoggedIn ? (
										<CreatorHubSignIn />
									) : (
										<>
											{!isCreating && !isSuccess && !isError && (
												<ConfirmCollection />
											)}
											{isCreating && (
												<div className='h-full flex flex-col items-center justify-center'>
													<TransactionProcessing
														transactionExplorerLink={
															transactionExplorerLink as string
														}
													/>
												</div>
											)}
											{isSuccess && (
												<div className='h-full flex flex-col items-center justify-center'>
													<CollectionSuccess
														transactionExplorerLink={
															transactionExplorerLink as string
														}
														values={values}
														collectionPageURL={fullCollectionPageUrl}
														mixPanelFn={mixPanelFn}
													/>
												</div>
											)}
											{isError && (
												<div className='h-full flex flex-col items-center justify-center'>
													<CollectionFailed errMessage={errMessage} />
												</div>
											)}
										</>
									)}
								</div>
								<div className='h-full w-full flex justify-center items-center border-t border-[#495057] px-[40px] py-[18px]'>
									<CreatorHubModalNavView
										modalNavProps={modalNavProps}
										isSignedIn={isUserLoggedIn}
										signIn={signIn}
									/>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}
