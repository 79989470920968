import { FCLTransactionResult, TokenMetadata } from "flowty-common"
import { FlowtyAPI } from "./api/client"
import { CheckerService } from "./checker/CheckerService"
import { CollectionService } from "./collection/CollectionService"
import { CreateOpenEdition } from "./creatorHub/createOpenEdition/CreateOpenEdition"
import { CreateOpenEditionProps } from "./creatorHub/createOpenEdition/CreateOpenEditionTypes"
import { DropDetails } from "./drop/dropDetails/DropDetails"
import {
	AllDropsDetailsProps,
	DropDetailsProps,
} from "./drop/dropDetails/DropDetailsTypes"
import { DropMint } from "./drop/dropMint/DropMint"
import { DropMintProps } from "./drop/dropMint/DropMintTypes"
import { FundLoanListing } from "./loan/fund/FundLoanListing"
import { FundLoanListingProps } from "./loan/fund/FundLoanListingTypes"
import { LoanListing } from "./loan/listing/LoanListing"
import {
	CreateLoanListingProps,
	RemoveLoanListing,
} from "./loan/listing/LoanListingTypes"
import { RepayLoanService } from "./loan/repay/RepayLoan"
import { RepayLoanRequest } from "./loan/repay/RepayLoanTypes"
import { FundRentalListing } from "./rent/fund/FundRentalListing"
import { FundRentalListingProps } from "./rent/fund/FundRentalListingTypes"
import { RentListing } from "./rent/listing/RentListing"
import {
	CreateRentListingProps,
	RemoveRentListing,
} from "./rent/listing/RentListingTypes"
import { ReturnRental } from "./rent/return/ReturnRental"
import { ScriptService } from "./scripts/ScriptService"
import { AcceptOffer } from "./storefront/acceptOffer/AcceptOffer"
import { AcceptOfferProps } from "./storefront/acceptOffer/AcceptOfferTypes"
import { CancelOffer } from "./storefront/cancelOffer/CancelOffer"
import { CancelOfferProps } from "./storefront/cancelOffer/CancelOfferTypes"
import { StorefrontListing } from "./storefront/listing/StorefrontListing"
import {
	CreateStorefrontListing,
	RemoveStorefrontListing,
} from "./storefront/listing/StorefrontListingTypes"
import { MakeOffer } from "./storefront/makeOffer/MakeOffer"
import { MakeOfferProps } from "./storefront/makeOffer/MakeOfferTypes"
import { StorefrontPurchase } from "./storefront/purchase/StorefrontPurchase"
import { PurchaseStorefrontListing } from "./storefront/purchase/StorefrontPurchaseTypes"
import { TokenService } from "./token/TokenService"
import { TransferService } from "./transfer/TransferService"
import { TransferServiceTypes } from "./transfer/TransferServiceTypes"
import {
	Config,
	LoanRentalFilteredData,
	StorefrontListingRequest,
} from "./types"

export class Flowty {
	config: Config

	storefrontListing: StorefrontListing

	loanListing: LoanListing

	rentListing: RentListing

	transferService: TransferService

	checkerService: CheckerService

	storefrontPurchase: StorefrontPurchase

	fundLoanListingService: FundLoanListing

	repayLoanService: RepayLoanService

	fundRentalListingService: FundRentalListing

	returnRentalService: ReturnRental

	makeOfferService: MakeOffer

	acceptOfferService: AcceptOffer

	cancelOfferService: CancelOffer

	dropDetailsService: DropDetails

	dropMintService: DropMint

	createOpenEditionService: CreateOpenEdition

	scripts: ScriptService

	tokens: TokenService

	collections: CollectionService

	api: FlowtyAPI

	constructor(config: Config) {
		this.config = config

		this.storefrontListing = new StorefrontListing(this.config)
		this.loanListing = new LoanListing(this.config)
		this.rentListing = new RentListing(this.config)
		this.transferService = new TransferService(this.config)
		this.checkerService = new CheckerService()
		this.storefrontPurchase = new StorefrontPurchase(this.config)
		this.fundLoanListingService = new FundLoanListing(this.config)
		this.repayLoanService = new RepayLoanService(this.config)
		this.fundRentalListingService = new FundRentalListing(this.config)
		this.returnRentalService = new ReturnRental(this.config)
		this.makeOfferService = new MakeOffer(this.config)
		this.acceptOfferService = new AcceptOffer(this.config)
		this.cancelOfferService = new CancelOffer(this.config)
		this.dropDetailsService = new DropDetails(this.config)
		this.dropMintService = new DropMint(this.config)
		this.createOpenEditionService = new CreateOpenEdition(this.config)
		this.scripts = new ScriptService(this.config)
		this.tokens = new TokenService(this.config)
		this.collections = new CollectionService(this.config)
		this.api = new FlowtyAPI(this.config.apiURL)
	}

	async createStorefrontListing({
		nftData,
		buyer,
		salePrice,
		expiry,
		token,
		txAvailableCallback,
		nftProviderPathIdentifier,
		ftReceiverAddress,
	}: CreateStorefrontListing): Promise<any> {
		return this.storefrontListing
			.createStorefrontListing({
				buyer,
				expiry,
				ftReceiverAddress,
				nftData,
				nftProviderPathIdentifier,
				salePrice,
				token,
				txAvailableCallback,
			})
			.then(res => res)
			.catch(err => err)
	}

	createStorefrontBulkListing = async (
		requests: StorefrontListingRequest[],
		paymentReceiverAddress: string,
		paymentReceiverPathIdentifier: string,
		token: TokenMetadata,
		txAvailableCallback: (transactionId: string) => void
	): Promise<FCLTransactionResult | null> => {
		return this.storefrontListing.createStorefrontBulkListing(
			requests,
			paymentReceiverAddress,
			paymentReceiverPathIdentifier,
			token,
			txAvailableCallback
		)
	}

	async removeStorefrontListing({
		listingResourceID,
		listingType,
		txAvailableCallback,
	}: RemoveStorefrontListing): Promise<any> {
		return this.storefrontListing
			.removeStorefrontListing({
				listingResourceID,
				listingType,
				txAvailableCallback,
			})
			.then(res => res)
			.catch(err => err)
	}

	removeBulkListing = async (
		listingResourceIDs: string[],
		listingType: string,
		txAvailableCallback: (transactionId: string) => void
	): Promise<FCLTransactionResult> => {
		console.log("remove bulk listing", { listingResourceIDs, listingType })

		return this.storefrontListing.removeBulkListing(
			listingResourceIDs,
			listingType,
			txAvailableCallback
		)
	}

	async createLoanListing({
		nftData,
		loanAmount,
		loanInterestRate,
		loanDuration,
		loanAutoRepaymentEnabled,
		loanExpiresAfterDays,
		token,
		ftPrivatePathIdentifier,
		nftProviderPathIdentifier,
		ftReceiverAddress,
		txAvailableCallback,
	}: CreateLoanListingProps): Promise<any> {
		return this.loanListing
			.createLoanListing({
				ftPrivatePathIdentifier,
				ftReceiverAddress,
				loanAmount,
				loanAutoRepaymentEnabled,
				loanDuration,
				loanExpiresAfterDays,
				loanInterestRate,
				nftData,
				nftProviderPathIdentifier,
				token,
				txAvailableCallback,
			})
			.then(res => res)
			.catch(err => err)
	}

	async removeLoanListing({
		listingResourceID,
		txAvailableCallback,
	}: RemoveLoanListing): Promise<any> {
		return this.loanListing
			.removeLoanListing({
				listingResourceID,
				txAvailableCallback,
			})
			.then(res => res)
			.catch(err => err)
	}

	async createRentListing({
		nftData,
		rentalFee,
		rentalDeposit,
		rentalTerm,
		token,
		renterAddress,
		rentalExpiresAfterDays,
		nftProviderPathIdentifier,
		ftReceiverAddress,
		txAvailableCallback,
	}: CreateRentListingProps): Promise<any> {
		return this.rentListing
			.createRentListing({
				ftReceiverAddress,
				nftData,
				nftProviderPathIdentifier,
				rentalDeposit,
				rentalExpiresAfterDays,
				rentalFee,
				rentalTerm,
				renterAddress,
				token,
				txAvailableCallback,
			})
			.then(res => res)
			.catch(err => err)
	}

	async removeRentListing({
		listingResourceID,
		txAvailableCallback,
	}: RemoveRentListing): Promise<any> {
		return this.rentListing
			.removeRentListing({
				listingResourceID,
				txAvailableCallback,
			})
			.then(res => res)
			.catch(err => err)
	}

	async transferNft({
		nftData,
		transferRecipient,
		nftProviderPathIdentifier,
		txAvailableCallback,
	}: TransferServiceTypes): Promise<any> {
		return this.transferService
			.transferNft({
				nftData,
				nftProviderPathIdentifier,
				transferRecipient,
				txAvailableCallback,
			})
			.then(res => res)
			.catch(err => err)
	}

	async verifyAddress(address: string): Promise<boolean> {
		return this.checkerService
			.verifyAddress(address)
			.then(res => res)
			.catch(err => err)
	}

	async purchaseStorefrontListing({
		listing,
		token,
		nftReceiverAddress,
		ftProviderAddress,
		privateFTPath,
		txAvailableCallback,
	}: PurchaseStorefrontListing): Promise<any> {
		return this.storefrontPurchase
			.purchaseStorefrontListing({
				ftProviderAddress,
				listing,
				nftReceiverAddress,
				privateFTPath,
				token,
				txAvailableCallback,
			})
			.then(res => res)
			.catch(err => err)
	}

	async fundLoanListing({
		listingData,
		nftReceiverAddress,
		ftPrivatePathIdentifier,
		ftProviderAddress,
		nftData,
		token,
		txAvailableCallback,
	}: FundLoanListingProps): Promise<any> {
		return this.fundLoanListingService
			.fundLoanListing({
				ftPrivatePathIdentifier,
				ftProviderAddress,
				listingData,
				nftData,
				nftReceiverAddress,
				token,
				txAvailableCallback,
			})
			.then(res => res)
			.catch(err => err)
	}

	async fundRentalListing({
		listingData,
		token,
		nftData,
		enabledAutoReturn,
		nftReceiverAddress,
		ftProviderAddress,
		ftPrivatePathIdentifier,
		nftProviderPathIdentifier,
		txAvailableCallback,
	}: FundRentalListingProps): Promise<any> {
		return this.fundRentalListingService
			.fundRentalListing({
				enabledAutoReturn,
				ftPrivatePathIdentifier,
				ftProviderAddress,
				listingData,
				nftData,
				nftProviderPathIdentifier,
				nftReceiverAddress,
				token,
				txAvailableCallback,
			})
			.then(res => res)
			.catch(err => err)
	}

	returnRental = async (
		rental: LoanRentalFilteredData,
		token: TokenMetadata,
		txAvailableCallback: (transactionId: string) => void,
		nftProviderAddress: string,
		nftProviderPathIdentifier: string
	): Promise<FCLTransactionResult> => {
		return this.returnRentalService.returnRental(
			rental,
			token,
			txAvailableCallback,
			nftProviderAddress,
			nftProviderPathIdentifier
		)
	}

	async makeOffer({
		nftData,
		txAvailableCallback,
		nftReceiverAddress,
		ftProviderAddress,
		ftProviderPathIdentifier,
		token,
		tokenIdentifier,
		offerAmount,
		expiry,
	}: MakeOfferProps): Promise<any> {
		return this.makeOfferService
			.makeOffer({
				expiry,
				ftProviderAddress,
				ftProviderPathIdentifier,
				nftData,
				nftReceiverAddress,
				offerAmount,
				token,
				tokenIdentifier,
				txAvailableCallback,
			})
			.then(res => res)
			.catch(err => err)
	}

	async acceptOffer({
		nftData,
		txAvailableCallback,
		nftProviderPathIdentifier,
		ftReceiverAddress,
		token,
		offerResourceID,
		offerStorefrontAddress,
		nftStoragePath,
	}: AcceptOfferProps): Promise<any> {
		return this.acceptOfferService
			.acceptOffer({
				ftReceiverAddress,
				nftData,
				nftProviderPathIdentifier,
				nftStoragePath,
				offerResourceID,
				offerStorefrontAddress,
				token,
				txAvailableCallback,
			})
			.then(res => res)
			.catch(err => err)
	}

	async cancelOffer({
		offerResourceID,
		token,
		txAvailableCallback,
	}: CancelOfferProps): Promise<any> {
		return this.cancelOfferService.cancelOffer({
			offerResourceID,
			token,
			txAvailableCallback,
		})
	}

	async getDropDetails({
		contractAddress,
		contractName,
		dropID,
		minter,
		quantity,
		paymentIdentifier,
	}: DropDetailsProps): Promise<any> {
		return this.dropDetailsService.getDropDetails({
			contractAddress,
			contractName,
			dropID,
			minter,
			paymentIdentifier,
			quantity,
		})
	}

	async getAllDropDetails({
		nftResourceTypeIdentifier,
		minter,
		quantity,
		paymentIdentifier,
	}: AllDropsDetailsProps): Promise<any> {
		return this.dropDetailsService.getAllDropDetails({
			minter,
			nftResourceTypeIdentifier,
			paymentIdentifier,
			quantity,
		})
	}

	async dropMint({
		commissionAddress,
		contractAddress,
		contractName,
		dropID,
		dropPhaseIndex,
		nftIdentifier,
		numToMint,
		paymentIdentifier,
		paymentReceiverPath,
		paymentStoragePath,
		totalCost,
		txAvailableCallback,
	}: DropMintProps): Promise<any> {
		return this.dropMintService.mint({
			commissionAddress,
			contractAddress,
			contractName,
			dropID,
			dropPhaseIndex,
			nftIdentifier,
			numToMint,
			paymentIdentifier,
			paymentReceiverPath,
			paymentStoragePath,
			totalCost,
			txAvailableCallback,
		})
	}

	createOpenEdition = async ({
		collectionDetails,
		nftDetails,
		dropDetails,
		phaseDetails,
		txAvailableCallback,
	}: CreateOpenEditionProps): Promise<FCLTransactionResult> => {
		return this.createOpenEditionService.createOpenEdition({
			collectionDetails,
			dropDetails,
			nftDetails,
			phaseDetails,
			txAvailableCallback,
		})
	}

	repayLoan = async ({
		fundItemID,
		address,
		token,
		ftPrivatePathIdentifier,
		ftProviderAddress,
		txAvailableCallback,
	}: RepayLoanRequest): Promise<FCLTransactionResult> => {
		return this.repayLoanService.repayLoan({
			address,
			ftPrivatePathIdentifier,
			ftProviderAddress,
			fundItemID,
			token,
			txAvailableCallback,
		})
	}

	setupCollection = async (
		address: string,
		name: string,
		isDapper: boolean,
		isNFTCatalog: boolean
	): Promise<FCLTransactionResult | null> => {
		return this.collections.setupCollection(
			address,
			name,
			isDapper,
			isNFTCatalog
		)
	}
}
