import { Config } from "../../types"

export const getMintDropTx = (config: Config): string =>
	config.crescendo ? mintDropCrescendo(config) : mintDrop(config)

const mintDrop = (config: Config): string => {
	return ``
}

const mintDropCrescendo = (config: Config): string => {
	return `
    import ViewResolver from ${config.contractAddresses.ViewResolver}
    import MetadataViews from ${config.contractAddresses.MetadataViews}
    import NonFungibleToken from ${config.contractAddresses.NonFungibleToken}
    import FungibleToken from ${config.contractAddresses.FungibleToken}
    import FlowtyDrops from ${config.contractAddresses.FlowtyDrops}
    
    transaction(
    contractAddress: Address,
    contractName: String,
    numToMint: Int,
    totalCost: UFix64,
    paymentIdentifier: String,
    paymentStorageIdentifier: String,
    paymentReceiverIdentifier: String,
    dropID: UInt64,
    dropPhaseIndex: Int,
    nftIdentifier: String,
    commissionAddress: Address
) {
    prepare(acct: auth(Storage, Capabilities) &Account) {
        let paymentStoragePath = StoragePath(identifier: paymentStorageIdentifier) ?? panic("invalid storage identifier")
        let paymentReceiverPath = PublicPath(identifier: paymentReceiverIdentifier) ?? panic("invalid receiver identifier")

        let dc = getAccount(contractAddress).contracts.get(name: contractName) ?? panic("contract name not found")
        let publicTypes = dc.publicTypes()
        let nftResourceType = Type<@{NonFungibleToken.NFT}>()

        let resolver = getAccount(contractAddress).contracts.borrow<&{ViewResolver}>(name: contractName)
            ?? panic("ViewResolver contract interface not found on contract address + name")
        
        var tmpType: Type? = nil
        for pt in publicTypes {
            if pt.isSubtype(of: nftResourceType) {
            tmpType = pt
            }
        }

        let nftType = tmpType ?? panic("no type should that implements")

        let collectionData = resolver.resolveContractView(resourceType: nftType, viewType: Type<MetadataViews.NFTCollectionData>())! as! MetadataViews.NFTCollectionData
        if acct.storage.borrow<&AnyResource>(from: collectionData.storagePath) == nil {
            acct.storage.save(<- collectionData.createEmptyCollection(), to: collectionData.storagePath)

            acct.capabilities.publish(
                acct.capabilities.storage.issue<&{NonFungibleToken.CollectionPublic}>(collectionData.storagePath), 
                at: collectionData.publicPath
            )
        }
        let receiverCap = acct.capabilities.get<&{NonFungibleToken.CollectionPublic}>(collectionData.publicPath)!

        let expectedNftType = CompositeType(nftIdentifier) ?? panic("invalid nft identifier")

        let vault = acct.storage.borrow<auth(FungibleToken.Withdraw) &{FungibleToken.Provider}>(from: paymentStoragePath)
            ?? panic("could not borrow token provider")

        let paymentVault <- vault.withdraw(amount: totalCost)

        let dropResolver = resolver.resolveContractView(resourceType: nftType, viewType: Type<FlowtyDrops.DropResolver>())! as! FlowtyDrops.DropResolver
        // let dropResolver = resolver.resolveView(Type<FlowtyDrops.DropResolver>())! as! FlowtyDrops.DropResolver
        let dropContainer = dropResolver.borrowContainer()
            ?? panic("unable to borrow drop container")

        let drop = dropContainer.borrowDropPublic(id: dropID) ?? panic("drop not found")

        let commissionReceiver = getAccount(commissionAddress).capabilities.get<&{FungibleToken.Receiver}>(paymentReceiverPath)
        let remainder <- drop.mint(
            payment: <-paymentVault,
            amount: numToMint,
            phaseIndex: dropPhaseIndex,
            expectedType: expectedNftType,
            receiverCap: receiverCap,
            commissionReceiver: commissionReceiver,
            data: {}
        )

        if remainder.balance > 0.0 {
            acct.storage.borrow<&{FungibleToken.Receiver}>(from: paymentStoragePath)!.deposit(from: <-remainder)
        } else {
            destroy remainder
        }
    }
}`
}
