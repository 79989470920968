import { useEffect, useState } from "react"
import Lottie from "react-lottie"
import FlowtyLink from "../../../../FlowtyModal/components/common/FlowtyLink"
import { ShareNewDrop } from "../../../../TwitterShare/LaunchPad/ShareNewDrop"
import { fetchJSON } from "../../../../utils/GlobalUtils"
import { FlowtyCreatorHubFormValues } from "../../../types/FlowtyCreatorHubTypes"

interface CollectionSuccessProps {
	transactionExplorerLink: string | null
	values: FlowtyCreatorHubFormValues
	collectionPageURL: string | null
	mixPanelFn: (event: string, data: unknown) => void
}

export const CollectionSuccess: React.FunctionComponent<
	CollectionSuccessProps
> = ({ transactionExplorerLink, values, collectionPageURL, mixPanelFn }) => {
	const [showTransactionSuccess, setShowTransactionSuccess] = useState(false)
	const [animationData, setAnimationData] = useState<JSON | null>(null)

	useEffect(() => {
		const fetchAnimationData = async (): Promise<void> => {
			try {
				const response = await fetchJSON(
					"https://storage.googleapis.com/flowty-images/drops/animation.json",
					mixPanelFn
				)
				setAnimationData(response as JSON)
			} catch (error) {
				mixPanelFn("Error fetching animation data", error)
			}
		}

		fetchAnimationData()
	}, [])

	return (
		<div className='w-full flex flex-col items-center justify-center gap-[8px] font-montserrat'>
			{showTransactionSuccess ? (
				<svg
					className='w-[7.75rem] h-[7.75rem]'
					width='124'
					height='124'
					viewBox='0 0 124 124'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M61.9999 111.6C89.3932 111.6 111.6 89.3933 111.6 62C111.6 34.6067 89.3932 12.4 61.9999 12.4C34.6066 12.4 12.3999 34.6067 12.3999 62C12.3999 89.3933 34.6066 111.6 61.9999 111.6ZM84.984 53.9841C87.4052 51.5628 87.4052 47.6372 84.984 45.216C82.5627 42.7947 78.6371 42.7947 76.2158 45.216L55.7999 65.6319L47.784 57.616C45.3627 55.1947 41.4371 55.1947 39.0158 57.616C36.5946 60.0372 36.5946 63.9628 39.0158 66.3841L51.4158 78.7841C53.8371 81.2053 57.7627 81.2053 60.184 78.7841L84.984 53.9841Z'
						fill='#04E5A3'
					/>
				</svg>
			) : (
				<Lottie
					eventListeners={[
						{
							callback: () => setShowTransactionSuccess(true),
							eventName: "complete",
						},
					]}
					options={{
						animationData: animationData,
						autoplay: true,
						loop: false,
						rendererSettings: {
							preserveAspectRatio: "xMidYMid slice",
						},
					}}
					height={108.5}
					width={108.5}
				/>
			)}
			<div className='text-[16px] font-semibold text-[#F8F9FA]'>
				Transaction Successful!
			</div>
			<div className='text-white text-center text-[14px] flex items-center justify-center gap-1'>
				Follow your transaction on{" "}
				<FlowtyLink href={transactionExplorerLink ?? ""} text='Flowscan' />
			</div>
			{collectionPageURL && (
				<ShareNewDrop collectionPageURL={collectionPageURL} values={values} />
			)}
		</div>
	)
}
