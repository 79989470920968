import { useField } from "formik"
import { useMemo } from "react"
import { FLOWTY_QUICKNODE_IPFS_URL } from "../../../../../../utils/constants"

export const PreviewNftList: React.FunctionComponent = () => {
	const [nftImage] = useField("nftImage")
	const [collectionName] = useField("collectionName")

	const renderNftList = useMemo(() => {
		return Array(8)
			.fill("")
			.map((_item, idx) => (
				<div
					key={idx}
					className='rounded-md bg-[#edf3f60a] w-[180px] h-[280px] 3xl:h-[350px] py-4 px-3'
				>
					<div className='flex flex-col space-y-4'>
						{collectionName.value ? (
							<div className='flex w-full justify-between overflow-hidden'>
								<p className='font-montserrat text-white font-extrabold uppercase text-[1.125rem]'>
									{collectionName.value.length > 9
										? collectionName.value.slice(0, 9) + "..."
										: collectionName.value}
								</p>
								<p className='font-montserrat text-white font-extrabold uppercase text-[1.125rem]'>
									#{idx + 1}
								</p>
							</div>
						) : (
							<div className='animate-pulse w-full h-[28px] bg-[#edf3f60a] rounded-md' />
						)}
						{nftImage.value ? (
							<img
								data-testid={`preview-nft-list-image-${idx}`}
								src={
									nftImage.value?.fileCid
										? `${FLOWTY_QUICKNODE_IPFS_URL}${nftImage.value?.fileCid}`
										: nftImage.value?.fileBase64
								}
								alt='Nft Card'
								className='object-contain w-[160px] h-[160px]'
							/>
						) : (
							<div className='animate-pulse object-contain w-full h-[159px] bg-[#edf3f60a]' />
						)}
						<div>
							<div className='flex flex-row justify-between mt-1 items-center border-gradient mb-3'>
								<p className='text-[#AAAAAA] text-base font-montserrat mb-2'>
									Purchase Price
								</p>
								<span className='text-bold mb-2 text-white'>{"-"}</span>
							</div>
						</div>
					</div>
				</div>
			))
	}, [nftImage])

	return (
		<div
			data-testid='preview-nft-list'
			className={`w-full h-full grid gap-[6.385px] creator-hub-nft-list-wrapper`}
		>
			{renderNftList}
		</div>
	)
}
