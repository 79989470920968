/* eslint-disable @typescript-eslint/no-explicit-any */
import { format } from "date-fns"
import { calcAPRInterest, getImageURL } from "flowty-common"
import numeral from "numeral"
import noImage from "../../../../assets/media/image_not_available.svg"
import { getDurationPeriod } from "../../../../util/nftDataUtil"
import { isFlowRewards } from "../../../../util/settings"
import { ActivityTableImage } from "../../../Shared/ActivityTableFallbackImage/ActivityTableImage"
import { TokenAmount } from "../../../Tokens/tokenDisplays"

export const userBorrowerTableFields: (
	navigate: (path: string) => void
) => any = navigate => [
	{
		customRender: ({ item }: any) => (
			<div className='flex flex-row justify-start items-center capitalize'>
				{item?.listingAvailable?.state.toLowerCase() ??
					(item.settled ? "Settled" : "Not settled")}
			</div>
		),
		name: "status",
		sortable: false,
		title: "Status",
	},
	{
		customRender: ({ item }: any) => (
			<div className='flex flex-row justify-center items-center'>
				{item.listingAvailable?.blockTimestamp
					? format(
							item.listingAvailable.blockTimestamp.toDate(),
							"yyyy-MM-dd HH:mm"
					  )
					: ""}
			</div>
		),
		name: "loanStart",
		sortable: false,
		title: "Loan Start Date",
	},

	{
		customRender: ({ item }: any) => (
			<div className='flex flex-row justify-center items-center'>
				{item.settleDeadline
					? format(item.settleDeadline.toDate(), "yyyy-MM-dd HH:mm")
					: ""}
			</div>
		),
		name: "dueDate",
		sortable: false,
		title: "Due Date",
	},
	{
		customRender: ({ item }: any) => {
			const itemType = `A.${item.additionalData?.card?.collectionAddress?.substring(
				2
			)}.${item.additionalData?.card?.collectionName}.NFT`

			return (
				<div>
					{!!item.listingAvailable?.detail?.card && (
						<div className='flex flex-row justify-start items-center'>
							<div className='w-[40px] mr-2'>
								<ActivityTableImage
									collectionAddress={
										item?.listingAvailable?.detail?.card?.collectionAddress ||
										""
									}
									collectionName={
										item?.listingAvailable?.detail?.card?.collectionName || ""
									}
									srcImg={getImageURL(
										item?.listingAvailable?.detail?.card?.images?.[0]?.url ??
											noImage,
										isFlowRewards(itemType)
									)}
								/>
							</div>
							<div className='flex flex-col'>
								{item.listingAvailable?.detail?.card?.additionalDetails ? (
									<span className='text-[12px] text-primary font-montserrat font-extrabold truncate'>
										{item.listingAvailable?.detail?.card?.title}
									</span>
								) : (
									<>
										<span className='text-[12px] text-primary font-montserrat font-extrabold truncate'>
											{item.listingAvailable?.detail?.card?.title}
										</span>
										{item.listingAvailable?.detail?.card?.num &&
											!item.listingAvailable?.detail?.card?.title.includes(
												"#"
											) && (
												<span className='text-[12px] text-primary font-montserrat font-extrabold ml-2'>
													#{item.listingAvailable?.detail?.card?.num}
												</span>
											)}
									</>
								)}
								{item?.listingAvailable?.detail?.card?.additionalDetails ? (
									<>
										{item?.listingAvailable?.detail?.card?.additionalDetails ? (
											item?.listingAvailable?.detail?.card?.additionalDetails?.map(
												(detail: any) => (
													<span
														key={detail}
														className='font-montserrat text-primary text-[12px] font-light'
													>
														{detail}
													</span>
												)
											)
										) : (
											<div className='flex flex-row items-center justify-start mt-2 h-4'></div>
										)}
									</>
								) : (
									<>
										{item?.listingAvailable?.detail?.card?.headerTraits &&
											item?.listingAvailable?.detail?.card?.headerTraits?.map(
												(trait: any) => (
													<span
														key={trait.displayType}
														className='font-montserrat text-primary text-[12px] font-light'
													>
														{trait.value}
													</span>
												)
											)}
									</>
								)}
							</div>
						</div>
					)}
				</div>
			)
		},
		name: "detail",
		sortable: false,
		title: "Details",
	},
	{
		customRender: ({ item }: any) => {
			return item?.listingAvailable?.amount ? (
				<div className='flex flex-row justify-center items-center'>
					<TokenAmount
						amount={Number(item.listingAvailable.amount)}
						isSmall
						token={item.listingAvailable.paymentTokenName}
					/>
				</div>
			) : null
		},
		name: "amount",
		sortable: false,
		title: "Amount",
	},
	{
		customRender: ({ item }: any) => (
			<div className='flex flex-row justify-center items-center'>
				{item?.listingAvailable?.enabledAutoRepayment ? "Yes" : "No"}
			</div>
		),
		name: "autoRepayment",
		sortable: false,
		title: "Auto Repayment",
	},
	{
		customRender: ({ item }: any) =>
			item.listingAvailable?.interestRate ? (
				<div className='flex flex-row justify-center items-center'>
					{(item.listingAvailable.interestRate * 100).toFixed(2)}%
				</div>
			) : null,
		name: "rate",
		sortable: false,
		title: "Rate",
	},
	{
		customRender: ({ item }: any) =>
			item.listingAvailable?.term ? (
				<div className='flex flex-row justify-center items-center'>
					{getDurationPeriod(item.listingAvailable?.term)}
				</div>
			) : null,
		name: "duration",
		sortable: false,
		title: "Duration",
	},
	{
		customRender: ({ item }: any) =>
			item?.listingAvailable ? (
				<div className='flex flex-row justify-center items-center'>
					{numeral(
						calcAPRInterest(
							item ? Number(item.listingAvailable.interestRate) : 0,
							item ? Number(item.listingAvailable.term) : 0
						)
					).format("0,0.00")}
					%
				</div>
			) : null,
		name: "apr",
		sortable: false,
		title: "APR",
	},
	{
		customRender: ({ item }: any) => {
			if (item.listingAvailable) {
				return item.calculatedValues?.totalRepayment ? (
					<div className='flex flex-row justify-center items-center'>
						<TokenAmount
							amount={item?.calculatedValues?.repaymentDue}
							isSmall
							token={item?.listingAvailable?.paymentTokenName || "FUSD"}
						/>
					</div>
				) : null
			}
			return null
		},
		name: "repayment",
		sortable: false,
		title: "Repayment Amount",
	},
	{
		customRender: ({ item }: any) => (
			<div
				onClick={e => {
					e.preventDefault()
					e.stopPropagation()
					navigate(`/user/${item?.lender}`)
				}}
				className='flex flex-row justify-start items-center hover:text-green-300'
			>
				<a href={`/user/${item?.lender}`}>{item?.lender || "--"}</a>
			</div>
		),
		name: "lender",
		sortable: false,
		title: "Lender",
	},
]
