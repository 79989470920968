import { useState } from "react"
import { ReactComponent as Discord } from "../../../../assets/media/discordOutlined.svg"
import { ReactComponent as DiscordFilled } from "../../../../assets/media/discordFilled.svg"
import { ExternalURLWarning } from "ds-flowty"

interface DiscordIconProps {
	link: string
}

export const DiscordIcon: React.FunctionComponent<DiscordIconProps> = ({
	link,
}) => {
	const [onHover, setOnHover] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)
	return (
		<>
			{onHover ? (
				<DiscordFilled
					className='cursor-pointer h-5'
					onClick={() => setIsModalOpen(true)}
					onMouseEnter={() => setOnHover(true)}
					onMouseLeave={() => setOnHover(false)}
				/>
			) : (
				<Discord
					className='cursor-pointer h-5'
					onClick={() => setIsModalOpen(true)}
					onMouseEnter={() => setOnHover(true)}
					onMouseLeave={() => setOnHover(false)}
				/>
			)}
			<ExternalURLWarning
				isOpen={isModalOpen}
				linkURL={link}
				onCloseModal={() => setIsModalOpen(false)}
			/>
		</>
	)
}
