import { Menu, Transition } from "@headlessui/react"
import { inject, observer } from "mobx-react"
import { Fragment, useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import defaultProfileImage from "../../../assets/media/landingPage/defaultProfileImage.svg"
import { logOut } from "../../../services/AuthService"
import { AuthStoreProp } from "../../../stores/AuthStore"
import { actions as Mixpanel } from "../../../util/Mixpanel"
import { getProviderImage, getProviderName } from "../../../util/wallet"
import { CopyWalletAddressButton } from "../../UserProfile/CopyWalletAddressButton"
import { ChildWallets } from "../ChildWallets"
import TokenBalance from "./TokenBalance"
import { flowty } from "../../../config/config"
import { FlowtyBadge } from "ds-flowty"
import { AccountContracts } from "../../../screens/CreatorHub/hooks/useProfileCreatorHub"

const WalletConnected: React.FC<AuthStoreProp> = ({ authStore }) => {
	const [collections, setCollections] = useState<AccountContracts | null>()
	const disconnectWallet = (): void => {
		logOut()
		window.location.reload()
		Mixpanel.track("Successful Logout")
	}

	const user = useMemo(() => authStore?.loggedUser, [authStore])

	const AuthService = useMemo(() => {
		// @ts-ignore: Unreachable code error
		return user?.services[0]?.uid
	}, [user])

	const getUserCollections = async (): Promise<void> => {
		try {
			const response = await flowty.scripts.getAccountCollections(
				authStore?.loggedUser?.addr ?? ""
			)
			setCollections(response as AccountContracts)
		} catch (error) {
			console.log("error", error)
		}
	}

	useEffect(() => {
		getUserCollections()
	}, [])

	return (
		<Menu
			as='div'
			id='user-nav-dropdown'
			className='relative inline-block text-left z-50'
		>
			<Menu.Button
				data-testid='logged-user-button'
				className='inline-flex py-[8px] px-[12px] md:px-[18px] items-center gap-2 rounded-[6px] border-2 border-[var(--Neutral-300,#495057)] shadow-[0_4px_18px_rgba(0,0,0,0.25)] mx-auto hover:border-[var(--Neutral-300,#6C757D)] hover:bg-opacity-5 hover:bg-white max-h-[48px]'
			>
				<div className='flex flex-row items-center '>
					<span className='text-[var(--Neutral-700,#DEE2E6)] text-center font-montserrat text-sm font-black leading-[16px] mr-4'>
						MY ACCOUNT
					</span>
					<div className='new-avatar-container w-[24px] h-[24px] md:w-[32px] md:h-[32px]'>
						<img
							src={user?.avatar || defaultProfileImage}
							className='rounded-[4px]'
							alt='User Flowty Avatar'
						/>
					</div>
					<div className='ml-[4px]'>
						<svg
							className='w-[18px] h-[18px] md:w-[20px] md:h-[20px]'
							xmlns='http://www.w3.org/2000/svg'
							width='20'
							height='20'
							viewBox='0 0 20 20'
							fill='none'
						>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M5.29289 7.29289C5.68342 6.90237 6.31658 6.90237 6.70711 7.29289L10 10.5858L13.2929 7.29289C13.6834 6.90237 14.3166 6.90237 14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L10.7071 12.7071C10.3166 13.0976 9.68342 13.0976 9.29289 12.7071L5.29289 8.70711C4.90237 8.31658 4.90237 7.68342 5.29289 7.29289Z'
								fill='white'
							/>
						</svg>
					</div>
				</div>
			</Menu.Button>
			<Transition
				as={Fragment}
				enter='transition ease-out duration-100'
				enterFrom='transform opacity-0 scale-95'
				enterTo='transform opacity-100 scale-100'
				leave='transition ease-in duration-75'
				leaveFrom='transform opacity-100 scale-100'
				leaveTo='transform opacity-0 scale-95'
			>
				<Menu.Items className='absolute right-[16px] max-w-[300px] translate-x-[16px] md:max-w-none md:right-0 md:origin-top-right md:translate-x-0 focus:outline-none mt-2 p-6 px-7 flex-col justify-center items-start gap-[var(--25,10px)] rounded-[6px] border border-[var(--Neutral-400,#6C757D)] bg-[#04070b] backdrop-blur-[50px]'>
					<div className='flex flex-row items-center'>
						<div className='new-avatar-container w-[35px] h-[35px] md:w-[48px] md:h-[48px]'>
							<img
								src={user?.avatar || defaultProfileImage}
								className='rounded-[8px]'
								alt='User Flowty Avatar'
							/>
						</div>
						<Link
							className='ml-3 font-bold text-white font-montserrat-bold'
							to='/user/profile'
						>
							<span
								className={`text-white text-center font-montserrat text-[14px] md:text-[18px] font-black leading-normal truncate ${
									!user?.userName && "lowercase"
								}`}
							>
								{user?.userName || user?.addr || ""}
							</span>
						</Link>
					</div>
					<div className='mt-[24px] mb-[38px]'>
						<div className='w-full md:w-96 px-[24px] py-[18px] mt-[16px] rounded-[10px] bg-[#ffffff1f] backdrop-blur-[21px] gap-[16px]'>
							<div className='flex flex-row items-center'>
								<div className='h-[36px] w-[36px] mr-2 flex items-center'>
									<img
										src={getProviderImage(String(AuthService))}
										className='rounded-md'
										alt='Provide Logo'
									/>
								</div>
								<div>
									<p className='text-base font-black text-white font-montserrat-bold'>
										{getProviderName(String(AuthService))}
									</p>
									<CopyWalletAddressButton
										address={`${authStore?.loggedUser?.addr}`}
									/>
								</div>
							</div>
							{!authStore?.loggedUser?.isDapper && (
								<div className='mt-[19px]'>
									<TokenBalance />
								</div>
							)}
						</div>
						<ChildWallets />
					</div>
					<div className='border-gradient' />
					<div className='flex flex-col pt-4'>
						<Link
							to='/user/profile'
							className='text-sm font-bold text-white uppercase duration-300 ease-in-out transform font-montserrat-bold hover-nav-item'
						>
							Profile
						</Link>
						{Boolean(collections?.contracts?.length) && (
							<div className='flex flex-row gap-[6px] mt-2'>
								<Link
									to='/user/creator-hub'
									className='flex gap-[6px]  text-sm font-bold text-white uppercase duration-300 ease-in-out transform font-montserrat-bold hover-nav-item'
								>
									Creator Hub
								</Link>
								<FlowtyBadge text={"NEW"} type={"primary"} size='small' />
							</div>
						)}
						<Link
							to='/user-profile'
							className='mt-2 text-sm font-bold text-white uppercase duration-300 ease-in-out transform font-montserrat-bold hover-nav-item'
						>
							User Activity
						</Link>
						<Link
							to='/settings'
							className='mt-2 text-sm font-bold text-white uppercase duration-300 ease-in-out transform font-montserrat-bold hover-nav-item'
						>
							Settings
						</Link>
						<button
							onClick={disconnectWallet}
							className='mt-2 font-montserrat-bold text-[#ff2f2f] hover-nav-item-red uppercase font-bold transform duration-300 ease-in-out text-left text-sm'
						>
							Sign out
						</button>
					</div>
				</Menu.Items>
			</Transition>
		</Menu>
	)
}

export const WalletConnectedButton = inject("authStore")(
	observer(WalletConnected)
)
