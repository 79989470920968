import { FlowNFTContract, SpotPrice } from "flowty-common"
import { Flowty } from "flowty-sdk"
import * as Yup from "yup"
import { differenceInDays, differenceInMinutes } from "date-fns"

export enum CollectionType {
	SingleImage = "SINGLE_IMAGE",
	MultiImage = "MULTI_IMAGE",
}

export enum CollectionEditionType {
	OpenEdition = "OPEN_EDITION",
	LimitedEdition = "LIMITED_EDITION",
}

export type FileType = {
	file: File
	fileName: string
	fileCid: string | null
	fileBase64?: string
}

export interface FlowtyCreatorHubFormValues {
	collectionType: CollectionType
	collectionEditionType: CollectionEditionType
	collectionName: string
	collectionDescription: string
	collectionWebsiteURL: string
	dropStartDateTime: number | null
	dropEndDateTime: number | null
	exampleSalePrice: number | null
	nftBannerImage: FileType | null
	nftImage: FileType | null
	nftThumbnailImage: FileType | null
	mintPrice: number | null
	royalty: number | null
	creatorWalletAddress: string
	dropThumbnailImage: FileType | null
	startDate: Date
	endDate: Date
	twitterHandle: string | null
	discordHandle: string | null
	instagramHandle: string | null
}

export interface FlowtyCreatorHubProps {
	flowty: Flowty
	flowtyMarketplaceFee?: number | null
	mixPanelFn: (event: string, data: unknown) => void
	strapiUrl: string
	spotPrice: SpotPrice
	loggedUser?: string
	creatorHubId?: string
	uploadIPFSImage: (image: File) => Promise<{ cid: string }>
}

const createCollectionSchema = Yup.object().shape({
	collectionEditionType: Yup.mixed<CollectionEditionType>().oneOf(
		Object.values(CollectionEditionType)
	),
	collectionType: Yup.mixed<CollectionType>().oneOf(
		Object.values(CollectionType)
	),
})

const customizeCollectionOneSchema = Yup.object().shape({
	collectionDescription: Yup.string()
		.required("Please enter a description")
		.max(2000, "Description must be 2000 characters or less")
		.test({
			message: "Description should be less than 200 words",
			test: description => {
				const words = description.split(" ")

				if (words.length > 200) {
					return false
				} else {
					return true
				}
			},
		}),
	collectionName: Yup.string()
		.required("Please enter a collection name")
		.max(50, "Collection name must be 50 characters or less")
		.matches(/^[A-Za-z]/, "Collection name must begin with a letter")
		.matches(
			/^[a-zA-Z0-9_ ]+$/,
			"Collection name cannot have special characters"
		),
	mintPrice: Yup.number().notRequired().min(0, "Mint price can't be negative"),
	nftImage: Yup.mixed<FileType>()
		.required("Please upload an NFT image")
		.test({
			test: file => !!file,
		}),
	royalty: Yup.number()
		.notRequired()
		.min(0, "Minimum Royalty: 0%")
		.max(50, "Maximum Royalty: 50%"),
})

const customizeCollectionTwoSchema = Yup.object().shape({
	discordHandle: Yup.string().notRequired(),
	instagramHandle: Yup.string().notRequired(),
	nftBannerImage: Yup.mixed<FileType>()
		.required("Please upload a banner image")
		.test({
			test: file => !!file,
		}),
	nftThumbnailImage: Yup.mixed<FileType>()
		.required("Please upload a thumbnail image")
		.test({
			test: file => !!file,
		}),
	twitterHandle: Yup.string().notRequired(),
	websiteURL: Yup.string().notRequired(),
})

const CustomizeDropSchema = Yup.object().shape({
	dropEndDateTime: Yup.number()
		.required("End Date & Time must be after Start Date & Time")
		.test({
			message: "End Date & Time must be after Start Date & Time",
			test: function (numDate) {
				const dropStartTime = this.parent.dropStartDateTime
				return numDate > dropStartTime
			},
		})
		.test({
			message: "End date must be no more than 7 days from Start date",
			test: (numDate, ctx) => {
				const dropStartTime = ctx.parent.dropStartDateTime
				if (differenceInDays(numDate, dropStartTime) > 7) {
					return false
				} else {
					return true
				}
			},
		}),
	dropStartDateTime: Yup.number()
		.required("Please choose a Start Date & Time ")
		.test({
			message: "Start date must be no more than 7 days from today",
			test: numDate => {
				if (differenceInDays(numDate, Date.now()) > 7) {
					return false
				} else {
					return true
				}
			},
		})
		.test({
			message: "Start date must be after current date and time",
			test: numDate => {
				if (Math.abs(differenceInMinutes(Date.now(), numDate)) <= 5) {
					return true
				}
				if (numDate < Date.now()) {
					return false
				} else {
					return true
				}
			},
		}),
	dropThumbnailImage: Yup.mixed<FileType>()
		.required("Please upload a thumbnail")
		.test({
			test: file => !!file,
		}),
})

export const creatorHubValidationSchemas = [
	createCollectionSchema,
	customizeCollectionOneSchema,
	customizeCollectionTwoSchema,
	CustomizeDropSchema,
]

export type AccountContracts = {
	address: string
	contracts: FlowNFTContract[]
}
