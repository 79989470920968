import * as React from "react"
import { useCallback } from "react"
import { NavbarDesktop } from "./components/NavbarDesktop"
import { NavbarLaptop } from "./components/NavbarLaptop"
import { NavbarMobile } from "./components/NavbarMobile"
import { NavbarTablet } from "./components/NavbarTablet"

import { NavItemName } from "./types/NavbarTypes"
import { BaseNotification, NotificationData } from "flowty-common"
import { useBreakpoint } from "../utils/useBreakpoint/useBreakpoint"

export interface FlowtyNavbarProps {
	isLandingPage: boolean
	isCreatePage?: boolean
	notifications: NotificationData[]
	queueNotification?: ({
		type,
		id,
	}: {
		type: BaseNotification["type"]
		id: string
	}) => void
	isLogged: boolean
	loggedPopoverComponent: React.ReactNode
	mixpanelFn: (event: string) => void
	authLoading: boolean
	disableActions: boolean
	userAvatar?: string
	logUser: () => void
	selectedItem?: NavItemName
	searchControlsComponent: React.ReactNode
	className?: string
	navItems: JSX.Element[]
	hasMore: boolean
	registerHeight?: (height: number) => void
	fetchMoreNotifications: () => void
	loadingNotifications: boolean
	bgColor?: string
	isMainnet?: boolean
}
export const FlowtyNavbar: React.FunctionComponent<FlowtyNavbarProps> = ({
	isLandingPage,
	isCreatePage,
	isLogged,
	loggedPopoverComponent,
	notifications,
	queueNotification,
	mixpanelFn,
	authLoading,
	loadingNotifications,
	disableActions,
	userAvatar,
	fetchMoreNotifications,
	logUser,
	selectedItem,
	searchControlsComponent,
	hasMore,
	className,
	registerHeight,
	navItems,
	isMainnet,
}) => {
	const breakpoint = useBreakpoint()

	const [bgBlurLanding, setBgBlurLanding] = React.useState<string>(
		isLandingPage
			? "bg-blur-light"
			: isCreatePage
			? "bg-transparent"
			: "bg-[#04070B]"
	)
	React.useEffect(() => {
		const handleScroll = () => {
			const scrollPosition = window.scrollY || window.pageYOffset
			if (scrollPosition === 0) {
				if (isLandingPage) {
					setBgBlurLanding("bg-blur-light")
				} else {
					setBgBlurLanding("bg-[#04070B]")
				}
			} else {
				setBgBlurLanding("bg-blur")
			}
		}

		window.addEventListener("scroll", handleScroll)

		return () => {
			window.removeEventListener("scroll", handleScroll)
		}
	}, [])

	const isMarketplacePage = window.location.pathname.includes("/marketplace")

	const navbarProps = {
		authLoading,
		bgColor: bgBlurLanding,
		className,
		disableActions,
		fetchMoreNotifications,
		hasMore,
		isLandingPage,
		isLogged,
		isMainnet,
		isMarketplacePage,
		loadingNotifications,
		logUser,
		loggedPopoverComponent,
		mixpanelFn,
		navItems,
		notifications,
		queueNotification,
		registerHeight,
		searchControlsComponent,
		selectedItem,
		userAvatar,
	}

	const returnFlowtyNavbarBasedOnScreenSize = useCallback(() => {
		// Breakpoints: xs, mobile, tablet, desktop, lg
		switch (breakpoint) {
			case "xs":
				return <NavbarMobile {...navbarProps} />
			case "mobile":
				return <NavbarMobile {...navbarProps} />
			case "tablet":
				return <NavbarTablet {...navbarProps} />
			case "laptop":
				return <NavbarLaptop {...navbarProps} />
			case "desktop":
			case "xl":
				return <NavbarDesktop {...navbarProps} />
			default:
				return <NavbarDesktop {...navbarProps} />
		}
	}, [breakpoint, navbarProps])

	return returnFlowtyNavbarBasedOnScreenSize()
}
