import { useFlowtyCreatorHubContext } from "../../../../contexts/FlowtyCreatorHubContext"
import { FlowtyCreatorHubFormValues } from "../../../../types/FlowtyCreatorHubTypes"
import {
	renderErrorMessage,
	renderInputError,
} from "../../../../utils/formValidation"
import { CreatorHubRadioSelectGroup } from "../../../common/CreatorHubRadioGroup/CreatorHubRadioSelectGroup"
import { CreatorHubUploadImage } from "../../../common/CreatorHubUploadImage/CreatorHubUploadImage"
import { DateTimeSection } from "./components/DateTimeSection"
import { options } from "./utils/DropTypeRadioOptions"

export const CustomizeDropFormStep: React.FunctionComponent = () => {
	const { errors, touched, uploadImage, setFieldError, loggedUser } =
		useFlowtyCreatorHubContext()

	return (
		<div className='flex flex-1 flex-col justify-between self-stretch h-full'>
			<div className='flex flex-col gap-[16px] 3xl:gap-[24px] text-white'>
				<CreatorHubUploadImage
					title='Drop Page Image*'
					name='dropThumbnailImage'
					inputError={renderInputError<FlowtyCreatorHubFormValues>(
						"dropThumbnailImage",
						touched,
						errors
					)}
					errorMessage={renderErrorMessage<FlowtyCreatorHubFormValues>(
						"dropThumbnailImage",
						touched,
						errors
					)}
					imageType={"THUMBNAIL"}
					uploadImageFn={uploadImage}
					setFieldError={setFieldError}
					isLoggedUser={Boolean(loggedUser)}
				/>
				<DateTimeSection
					label='Start Date & Time (Local Time Zone)'
					name={"dropStartDateTime"}
					selectsStart={true}
					errors={errors}
					touched={touched}
				/>
				<DateTimeSection
					label='End Date & Time (Local Time Zone)'
					name={"dropEndDateTime"}
					selectsEnd={true}
					errors={errors}
					touched={touched}
				/>
				<div className='flex flex-col gap-[6.667px] 3xl:gap-[12px]'>
					<div className='flex-col text-white text-xs 3xl:text-lg font-bold'>
						Collection Type
					</div>
					<CreatorHubRadioSelectGroup options={options} classNames='flex-row' />
				</div>
			</div>
		</div>
	)
}
