import { ExternalURLWarning } from "ds-flowty"
import { useState } from "react"

interface InstagramIconProps {
	link: string
}

export const InstagramIcon: React.FunctionComponent<InstagramIconProps> = ({
	link,
}) => {
	const [onHover, setOnHover] = useState(false)
	const [isModalOpen, setIsModalOpen] = useState(false)
	return (
		<>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='18'
				height='18'
				viewBox='0 0 18 18'
				className='cursor-pointer h-5 w-[14px]'
				onClick={() => setIsModalOpen(true)}
				onMouseEnter={() => setOnHover(true)}
				onMouseLeave={() => setOnHover(false)}
				fill='#F8F9FA'
			>
				<path
					d='M5.4002 0.200195C2.53319 0.200195 0.200195 2.53319 0.200195 5.4002V12.6002C0.200195 15.4672 2.53319 17.8002 5.4002 17.8002H12.6002C15.4672 17.8002 17.8002 15.4672 17.8002 12.6002V5.4002C17.8002 2.53319 15.4672 0.200195 12.6002 0.200195H5.4002ZM5.4002 1.0002H12.6002C15.0348 1.0002 17.0002 2.9656 17.0002 5.4002V12.6002C17.0002 15.0348 15.0348 17.0002 12.6002 17.0002H5.4002C2.9656 17.0002 1.0002 15.0348 1.0002 12.6002V5.4002C1.0002 2.9656 2.9656 1.0002 5.4002 1.0002ZM13.8002 3.4002C13.588 3.4002 13.3845 3.48448 13.2345 3.63451C13.0845 3.78454 13.0002 3.98802 13.0002 4.2002C13.0002 4.41237 13.0845 4.61585 13.2345 4.76588C13.3845 4.91591 13.588 5.0002 13.8002 5.0002C14.0124 5.0002 14.2159 4.91591 14.3659 4.76588C14.5159 4.61585 14.6002 4.41237 14.6002 4.2002C14.6002 3.98802 14.5159 3.78454 14.3659 3.63451C14.2159 3.48448 14.0124 3.4002 13.8002 3.4002ZM9.0002 4.6002C6.57488 4.6002 4.6002 6.57488 4.6002 9.0002C4.6002 11.4255 6.57488 13.4002 9.0002 13.4002C11.4255 13.4002 13.4002 11.4255 13.4002 9.0002C13.4002 6.57488 11.4255 4.6002 9.0002 4.6002ZM9.0002 5.4002C10.9932 5.4002 12.6002 7.00723 12.6002 9.0002C12.6002 10.9932 10.9932 12.6002 9.0002 12.6002C7.00723 12.6002 5.4002 10.9932 5.4002 9.0002C5.4002 7.00723 7.00723 5.4002 9.0002 5.4002Z'
					stroke={onHover ? "#F8F9FA" : "transparent"}
					fill='#F8F9FA'
				/>
			</svg>
			<ExternalURLWarning
				isOpen={isModalOpen}
				linkURL={link}
				onCloseModal={() => setIsModalOpen(false)}
			/>
		</>
	)
}
