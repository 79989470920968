import { format, fromUnixTime } from "date-fns"
import { DROPSTATUS, DropType } from "ds-flowty"
import { DropStatusPill } from "ds-flowty/src/FlowtyDropPage/components/common/DropStatusPill/DropStatusPill"
import { getSquareImage } from "flowty-common"
import React, { useState } from "react"
import { Link } from "react-router-dom"
import { addFallbackImage } from "../../../util/images"
import { MergedCollections } from "../hooks/useProfileCreatorHub"
import { PriceDisplay } from "./PriceDisplay"

interface Props {
	contract: MergedCollections
	collectionName: string | undefined
	getDropStatus: (contract: DropType) => DROPSTATUS
}

export const CreatorHubCard: React.FunctionComponent<Props> = ({
	contract,
	collectionName,
	getDropStatus,
}) => {
	const [imageLoaded, setImageLoaded] = useState(false)
	const handleImageLoad = (): void => {
		setImageLoaded(true)
	}
	const imageCardSize = "w-full object-fill aspect-square"

	return (
		<Link
			to={`/collection/${contract?.drops[0]?.address}/${collectionName}?orderFilters=%7B"all"%3A%7B%7D%7D&sort=%7B"direction"%3A"desc"%2C"listingKind"%3Anull%2C"path"%3A"blockTimestamp"%7D&page=drop`}
			className='flex w-full max-sm:w-full relative self-center justify-self-center py-[16px] sm:py-[12px] px-[12px] sm:px-[14px] flex-col sm:items-start gap-[12px] rounded-[6.339px] border-[0.792px] border-neutral-300 bg-gradient-to-br from-[rgba(255,255,255,0.12)] to-[rgba(255,255,255,0.06)] backdrop-blur-[16.640px]'
		>
			<div className='w-full flex flex-col items-start gap-[12px]'>
				<div className='flex w-full  sm:h-[18px] justify-between items-center'>
					<div className='flex flex-column items-center gap-[12px]'>
						<img
							src={getSquareImage(
								contract?.collectionDisplay?.name ?? "",
								contract
							)}
							alt='thumbnail'
							onError={addFallbackImage}
							className='w-[52px] h-[52px] sm:hidden'
						/>

						<div className=' text-[#F8F9FA] sm:max-w-[180px] font-montserrat sm:truncate sm:overflow-hidden text-[14px] sm:text-[12px] 3xl:text-[14.263px] font-black leading-normal uppercase'>
							{contract?.collectionDisplay?.name}
						</div>
					</div>
					<div className='pl-[6px]'>
						<DropStatusPill
							dropStatus={getDropStatus(contract.drops?.[0])}
							card={true}
						/>
					</div>
				</div>

				{!imageLoaded && (
					<div
						className={`hidden sm:flex ${imageCardSize} animate-pulse bg-[#43ff640a] ${
							imageLoaded ? "hidden" : ""
						}`}
					></div>
				)}
				<div
					className={`hidden sm:flex w-full h-full ${
						!imageLoaded ? "sm:hidden" : "object-fill aspect-square"
					}`}
				>
					<img
						src={getSquareImage(
							contract?.collectionDisplay?.name ?? "",
							contract
						)}
						alt='thumbnail'
						onLoad={handleImageLoad}
						onError={addFallbackImage}
						className={`${
							imageLoaded ? "object-fill aspect-square w-full" : "hidden"
						}`}
					/>
				</div>
			</div>

			<div className='w-full flex flex-col justify-center items-center gap-[8px] self-stretch'>
				<div className='flex w-full justify-between items-end'>
					<span className='text-white font-montserrat text-[14px] sm:text-[10.366px] 3xl:text-[12px] font-normal leading-normal'>
						Mint Price
					</span>
					<span>
						<PriceDisplay
							price={contract?.drops?.[0]?.phases?.[0]?.quote?.price}
							paymentType={contract?.drops?.[0]?.phases?.[0]?.paymentTypes?.[0]}
							toolTipCustomId='drop-price-tooltip'
						/>
					</span>
				</div>
				<div className='flex w-full justify-between items-end'>
					<span className='text-[#CED4DA] font-montserrat text-[12px] sm:text-[8.638px] 3xl:text-[10px] font-normal leading-normal'>
						Minted
					</span>
					<span className='text-[#CED4DA] text-right font-montserrat text-[12px] sm:text-[8.638px] 3xl:text-[10px] font-medium leading-normal'>
						{contract?.drops[0]?.totalMinted}
					</span>
				</div>
				<div className='flex w-full justify-between items-end'>
					<span className='text-[#CED4DA] font-montserrat text-[12px] sm:text-[8.638px] 3xl:text-[10px] font-normal leading-normal'>
						Mint Start
					</span>
					<span className='text-[#CED4DA] text-right font-montserrat text-[12px] sm:text-[8.638px] 3xl:text-[10px] font-medium leading-normal'>
						{contract?.drops[0]?.phases[0]?.start &&
							format(
								fromUnixTime(
									parseInt(contract?.drops[0]?.phases[0]?.start, 10)
								),
								"yyyy-MM-dd HH:mm"
							)}
					</span>
				</div>
				<div className='flex w-full justify-between items-end'>
					<span className='text-[#CED4DA] font-montserrat text-[12px] sm:text-[8.638px] 3xl:text-[10px] font-normal leading-normal'>
						Mint End
					</span>
					<span className='text-[#CED4DA] text-right font-montserrat text-[12px] sm:text-[8.638px] 3xl:text-[10px] font-medium leading-normal'>
						{contract?.drops[0]?.phases[0]?.end &&
							format(
								fromUnixTime(parseInt(contract?.drops[0]?.phases[0]?.end, 10)),
								"yyyy-MM-dd HH:mm"
							)}
					</span>
				</div>
			</div>
		</Link>
	)
}
