import { SpotPrice } from "flowty-common"
import { FormikErrors, FormikTouched } from "formik"
import { PropsWithChildren } from "react"
import { FlowtyCreatorHubFormValues } from "./FlowtyCreatorHubTypes"

export interface FlowtyCreatorHubContextValues {
	changeSelectedFormTab: (tab: FormTabs) => void
	collectionUrlPathname: string
	formTab: FormTabs
	uploadImage?: (file: File) => Promise<{ cid: string } | null>
	moveToPreviousTab: () => void
	mixPanelFn: (event: string, data: unknown) => void
	confirmationPagePreviewTabs: string[]
	confirmationPageMobilePreviewTabs: string[]
	activeConfirmationPreviewTab: string
	spotPrice?: SpotPrice
	loggedUser?: string
	flowtyMarketplaceFee?: number | null
	onConfirmationPageTabClick: (tab: string) => void
	errors: FormikErrors<FlowtyCreatorHubFormValues>
	handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void
	setFieldError: (field: string, message: string) => void
	touched: FormikTouched<FlowtyCreatorHubFormValues>
	hasViewedDropPage: boolean
	setHasViewedDropPage: (value: boolean) => void
}
export interface FlowtyCreatorHubContextProps extends PropsWithChildren {
	mixPanelFn: (event: string, data: unknown) => void
	resetForm: () => void
	spotPrice: SpotPrice
	strapiUrl: string
	uploadIPFSImage: (image: File) => Promise<{ cid: string }>
	values: FlowtyCreatorHubFormValues
	flowtyMarketplaceFee?: number | null
	loggedUser?: string
	errors: FormikErrors<FlowtyCreatorHubFormValues>
	changeSelectedFormTab: (tab: FormTabs) => void
	formTab: FormTabs
	moveToNextTab: () => void
	moveToPreviousTab: () => void
	handleSubmit: (e?: React.FormEvent<HTMLFormElement>) => void
	setFieldError: (field: string, message: string) => void
	touched: FormikTouched<FlowtyCreatorHubFormValues>
}

export enum FormTabs {
	CreateCollection,
	CustomizeCollectionFirstTab,
	CustomizeCollectionSecondTab,
	CustomizeDropTab,
	ConfirmationTab,
}
