import { useFlowtyCreatorHubContext } from "../../contexts/FlowtyCreatorHubContext"
import { FormTabs } from "../../types/FlowtyCreatorHubContextTypes"
import { ConfirmationFormStep } from "./components/ConfirmationFormStep/ConfirmationFormStep"
import { CreateCollectionFormStep } from "./components/CreateCollectionFormStep/CreateCollectionFormStep"
import { CustomizeCollectionFormStep } from "./components/CustomizeCollectionFormStep/CustomizeCollectionFormStep"
import { CustomizeDropFormStep } from "./components/CustomizeDropFormStep/CustomizeDropFormStep"
import { FooterFormTab } from "./components/FooterFormTab/FooterFormTab"
import { HeaderFormTab } from "./components/HeaderFormTab/HeaderFormTab"

export const FormView: React.FunctionComponent = () => {
	const { formTab } = useFlowtyCreatorHubContext()

	const getTabContent = (): React.ReactNode => {
		switch (formTab) {
			case FormTabs.CreateCollection:
				return <CreateCollectionFormStep />
			case FormTabs.CustomizeCollectionFirstTab:
			case FormTabs.CustomizeCollectionSecondTab:
				return <CustomizeCollectionFormStep />
			case FormTabs.CustomizeDropTab:
				return <CustomizeDropFormStep />
			case FormTabs.ConfirmationTab:
				return <ConfirmationFormStep />
			default:
				return null
		}
	}

	return (
		<div className='flex flex-col gap-[20px] md:gap-[30px] 2xl:gap-[40px] w-full md:w-min'>
			<HeaderFormTab />
			<div className='h-full overflow-y-auto pr-2'>{getTabContent()}</div>
			<FooterFormTab />
		</div>
	)
}
