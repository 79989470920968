import { FormikErrors, FormikTouched } from "formik"
import { DateTimePicker } from "./DateTimePicker"
import { FlowtyCreatorHubFormValues } from "../../../../../types/FlowtyCreatorHubTypes"
import {
	renderErrorMessage,
	renderInputError,
} from "../../../../../utils/formValidation"

interface DateTimeSectionProps {
	label: string
	name: string
	selectsStart?: boolean
	selectsEnd?: boolean
	touched: FormikTouched<FlowtyCreatorHubFormValues>
	errors: FormikErrors<FlowtyCreatorHubFormValues>
}

export const DateTimeSection: React.FunctionComponent<DateTimeSectionProps> = ({
	label,
	name,
	selectsStart = false,
	selectsEnd = false,
	touched,
	errors,
}) => (
	<div className='flex flex-col gap-[8px]'>
		<div className='text-white text-xs 3xl:text-lg font-bold'>{label}</div>
		<DateTimePicker
			fieldName={name}
			selectsStart={selectsStart}
			selectsEnd={selectsEnd}
			inputError={renderInputError<FlowtyCreatorHubFormValues>(
				name as keyof FlowtyCreatorHubFormValues,
				touched,
				errors
			)}
			errorMessage={renderErrorMessage<FlowtyCreatorHubFormValues>(
				name as keyof FlowtyCreatorHubFormValues,
				touched,
				errors
			)}
		/>
	</div>
)
