import { FlowtyButton } from "ds-flowty"
import { CollectionTabsType } from "../../../../screens/CollectionPage/hooks/useCollectionPage"

export interface DropStatus {
	showDropTab: boolean
	dropStatus?: "LIVE" | "COMING" | "ENDED"
}

interface EmptyNftViewProps {
	isUnsupportedSelection: boolean
	showVisitDrop?: DropStatus
	onSelectedTabChange?: (tab: CollectionTabsType) => void
	emptyActionsPage?: boolean
}

const getContent = (
	isUnsupportedSelection: boolean,
	showVisitDrop: DropStatus,
	onSelectedTabChange: (tab: string) => void,
	emptyActionsPage?: boolean
): React.ReactNode | string => {
	if (isUnsupportedSelection) {
		return (
			<>
				<p className='text-lg'>
					Loans and Rentals are not supported on Dapper Wallet. Please connect
					with a non-Dapper Wallet to use those features.
				</p>
				<p className='text-lg'>
					For more information on Wallets, see our{" "}
					<a
						target='_blank'
						rel='noreferrer'
						className='underline'
						href='https://docs.flowty.io/reference/general-platform/supported-crypto-wallets'
					>
						supported wallets FAQ.
					</a>
				</p>
			</>
		)
	} else if (showVisitDrop.showDropTab) {
		return (
			<div className='flex flex-col'>
				<div className='text-xl font-bold text-[#6C757D]'>No Items Found</div>
				{showVisitDrop.dropStatus === "COMING" && (
					<div className='text-med text-[#6C757D] pb-2'>
						Collection isn&lsquo;t released yet.
					</div>
				)}
				<FlowtyButton
					text='Visit Drop'
					onClick={() => {
						onSelectedTabChange && onSelectedTabChange("DROP")
					}}
					variant={"secondary"}
				/>
			</div>
		)
	} else if (emptyActionsPage) {
		return "No active loans or rentals found"
	} else {
		return "No results. Please adjust your filters to see available listings."
	}
}

export const EmptyNftView: React.FunctionComponent<EmptyNftViewProps> = ({
	isUnsupportedSelection,
	showVisitDrop = {
		showDropTab: false,
	},
	onSelectedTabChange,
	emptyActionsPage,
}) => {
	return (
		<div className='flex flex-col items-center'>
			<div className='relative mr-5'>
				<svg
					width='82'
					height='105'
					viewBox='0 0 82 105'
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
				>
					<path
						d='M76.5 22.5L81.5 0H44C30.8 2.4 23.5 15.3333 21.5 21.5L0 105H26L35.5 67H53.5L58.5 43.5H41.5C46 27.5 47.5 22.5 50.5 22.5H76.5Z'
						className='fill-[#b3bbbe]'
					/>
				</svg>
				<div className='flex space-x-2 left-11 bottom-0 absolute'>
					<div className='w-5 h-5 rounded-full bg-[#B3BBBE]'></div>
					<div className='w-5 h-5 rounded-full bg-[#B3BBBE]'></div>
					<div className='w-5 h-5 rounded-full bg-[#B3BBBE]'></div>
				</div>
			</div>
			<div className='text-[#B3B7BC] mt-3 px-4 lg:px-8 text-center'>
				{getContent(
					isUnsupportedSelection,
					showVisitDrop,
					onSelectedTabChange as (tab: string) => void,
					emptyActionsPage
				)}
			</div>
		</div>
	)
}
