import { SupportedTokens, TokenMetadata } from "flowty-common"
import { Config } from "../types"

export class TokenService {
	config: Config

	getTokenIdentifier = (symbol: SupportedTokens): string => {
		let contractName = ""
		let contractAddress = ""

		switch (symbol) {
			case "FLOW":
				contractName = "FlowToken"
				contractAddress = this.config.contractAddresses.FlowToken
				break
			case "USDC":
				contractName = "USDCFlow"
				contractAddress = this.config.contractAddresses.USDCFlow
				break
			case "DUC":
				contractName = "DapperUtilityCoin"
				contractAddress = this.config.contractAddresses.DapperUtilityCoin
				break
			default:
				return ""
		}

		return `A.${contractAddress.substring(2)}.${contractName}.Vault`
	}

	// eslint-disable-next-line class-methods-use-this
	getTokenSymbols = (): SupportedTokens[] => {
		return ["FLOW", "USDC", "DUC"]
	}

	getTokenInfo(symbol: SupportedTokens): TokenMetadata {
		switch (symbol) {
			case "FLOW":
				return {
					balancePath: "/public/flowTokenBalance",
					contractAddress: this.config.contractAddresses.FlowToken,
					contractName: "FlowToken",
					receiverPath: "/public/flowTokenReceiver",
					storagePath: "/storage/flowTokenVault",
					symbol,
				}
			case "USDC":
				return {
					balancePath: "/public/usdcFlowMetadata",
					contractAddress: this.config.contractAddresses.USDCFlow,
					contractName: "USDCFlow",
					receiverPath: "/public/usdcFlowReceiver",
					storagePath: "/storage/usdcFlowVault",
					symbol,
				}
			case "DUC":
				return {
					balancePath: "/public/dapperUtilityCoinBalance",
					contractAddress: this.config.contractAddresses.DapperUtilityCoin,
					contractName: "DapperUtilityCoin",
					receiverPath: "/public/dapperUtilityCoinReceiver",
					storagePath: "/storage/dapperUtilityCoinVault",
					symbol,
				}
			default:
				throw new Error("Unsupported token")
		}
	}

	constructor(config: Config) {
		this.config = config
	}
}
