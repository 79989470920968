import BloctoLogo from "../../../../assets/blocto-footer.png"
import FlipperLogo from "../../../../assets/flipper.png"
import NufiLogo from "../../../../assets/nufi-footer.png"
import LilicoLogo from "../../../../assets/lilico-icon.png"

export const CreatorHubSignIn = () => {
	return (
		<div className='flex flex-col space-y-8'>
			<div className='font-bold text-[18px] text-white'>
				Non-Dapper Wallet Required
			</div>
			<div className='text-[#DEE2E6]'>
				To complete the creation of this NFT Collection, a non-Dapper wallet is
				required
			</div>
			<div className='flex flex-col'>
				<div className='font-montserrat text-[14px] mb-6 text-white uppercase font-bold'>
					Examples of Supported Wallets:
				</div>
				<div className='flex items-center space-x-4 text-[#CED4DA]'>
					<div className=''>
						<img src={BloctoLogo} className='img-fluid' alt='Blocto Logo' />
					</div>
					<div className=''>
						<img src={NufiLogo} className='img-fluid' alt='Nufi Logo' />
					</div>
					<div className=''>
						<img src={FlipperLogo} className='img-fluid' alt='Flipper Logo' />
					</div>
					<div className=''>
						<img src={LilicoLogo} className='img-fluid' alt='Lilico Logo' />
					</div>
				</div>
			</div>
		</div>
	)
}
