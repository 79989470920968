import { FlowtyButton } from "../../../../../FlowtyButton"
import { useFlowtyCreatorHubContext } from "../../../../contexts/FlowtyCreatorHubContext"
import { FormTabs } from "../../../../types/FlowtyCreatorHubContextTypes"
import { WarningComponent } from "../../../common/WarningComponent/WarningComponent"

export const FooterFormTab: React.FunctionComponent = () => {
	const { formTab, handleSubmit } = useFlowtyCreatorHubContext()

	const warningText =
		"Once you submit your collection, you will no longer be able to make any edits or changes"

	return (
		<div className='flex flex-col gap-4 3xl:gap-6 '>
			{formTab === FormTabs.CreateCollection && (
				<div className='text-xs 3xl:text-base italic text-[#ADB5BD] mt-auto'>
					{" "}
					{
						"Please note that to create a new collection you will need a non-Dapper Wallet (Flow Reference Wallet, Blocto or otherwise)"
					}
				</div>
			)}
			{formTab === FormTabs.ConfirmationTab && (
				<div className='md:mb-[12px]'>
					<WarningComponent text={warningText} />
				</div>
			)}
			<FlowtyButton
				type='submit'
				onClick={handleSubmit}
				text={`${
					formTab !== FormTabs.ConfirmationTab ? "NEXT" : "READY TO LAUNCH!"
				}`}
				variant='secondary'
				bgColor='white'
			/>
			<p className='text-[#ADB5BD] font-medium text-xs 3xl:text-base'>
				Need Help? Contact Us via{" "}
				<a
					href='https://discord.gg/flowty'
					target='_blank'
					rel='noreferrer'
					className='underline text-white font-semibold'
				>
					Discord
				</a>{" "}
				or{" "}
				<a
					href='https://x.com/flowty_io'
					target='_blank'
					rel='noreferrer'
					className='underline text-white font-semibold'
				>
					Twitter
				</a>
			</p>
		</div>
	)
}
