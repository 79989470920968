const createCollectionImageURL =
	"https://storage.googleapis.com/flowty-images/drops/collection-type-preview.webp"

export const CreateCollectionPreviewStep: React.FunctionComponent = () => {
	return (
		<div
			className='w-full h-full bg-cover bg-no-repeat bg-center'
			style={{
				backgroundImage: `url(${createCollectionImageURL})`,
			}}
			data-testid='create-collection-preview-step'
		/>
	)
}
