import {
	FLOWTY_INTEREST_FEE,
	FLOW_SCAN_TX_MAINNET_URL,
	FLOW_SCAN_TX_TESTNET_URL,
	FlowNFT,
	InitiatedTransactionNotification,
	NotificationType,
	OfferCreated,
	OpensearchFlowNFT,
	OpensearchStorefrontAvailableData,
	Order,
	OrdersType,
	SpotPrice,
	SupportedTokens,
	Valuation,
	checkIsLocked,
	nftTypeAndIdToLocationData,
} from "flowty-common"
import {
	AccountSummaries,
	AccountSummary,
	Flowty,
	useGetCollectionRoyalty,
	useGetFees,
} from "flowty-sdk"
import {
	PropsWithChildren,
	createContext,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react"
import { useLocation } from "react-router-dom"
import {
	NftReceiverFilter,
	PassThroughFilter,
	SelectorFilter,
} from "../../../FlowtyWalletSelector/FlowtyWalletSelector"
import {
	LoanListingOrderType,
	RentListingOrderType,
	StorefrontAcceptOfferOrderType,
	ValuationData,
} from "../../../Types/GlobalTypes"
import { getListingOrderData } from "../../../utils/GlobalUtils"
import { FlowtyListingFormValues } from "../../FlowtyListingModal"
import { FlowtyModalNavBtnProps } from "../../types/FlowtyModalTypes"
import { acceptOfferFnBuilder } from "../../utils/AcceptOfferModalFnBuilder"
import { delistingFnBuilder } from "../../utils/DelistingModalFnBuilder"
import { calcDiscountWithAmountAndValuation } from "../../utils/FlowtyModalUtils"
import { listingFnBuilder } from "../../utils/ListingModalFnBuilder"
import {
	FlowtyListingModalContextValues,
	ListingOrderDataType,
	ListingState,
} from "./types/ListingModalContextTypes"
import {
	getButtonText,
	getFuncionCall,
	getIsDisabledButton,
	getIsNotDisplay,
} from "./utils/PrimaryButtonUtils"
import {
	getSecondaryBtnFuncionCall,
	getSecondaryButtonIsNotDisplay,
	getSecondaryButtonText,
} from "./utils/SecondaryButtonUtils"

const defaultContext: FlowtyListingModalContextValues = {
	activeTab: "1",
	blendedLTV: null,
	childAccounts: undefined,
	closeBtnProps: {
		onClick: () => {},
		text: "",
	},
	collectionDisplayName: null,
	collectionImage: null,
	confirmBtnProps: {
		onClick: () => {},
		text: "",
	},
	createTransactionNotification: () => {},
	error: {
		loan: false,
		rent: false,
		sale: false,
		transfer: false,
	},
	existingPurchaseOrder: undefined,
	hasChildAccounts: false,
	hasNftReceiver: new PassThroughFilter(),
	hasProvider: false,
	isCatalog: false,
	isChildAccountAsset: false,
	isDapper: false,
	isDelist: {
		loan: false,
		rent: false,
		sale: false,
		transfer: false,
	},
	isFormError: {
		loan: false,
		rent: false,
		sale: false,
		transfer: false,
	},
	isLoading: {
		loan: false,
		rent: false,
		sale: false,
		transfer: false,
	},
	isLocked: false,
	isMainnet: false,
	isUpdateListing: false,
	listingType: "storefront",
	loanFormValues: {
		amountToBorrow: 0,
		amountToRepay: 0,
		listingDuration: 0,
		loanDuration: 0,
	},
	loanStep: 1,
	mainAccount: undefined,
	mixPanelFn: () => {},
	nftOwnerAccountSummary: undefined,
	onCloseModal: () => {},
	onTabChange: (tab: "1" | "2" | "3" | "4") => {},
	orderFromChildAccount: false,
	privateRentInvalidAddress: false,
	rentStep: 1,
	royaltyRate: 0,
	saleFees: undefined,
	sealed: {
		loan: false,
		rent: false,
		sale: false,
		transfer: false,
	},
	selectedAccount: undefined,
	selectedOrder: {
		fundLoan: null,
		fundRental: null,
		purchase: null,
	},
	setPrivateRentInvalidAddress: () => {},
	setSelectedAccount: () => {},
	setSelectedOrder: () => {},
	setTransferInvalidAddress: () => {},
	setTransferWalletSelected: () => {},
	spotPrice: undefined,
	strapiUrl: "",
	submitDelistTransaction: () => {},
	submitTransaction: () => {},
	transactionExplorerLink: null,
	transferInvalidAddress: false,
	transferReceiver: "",
	transferWalletSelected: "",
	updateSelectedOrder: () => {},
	valuationData: {
		percentage: null,
		source: "",
		usdValue: 0,
	},
}

const FlowtyListingModalContext =
	createContext<FlowtyListingModalContextValues>(defaultContext)

interface FlowtyListingModalContextProps extends PropsWithChildren {
	accountSummaries: AccountSummaries
	addressesWithCollectionPublic?: string[]
	hasProvider: boolean
	initialListingType?: "rent" | "loan" | "transfer"
	mainAccount: AccountSummary | undefined
	nftProviderPathIdentifier: string
	createTransactionNotification?: (
		data: InitiatedTransactionNotification
	) => void
	collectionDisplayName?: string | null
	flowNft?: FlowNFT | null
	selectedOffer?: OfferCreated
	openSearchFlowNFT: OpensearchFlowNFT
	mixPanelFn: (event: string, data: unknown) => void
	collectionImage: string | null
	nftOrders?: OrdersType
	singleAction?: "delistLoan" | "delistRental" | "delistSale"
	singleOrder?: Order
	onClose: () => void
	resetForm: () => void
	spotPrice?: SpotPrice
	strapiUrl?: string
	values: FlowtyListingFormValues
	valuation?: Valuation | null
	isLoadingValuation: boolean
	flowty: Flowty
}

export const FlowtyListingModalContextProvider: React.FC<
	FlowtyListingModalContextProps
> = ({
	accountSummaries,
	addressesWithCollectionPublic,
	children,
	createTransactionNotification,
	collectionDisplayName,
	initialListingType,
	hasProvider,
	mainAccount,
	collectionImage,
	nftProviderPathIdentifier,
	openSearchFlowNFT,
	flowNft,
	nftOrders,
	singleAction,
	singleOrder,
	selectedOffer,
	onClose,
	mixPanelFn,
	resetForm,
	spotPrice,
	strapiUrl,
	values,
	valuation,
	isLoadingValuation,
	flowty,
}) => {
	const [activeTab, setActiveTab] = useState<"1" | "2" | "3" | "4">(
		initialListingType === "loan" || singleAction === "delistLoan"
			? "2"
			: initialListingType === "rent" || singleAction === "delistRental"
			? "3"
			: initialListingType === "transfer"
			? "4"
			: "1"
	)
	const [loanStep, setLoanStep] = useState<1 | 2>(1)
	const [rentStep, setRentStep] = useState<1 | 2>(1)
	const [selectedAccount, setSelectedAccount] = useState<AccountSummary>(
		mainAccount || Object.values(accountSummaries || {})[0]
	)
	const [transactionID, setTransactionID] = useState<string | null>(null)
	const [error, setError] = useState<ListingState>({
		loan: false,
		rent: false,
		sale: false,
		transfer: false,
	})
	const [sealed, setIsSealed] = useState<ListingState>({
		loan: false,
		rent: false,
		sale: false,
		transfer: false,
	})
	const [isLoading, setIsLoading] = useState<ListingState>({
		loan: false,
		rent: false,
		sale: false,
		transfer: false,
	})
	const [isFormError, setIsFormError] = useState<ListingState>({
		loan: false,
		rent: false,
		sale: false,
		transfer: false,
	})
	const [isDelist, setIsDelist] = useState<ListingState>({
		loan: singleAction === "delistLoan",
		rent: singleAction === "delistRental",
		sale: singleAction === "delistSale",
		transfer: false,
	})
	const [isUpdateListing, setIsUpdateListing] = useState<boolean>(
		initialListingType === "loan" || initialListingType === "rent"
	)
	const [transferWalletSelected, setTransferWalletSelected] = useState("")
	const [selectedOrder, setSelectedOrder] = useState<{
		purchase?: Order | null
		fundLoan?: Order | null
		fundRental?: Order | null
	}>({
		fundLoan:
			singleAction === "delistLoan"
				? singleOrder
				: nftOrders?.loan?.[0] || null,
		fundRental:
			singleAction === "delistRental"
				? singleOrder
				: nftOrders?.rental?.[0] || null,
		purchase:
			singleAction === "delistSale"
				? singleOrder
				: nftOrders?.storefront?.find(order =>
						order.listingType.includes("NFTStorefrontV2.ListingAvailable")
				  ) || null,
	})
	const [isAcceptOffer, setIsAcceptOffer] = useState<boolean>(
		Boolean(selectedOffer)
	)

	const orderFromChildAccount = useMemo(() => {
		if (activeTab === "1") {
			return (
				selectedOrder.purchase?.flowtyStorefrontAddress !== mainAccount?.address
			)
		} else if (activeTab === "2") {
			return (
				selectedOrder.fundLoan?.flowtyStorefrontAddress !== mainAccount?.address
			)
		} else {
			return (
				selectedOrder.fundRental?.flowtyStorefrontAddress !==
				mainAccount?.address
			)
		}
	}, [singleOrder, mainAccount, activeTab, selectedOrder])

	const isDapper = selectedAccount?.isDapper
	const nftOwnerAccountSummary = accountSummaries?.[openSearchFlowNFT?.owner]
	const isChildAccountAsset =
		nftOwnerAccountSummary?.address !== mainAccount?.address

	const hasChildAccounts = useMemo(() => {
		return Object.values(accountSummaries || {}).length > 1
	}, [accountSummaries])

	const isParentDapper = hasChildAccounts && mainAccount?.isDapper
	const isNftOnDapperChild =
		hasChildAccounts && nftOwnerAccountSummary?.isDapper
	const isNftOnNonDapperChild =
		hasChildAccounts && isParentDapper && !nftOwnerAccountSummary?.isDapper

	const childAccounts = useMemo(() => {
		return Object.values(accountSummaries || {}).filter(
			(account: AccountSummary) => account.address !== mainAccount?.address
		)
	}, [accountSummaries, mainAccount])

	const transferSuggestedAccounts = useMemo(() => {
		return Object.values(accountSummaries || {}).filter(
			(account: AccountSummary) => account.address !== openSearchFlowNFT?.owner
		)
	}, [accountSummaries, openSearchFlowNFT])

	const ftReceiverAddress = selectedAccount?.address || openSearchFlowNFT?.owner

	const isMainnet = flowty.config.network === "mainnet"
	const TOPSHOT_ADDRESS = isMainnet
		? "0x0b2a3299cc857e29"
		: "0x877931736ee77cff"
	const TOPSHOT_TYPE = `A.${TOPSHOT_ADDRESS.substring(2)}.TopShot.NFT`

	const transactionExplorerLink = useMemo(() => {
		if (!transactionID) return null
		const flowscanBaseURL = isMainnet
			? FLOW_SCAN_TX_MAINNET_URL
			: FLOW_SCAN_TX_TESTNET_URL
		return `${flowscanBaseURL}/${transactionID}`
	}, [transactionID])

	const txAvailableCallback = (res: string | null): void =>
		setTransactionID(res)

	const bestOffer =
		isDapper && openSearchFlowNFT?.bestDapperOffer
			? (openSearchFlowNFT?.bestDapperOffer as OfferCreated)
			: isDapper && !openSearchFlowNFT?.bestDapperOffer
			? (openSearchFlowNFT?.bestNonCustodialOffer as OfferCreated)
			: !isDapper && openSearchFlowNFT?.bestNonCustodialOffer
			? (openSearchFlowNFT?.bestNonCustodialOffer as OfferCreated)
			: (openSearchFlowNFT?.bestDapperOffer as OfferCreated)

	const submitTransaction = async (): Promise<void> => {
		await listingFnBuilder({
			flowty,
			ftReceiverAddress,
			listingType,
			mixPanelFn,
			nftProviderPathIdentifier,
			openSearchFlowNFT,
			setError,
			setIsLoading,
			setIsSealed,
			transferWalletSelected,
			txAvailableCallback,
			values,
		})
	}

	const submitDelistTransaction = async (
		listingResourceID: string,
		order: Order | null
	): Promise<void> => {
		await delistingFnBuilder({
			flowty,
			listingResourceID,
			listingType,
			mixPanelFn,
			order,
			setError,
			setIsLoading,
			setIsSealed,
			txAvailableCallback,
		})
	}

	const submitOfferTransaction = async (): Promise<void> => {
		await acceptOfferFnBuilder({
			flowty,
			ftReceiverAddress,
			mixPanelFn,
			nftProviderPathIdentifier,
			nftStoragePath:
				flowNft?.nftView?.collectionData?.storagePath?.identifier || "",
			offerResourceID: selectedOffer
				? selectedOffer?.offerResourceID || ""
				: bestOffer?.offerResourceID || "",
			offerStorefrontAddress: selectedOffer
				? selectedOffer?.storefrontAddress || ""
				: bestOffer?.storefrontAddress || "",
			offerToken: selectedOffer
				? selectedOffer?.paymentTokenName || "FLOW"
				: bestOffer?.paymentTokenName || "FLOW",
			openSearchFlowNFT,
			setError,
			setIsLoading,
			setIsSealed,
			txAvailableCallback,
		})
	}

	const onTabChange = (tab: "1" | "2" | "3" | "4"): void => {
		setActiveTab(tab)
		setIsUpdateListing(false)
	}

	const updateIsDelist = (listingType: "sale" | "loan" | "rent"): void => {
		setIsDelist(prev => ({ ...prev, [listingType]: true }))
	}

	const cancelIsDelist = (listingType: "sale" | "loan" | "rent"): void => {
		setIsDelist(prev => ({ ...prev, [listingType]: false }))
	}

	const onCloseModal = (): void => {
		if (
			!isLoading.sale &&
			!isLoading.rent &&
			!isLoading.loan &&
			!isLoading.transfer
		) {
			setError({
				loan: false,
				rent: false,
				sale: false,
				transfer: false,
			})
			setIsSealed({
				loan: false,
				rent: false,
				sale: false,
				transfer: false,
			})
		}
		setIsFormError({
			loan: false,
			rent: false,
			sale: false,
			transfer: false,
		})
		setIsDelist({
			loan: false,
			rent: false,
			sale: false,
			transfer: false,
		})
		setLoanStep(1)
		setRentStep(1)
		resetForm()
		onClose()
	}

	const setFormError = (): void => {
		let curentListingType:
			| "sale"
			| "rent"
			| "loan"
			| "transfer"
			| "storefront"
			| "rental"
			| "acceptOffer" = listingType
		if (listingType === "storefront") {
			curentListingType = "sale"
		}
		if (listingType === "rental") {
			curentListingType = "rent"
		}
		setIsFormError(prev => ({ ...prev, [curentListingType]: true }))
	}

	const changeLoanStep = (): void => {
		setIsFormError(prev => ({ ...prev, loan: false }))
		setLoanStep(2)
	}

	const changeRentStep = (): void => {
		setIsFormError(prev => ({ ...prev, rent: false }))
		setRentStep(2)
	}

	const assetPageUrl = useMemo(() => {
		const locationData = nftTypeAndIdToLocationData(
			openSearchFlowNFT.type,
			openSearchFlowNFT.id
		)

		const pageOrigin = window.location.origin
		return `${pageOrigin}/asset/${locationData.contract.address}/${locationData.contract.name}/${locationData.resourceName}/${locationData.nftID}`
	}, [openSearchFlowNFT])

	const redirectToAssetPage = (): void => {
		window.open(assetPageUrl, "_blank")
	}

	const existingPurchaseOrder = useMemo(() => {
		if (nftOrders?.storefront?.length) {
			const isFlowty = nftOrders?.storefront.find(order => {
				return order.listingType.includes("NFTStorefrontV2.ListingAvailable")
			})
			if (isFlowty) {
				return nftOrders?.storefront
			}
		} else return null
	}, [nftOrders])

	const existingLoanOrder = useMemo(() => {
		if (nftOrders?.loan?.length) {
			return nftOrders?.loan
		} else return null
	}, [nftOrders])

	const existingRentalOrder = useMemo(() => {
		if (nftOrders?.rental?.length) {
			return nftOrders?.rental
		} else return null
	}, [nftOrders])

	const [privateRentInvalidAddress, setPrivateRentInvalidAddress] =
		useState(false)
	const [transferInvalidAddress, setTransferInvalidAddress] = useState(false)

	const nftType = openSearchFlowNFT?.type?.endsWith(".NFT")
		? openSearchFlowNFT?.type
		: `${openSearchFlowNFT?.type}.NFT`

	let isLocked = false
	if (nftType === TOPSHOT_TYPE) {
		isLocked = checkIsLocked(openSearchFlowNFT)
	}

	const isCatalog = useMemo(() => {
		return Boolean(flowNft?.isNFTCatalog)
	}, [flowNft])

	const location = useLocation()
	const isAssetDetailPage = useMemo(() => {
		return location.pathname.includes("/asset/")
	}, [location.pathname])

	const confirmBtnProps: FlowtyModalNavBtnProps = useMemo(() => {
		return {
			disabled: getIsDisabledButton({
				activeTab,
				existingPurchaseOrder,
				isAcceptOffer,
				isCatalog,
				isDapper,
				isDelist,
				isLoading,
				loanStep,
				openSearchFlowNFT,
				orderFromChildAccount,
				privateRentInvalidAddress,
				rentStep,
				selectedOffer,
			}),
			notDisplay: getIsNotDisplay({
				activeTab,
				hasProvider,
				isAssetDetailPage,
				isDapper,
				isDelist,
				isLocked,
				loanStep,
				rentStep,
				sealed,
			}),
			onClick: getFuncionCall({
				activeTab,
				changeLoanStep,
				changeRentStep,
				error,
				existingLoanOrder: Boolean(existingLoanOrder),
				existingPurchaseOrder,
				existingRentalOrder: Boolean(existingRentalOrder),
				isAcceptOffer,
				isDelist,
				isMainnet,
				isUpdateListing,
				loanStep,
				redirectToAssetPage,
				rentStep,
				sealed,
				selectedOffer,
				selectedOrder,
				setFormError,
				setIsUpdateListing,
				submitDelistTransaction,
				submitOfferTransaction,
				submitTransaction,
				transferWalletSelected,
				values,
			}),
			text: getButtonText({
				activeTab,
				error,
				existingLoanOrder: Boolean(existingLoanOrder),
				existingPurchaseOrder,
				existingRentalOrder: Boolean(existingRentalOrder),
				isAcceptOffer,
				isDelist,
				isLoading,
				isSelectedOffer: Boolean(selectedOffer),
				isUpdateListing,
				loanStep,
				rentStep,
				sealed,
			}),
		}
	}, [
		activeTab,
		loanStep,
		rentStep,
		values,
		isLoading,
		sealed,
		error,
		isUpdateListing,
		existingPurchaseOrder,
		isDelist,
		isAcceptOffer,
		selectedOffer,
		privateRentInvalidAddress,
		transferInvalidAddress,
		isLocked,
		isCatalog,
		transferWalletSelected,
		selectedAccount,
	])

	const closeBtnProps: FlowtyModalNavBtnProps = useMemo(() => {
		return {
			bgColor: "white",
			notDisplay: getSecondaryButtonIsNotDisplay({
				activeTab,
				isLoading,
				loanStep,
				rentStep,
				sealed,
			}),
			onClick: getSecondaryBtnFuncionCall({
				activeTab,
				cancelIsDelist,
				error,
				existingLoanOrder: Boolean(existingLoanOrder),
				existingPurchaseOrder,
				existingRentalOrder: Boolean(existingRentalOrder),
				hasProvider,
				isAcceptOffer,
				isDelist,
				isSingleOrder: Boolean(singleOrder),
				isUpdateListing,
				loanStep,
				onCloseModal,
				rentStep,
				sealed,
				selectedOffer,
				setError,
				setIsAcceptOffer,
				setIsUpdateListing,
				setLoanStep,
				setRentStep,
				updateIsDelist,
			}),
			text: getSecondaryButtonText({
				activeTab,
				error,
				existingLoanOrder: Boolean(existingLoanOrder),
				existingPurchaseOrder,
				existingRentalOrder: Boolean(existingRentalOrder),
				hasProvider,
				isAcceptOffer,
				isDapper,
				isDelist,
				isLocked,
				isSelectedOffer: Boolean(selectedOffer),
				isSingleOrder: Boolean(singleOrder),
				isUpdateListing,
				loanStep,
				rentStep,
				sealed,
			}),
		}
	}, [
		activeTab,
		loanStep,
		rentStep,
		sealed,
		isLoading,
		sealed,
		error,
		isUpdateListing,
		existingPurchaseOrder,
		isDelist,
		isAcceptOffer,
		isLocked,
		selectedOffer,
	])

	const listingType:
		| "loan"
		| "rental"
		| "storefront"
		| "transfer"
		| "acceptOffer" = useMemo(() => {
		return Boolean(selectedOffer) || isAcceptOffer
			? "acceptOffer"
			: activeTab === "1"
			? "storefront"
			: activeTab === "2"
			? "loan"
			: activeTab === "3"
			? "rental"
			: "transfer"
	}, [activeTab, isAcceptOffer, selectedOffer])

	let { fees, isLoading: isLoadingGetFees } = useGetFees({
		flowty,
		nft: flowNft ? flowNft : null,
		price:
			listingType === "storefront"
				? +values?.saleListingValue
				: listingType === "loan"
				? +values?.amountToBorrow
				: +values?.rentalFee,
		token: values?.tokenType as SupportedTokens,
	})
	let { royaltyRate, isLoading: isLoadingCollectionRoyalty } =
		useGetCollectionRoyalty({
			flowty: flowty,
			mixPanelFn: mixPanelFn,
			nft: flowNft ? flowNft : null,
		})

	// Storybook fees mock
	const IS_STORYBOOK = process.env.IS_STORYBOOK
	if (IS_STORYBOOK) {
		isLoadingCollectionRoyalty = false
		isLoadingGetFees = false
		fees = {
			dapper: Number(values?.saleListingValue) * 0.01,
			flowty: Number(values?.saleListingValue) * 0.02,
			royalties: Number(values?.saleListingValue) * 0.06,
			seller:
				Number(values?.saleListingValue) -
				Number(values?.saleListingValue) * 0.09,
		}

		royaltyRate = 0.06
	}

	const feeCalcValue = +values?.amountToRepay - +values?.amountToBorrow

	const flowtyFee = feeCalcValue > 0 ? FLOWTY_INTEREST_FEE * feeCalcValue : 0
	const royaltyFee = (royaltyRate || 0) * +values?.amountToBorrow

	const lenderFunds = +values?.amountToBorrow + (royaltyFee + flowtyFee)

	const orderData: ListingOrderDataType = useMemo(() => {
		return Boolean(singleOrder)
			? ({
					...singleOrder,
					listingKind:
						singleAction === "delistLoan"
							? "loan"
							: singleAction === "delistRental"
							? "rental"
							: "storefront",
			  } as Order)
			: Boolean(selectedOffer)
			? ({
					...selectedOffer,
					listingKind: "acceptOffer",
					purchaseOrder:
						(openSearchFlowNFT.orders?.find(
							order => order?.listingKind === "storefront"
						) as OpensearchStorefrontAvailableData) || undefined,
			  } as OfferCreated)
			: !isUpdateListing &&
			  Boolean(
					nftOrders?.[listingType as "loan" | "rental" | "storefront"]?.length
			  )
			? selectedOrder?.[
					listingType === "loan"
						? "fundLoan"
						: listingType === "rental"
						? "fundRental"
						: "purchase"
			  ]
			: getListingOrderData(
					listingType,
					values,
					lenderFunds,
					fees?.royalties || 0
			  )
	}, [
		values,
		listingType,
		singleOrder,
		selectedOffer,
		nftOrders,
		selectedOrder,
		singleAction,
		isUpdateListing,
		lenderFunds,
		fees,
		openSearchFlowNFT,
	])

	const discount = calcDiscountWithAmountAndValuation({
		salePrice: +values?.saleListingValue,
		spotPrice: spotPrice?.value || 0,
		token: values?.tokenType as SupportedTokens,
		valuation: valuation?.usdValue || 0,
	})

	const valuationData: ValuationData = {
		percentage: valuation?.usdValue ? discount : null,
		source: valuation?.source || "",
		usdValue: valuation?.usdValue || 0,
	}

	const blendedLTV = useMemo(() => {
		if (
			listingType === "loan" &&
			valuation?.usdValue &&
			valuation?.usdValue > 0
		) {
			return (lenderFunds / Number(valuation?.usdValue)) * 100
		}
		return null
	}, [values, valuation])

	const loanFormValues = useMemo(() => {
		return {
			amountToBorrow: +values?.amountToBorrow,
			amountToRepay: +values?.amountToRepay,
			listingDuration: values?.listingDuration,
			loanDuration: values?.loanDuration,
		}
	}, [values])

	const updateSelectedOrder = (order: Order | null): void => {
		if (activeTab === "1") {
			setSelectedOrder(prev => ({ ...prev, purchase: order }))
		} else if (activeTab === "2") {
			setSelectedOrder(prev => ({ ...prev, fundLoan: order }))
		} else {
			setSelectedOrder(prev => ({ ...prev, fundRental: order }))
		}
	}

	const transferReceiver = values?.transferReceiver

	useEffect(() => {
		if (transactionID && createTransactionNotification) {
			if (!transferReceiver && !selectedOffer) {
				switch (listingType) {
					case "storefront":
						createTransactionNotification({
							amount: +(orderData as StorefrontAcceptOfferOrderType).salePrice,
							collectionAddress: openSearchFlowNFT.contractAddress,
							collectionName: openSearchFlowNFT.contractName,
							imageUrl: openSearchFlowNFT?.card?.images?.[0].url ?? "",
							listingType: isDelist.sale ? "delistSale" : listingType,
							nftId: openSearchFlowNFT.id,
							nftType: openSearchFlowNFT.type,
							status: "PROCESSING",
							timestamp: Date.now(),
							title: openSearchFlowNFT?.card?.title ?? "",
							token: (orderData as StorefrontAcceptOfferOrderType)
								.paymentTokenName,
							transactionID,
							type: NotificationType.Transaction,
						})
						return
					case "rental":
						createTransactionNotification({
							amount: +(orderData as RentListingOrderType).amount,
							collectionAddress: openSearchFlowNFT.contractAddress,
							collectionName: openSearchFlowNFT.contractName,
							imageUrl: openSearchFlowNFT?.card?.images?.[0].url ?? "",
							listingType: isDelist.rent ? "delistRental" : listingType,
							nftId: openSearchFlowNFT.id,
							nftType: openSearchFlowNFT.type,
							status: "PROCESSING",
							timestamp: Date.now(),
							title: openSearchFlowNFT?.card?.title ?? "",
							token: (orderData as RentListingOrderType).paymentTokenName,
							transactionID,
							type: NotificationType.Transaction,
						})
						return
					case "loan":
						createTransactionNotification({
							amount: (orderData as LoanListingOrderType).derivations
								?.calculatedValues.marketplaceAmount,
							collectionAddress: openSearchFlowNFT.contractAddress,
							collectionName: openSearchFlowNFT.contractName,
							imageUrl: openSearchFlowNFT?.card?.images?.[0].url ?? "",
							listingType: isDelist.loan ? "delistLoan" : listingType,
							nftId: openSearchFlowNFT.id,
							nftType: openSearchFlowNFT.type,
							status: "PROCESSING",
							timestamp: Date.now(),
							title: openSearchFlowNFT?.card?.title ?? "",
							token: (orderData as LoanListingOrderType).paymentTokenName,
							transactionID,
							type: NotificationType.Transaction,
						})
						return
				}
			}
			if (transferReceiver) {
				createTransactionNotification({
					collectionAddress: openSearchFlowNFT.contractAddress,
					collectionName: openSearchFlowNFT.contractName,
					imageUrl: openSearchFlowNFT?.card?.images?.[0].url ?? "",
					listingType,
					nftId: openSearchFlowNFT.id,
					nftType: openSearchFlowNFT.type,
					status: "PROCESSING",
					timestamp: Date.now(),
					title: openSearchFlowNFT?.card?.title ?? "",
					transactionID,
					transferAddress: transferReceiver,
					type: NotificationType.Transaction,
				})
				return
			}
			if (selectedOffer) {
				createTransactionNotification({
					amount: selectedOffer?.amount,
					collectionAddress: openSearchFlowNFT.contractAddress,
					collectionName: openSearchFlowNFT.contractName,
					imageUrl: openSearchFlowNFT?.card?.images?.[0].url ?? "",
					listingType,
					nftId: openSearchFlowNFT.id,
					nftType: openSearchFlowNFT.type,
					status: "PROCESSING",
					timestamp: Date.now(),
					title: openSearchFlowNFT?.card?.title ?? "",
					token: selectedOffer?.paymentTokenName,
					transactionID,
					type: NotificationType.Transaction,
				})
				return
			}
			if (bestOffer) {
				createTransactionNotification({
					amount: bestOffer?.amount,
					collectionAddress: openSearchFlowNFT.contractAddress,
					collectionName: openSearchFlowNFT.contractName,
					imageUrl: openSearchFlowNFT?.card?.images?.[0].url ?? "",
					listingType,
					nftId: openSearchFlowNFT.id,
					nftType: openSearchFlowNFT.type,
					status: "PROCESSING",
					timestamp: Date.now(),
					title: openSearchFlowNFT?.card?.title ?? "",
					token: bestOffer?.paymentTokenName,
					transactionID,
					type: NotificationType.Transaction,
				})
				return
			}
		}
	}, [
		transactionID,
		transferReceiver,
		openSearchFlowNFT,
		selectedOffer,
		bestOffer,
		listingType,
		orderData,
		createTransactionNotification,
	])

	const acceptOffer = (): void => {
		setIsAcceptOffer(true)
	}

	const cancelAcceptOffer = (): void => {
		setIsAcceptOffer(false)
	}

	const hasNftReceiver = useMemo((): SelectorFilter<string[]> => {
		return new NftReceiverFilter(addressesWithCollectionPublic || [])
	}, [addressesWithCollectionPublic])

	return (
		<FlowtyListingModalContext.Provider
			value={{
				acceptOffer,
				activeTab,
				bestOffer,
				blendedLTV,
				cancelAcceptOffer,
				childAccounts,
				closeBtnProps,
				collectionDisplayName,
				collectionImage,
				confirmBtnProps,
				createTransactionNotification,
				error,
				existingLoanOrder,
				existingPurchaseOrder,
				existingRentalOrder,
				flowNft,
				hasChildAccounts,
				hasNftReceiver,
				hasOffer: openSearchFlowNFT?.offers?.length > 0,
				hasProvider,
				isAcceptOffer,
				isCatalog,
				isChildAccountAsset,
				isDapper,
				isDelist,
				isFormError,
				isLoading,
				isLoadingCollectionRoyalty,
				isLoadingGetFees,
				isLoadingValuation,
				isLocked,
				isMainnet,
				isNftOnDapperChild,
				isNftOnNonDapperChild,
				isParentDapper,
				isUpdateListing,
				listingType,
				loanFormValues,
				loanStep,
				mainAccount,
				mixPanelFn,
				nftOwnerAccountSummary,
				onCloseModal,
				onTabChange,
				openSearchFlowNFT,
				orderData,
				orderFromChildAccount,
				privateRentInvalidAddress,
				rentStep,
				royaltyRate,
				saleFees: fees,
				sealed,
				selectedAccount,
				selectedOffer,
				selectedOrder,
				setPrivateRentInvalidAddress,
				setSelectedAccount,
				setSelectedOrder,
				setTransferInvalidAddress,
				setTransferWalletSelected,
				singleAction,
				singleOrder,
				spotPrice,
				strapiUrl,
				submitDelistTransaction,
				submitTransaction,
				transactionExplorerLink,
				transferInvalidAddress,
				transferReceiver,
				transferSuggestedAccounts,
				transferWalletSelected,
				updateSelectedOrder,
				valuationData,
			}}
		>
			{children}
		</FlowtyListingModalContext.Provider>
	)
}

export const useFlowtyListingModalContext = () =>
	useContext(FlowtyListingModalContext) as FlowtyListingModalContextValues
