import { useField } from "formik"
import { setFutureEndDateTime } from "./DateTimePicker"
import { cn } from "../../../../../../utils/cn"
import { useEffect, useRef } from "react"

const generateTimeOptions = () => {
	const times = []
	for (let i = 0; i < 24; i++) {
		for (let j = 0; j < 60; j += 30) {
			const hour = i % 12 === 0 ? 12 : i % 12
			const minute = j.toString().padStart(2, "0")
			const period = i < 12 ? "AM" : "PM"
			times.push(`${hour}:${minute} ${period}`)
		}
	}
	return times
}

interface TimePickerProps {
	fieldName: string
	toggleTimePicker: () => void
	selectedTime: string | null
	setSelectedTime: React.Dispatch<React.SetStateAction<string | null>>
}
export const TimePicker: React.FC<TimePickerProps> = ({
	fieldName,
	toggleTimePicker,
	setSelectedTime,
	selectedTime,
}) => {
	const timeOptions = generateTimeOptions()
	const [dropStartDate] = useField<number>("dropStartDateTime")
	const [, dropEndDate, endDateHelpers] = useField<number>("dropEndDateTime")

	const [field, , helpers] = useField(fieldName)

	const selectedTimeRef = useRef<HTMLOptionElement>(null)

	useEffect(() => {
		if (selectedTimeRef?.current?.scrollIntoView) {
			selectedTimeRef?.current?.scrollIntoView()
		}
	}, [selectedTime])

	const handleTimeChange = (time: string): void => {
		setSelectedTime(time)
		if (time === "NOW") {
			const newDate = new Date(Date.now())

			helpers.setValue(newDate.getTime())

			toggleTimePicker()
			return
		}

		if (time) {
			const [hourPart, minutePart] = time.split(":")
			const minutes = minutePart.slice(0, 2)
			const period = minutePart.slice(3)
			let hours = parseInt(hourPart)

			if (period === "PM" && hours !== 12) {
				hours += 12
			} else if (period === "AM" && hours === 12) {
				hours = 0
			}

			const newDate = new Date(field.value || dropStartDate.value || Date.now())
			newDate.setHours(hours)
			newDate.setMinutes(parseInt(minutes))
			const formattedDate = newDate.getTime()
			helpers.setValue(formattedDate)
			toggleTimePicker()

			if (
				fieldName === "dropStartDateTime" &&
				formattedDate >= dropEndDate.value
			) {
				setFutureEndDateTime(newDate, endDateHelpers)
			}
		}
	}

	return (
		<div className='w-full rounded-lg bg-[#04070B] shadow-md'>
			<select
				id='timePicker'
				onChange={value => handleTimeChange(value.target.value)}
				className='cursor-pointer absolute left-0 mt-2  block w-full py-4 3xl:py-5 whitespace-nowrap text-[9.333px] 3xl:text-base font-medium border border-[#6C757D] bg-[#04070B] focus:outline-none sm:text-sm rounded-md overflow-y-scroll'
				size={7}
			>
				{fieldName === "dropStartDateTime" && (
					<option
						value={"NOW"}
						className={cn(
							"text-white py-[8px] select-none rounded px-5 3xl:px-6 hover:bg-[#575757]",
							{
								"bg-[#575757]/30": selectedTime === "NOW",
							}
						)}
					>
						NOW
					</option>
				)}
				{timeOptions.map((time, index) => (
					<option
						key={index}
						value={time}
						className={cn(
							"text-white py-[8px] select-none rounded px-5 3xl:px-6 hover:bg-[#575757]",
							{
								"bg-[#575757]/30": selectedTime === time,
							}
						)}
						{...(selectedTime === time && { ref: selectedTimeRef })}
					>
						{time}
					</option>
				))}
			</select>
		</div>
	)
}
