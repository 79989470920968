import { useFlowtyCreatorHubContext } from "../../contexts/FlowtyCreatorHubContext"
import { FormTabs } from "../../types/FlowtyCreatorHubContextTypes"
import { ConfirmationPreviewStep } from "./components/ConfirmationPreviewStep/ConfirmationPreviewStep"
import { CreateCollectionPreviewStep } from "./components/CreateCollectionPreviewStep/CreateCollectionPreviewStep"
import { CustomizeCollectionPreviewStep } from "./components/CustomizeCollectionPreviewStep/CustomizeCollectionPreviewStep"
import { CustomizeDropPreviewStep } from "./components/CustomizeDropPreviewStep/CustomizeDropPreviewStep"
import { HeaderPreviewTab } from "./components/HeaderPreviewTab/HeaderPreviewTab"

export const PreviewView: React.FunctionComponent = () => {
	const { formTab } = useFlowtyCreatorHubContext()

	const getTabContent = (): React.ReactNode => {
		switch (formTab) {
			case FormTabs.CreateCollection:
				return <CreateCollectionPreviewStep />
			case FormTabs.CustomizeCollectionFirstTab:
			case FormTabs.CustomizeCollectionSecondTab:
				return <CustomizeCollectionPreviewStep />
			case FormTabs.CustomizeDropTab:
				return <CustomizeDropPreviewStep />
			case FormTabs.ConfirmationTab:
				return <ConfirmationPreviewStep />
			default:
				return null
		}
	}

	return (
		<div className='w-full h-full flex flex-col gap-2 3xl:gap-4'>
			{formTab !== FormTabs.CreateCollection && <HeaderPreviewTab />}
			<div className='w-full flex-1 flex-col py-[12px] px-[24px] border border-[#6C757D] rounded-xl overflow-y-hidden'>
				{getTabContent()}
			</div>
		</div>
	)
}
