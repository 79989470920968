import React from "react"
import { CreatorHubRadioSelect } from "./CreatorHubRadioSelect"
import {
	CollectionEditionType,
	CollectionType,
} from "../../../types/FlowtyCreatorHubTypes"

export interface CreatorHubRadioSelectGroupProps {
	options: {
		description: string
		disabled: boolean
		name: string
		image: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
		labelMapping: Record<string, string>
		pillContent: string | null
		value: CollectionType | CollectionEditionType
	}[]
	classNames: string
	isLarge?: boolean
}

export const CreatorHubRadioSelectGroup: React.FC<
	CreatorHubRadioSelectGroupProps
> = ({ options, classNames, isLarge = false }) => {
	return (
		<div
			className={`flex ${classNames} ${isLarge ? "gap-4 3xl:gap-6" : "gap-3"}`}
		>
			{options.map(option => (
				<CreatorHubRadioSelect
					key={option.value}
					name={option.name}
					labelMapping={option.labelMapping}
					value={option.value}
					image={option.image}
					description={option.description}
					pillContent={option.pillContent}
					disabled={option.disabled}
					isLarge={isLarge}
				/>
			))}
		</div>
	)
}
