/* eslint-disable @typescript-eslint/no-explicit-any */
import { format } from "date-fns"
import { getImageURL } from "flowty-common"
import numeral from "numeral"
import noImage from "../../../../assets/media/image_not_available.svg"
import { getDurationPeriod } from "../../../../util/nftDataUtil"
import { isFlowRewards } from "../../../../util/settings"
import { ActivityTableImage } from "../../../Shared/ActivityTableFallbackImage/ActivityTableImage"
import { TokenAmount } from "../../../Tokens/tokenDisplays"

export const userLenderTableFields: (
	navigate: (path: string) => void
) => any = navigate => [
	{
		customRender: ({ item }: any) => (
			<div className='flex flex-row justify-start items-center capitalize'>
				{item?.state.toLowerCase()}
			</div>
		),
		name: "status",
		sortable: false,
		title: "Status",
	},
	{
		customRender: ({ item }: any) => (
			<div className='flex flex-row justify-start items-center'>
				{item.blockTimestamp
					? format(item.blockTimestamp.toDate(), "yyyy-MM-dd HH:mm")
					: ""}
			</div>
		),
		name: "loanStart",
		sortable: false,
		title: "Loan Start Date",
	},
	{
		customRender: ({ item }: any) => (
			<div className='flex flex-row justify-start items-center'>
				{item.settleDeadline
					? format(item.settleDeadline.toDate(), "yyyy-MM-dd HH:mm")
					: ""}
			</div>
		),
		name: "dueDate",
		sortable: false,
		title: "Due Date",
	},
	{
		customRender: ({ item }: any) => {
			const itemType = `A.${item.additionalData?.card?.collectionAddress?.substring(
				2
			)}.${item.additionalData?.card?.collectionName}.NFT`

			return (
				<div>
					{!!item.detail?.card && (
						<div className='flex flex-row justify-start items-center'>
							<div className='w-[40px] mr-2'>
								<ActivityTableImage
									collectionAddress={
										item?.detail?.card?.collectionAddress || ""
									}
									collectionName={item?.detail?.card?.collectionName || ""}
									srcImg={getImageURL(
										item?.detail?.card?.images?.[0]?.url ?? noImage,
										isFlowRewards(itemType)
									)}
								/>
							</div>
							<div className='flex flex-col'>
								{item.detail?.card?.additionalDetails ? (
									<span className='text-[12px] text-primary font-montserrat font-extrabold truncate'>
										{item.detail?.card?.title}
									</span>
								) : (
									<>
										<span className='text-[12px] text-primary font-montserrat font-extrabold truncate'>
											{item.detail?.card?.title}
										</span>
										{item.detail?.card?.num &&
											!item.detail?.card?.title.includes("#") && (
												<span className='text-[12px] text-primary font-montserrat font-extrabold ml-2'>
													#{item.detail?.card?.num}
												</span>
											)}
									</>
								)}
								{item?.detail?.card?.additionalDetails ? (
									<>
										{item?.detail?.card?.additionalDetails ? (
											item?.detail?.card?.additionalDetails?.map(
												(detail: any) => (
													<span
														key={detail}
														className='font-montserrat text-primary text-[12px] font-light'
													>
														{detail}
													</span>
												)
											)
										) : (
											<div className='flex flex-row items-center justify-start mt-2 h-4'></div>
										)}
									</>
								) : (
									<>
										{item?.detail?.card?.headerTraits &&
											item?.detail?.card?.headerTraits?.map((trait: any) => (
												<span
													key={trait.displayType}
													className='font-montserrat text-primary text-[12px] font-light'
												>
													{trait.value}
												</span>
											))}
									</>
								)}
							</div>
						</div>
					)}
				</div>
			)
		},
		name: "detail",
		sortable: false,
		title: "Details",
	},
	{
		customRender: ({ item }: any) => (
			<div className='flex flex-row justify-start items-center'>
				<TokenAmount
					amount={Number(
						item?.derivations?.calculatedValues?.marketplaceAmount ?? 0
					)}
					isSmall
					token={item.paymentTokenName || "FUSD"}
				/>
			</div>
		),
		name: "amount",
		sortable: false,
		title: "Amount",
	},
	{
		customRender: ({ item }: any) => (
			<div className='flex flex-row justify-start items-center'>
				{(
					(item?.derivations?.calculatedValues?.periodicInterest ?? 0) * 100
				).toFixed(2)}
				%
			</div>
		),
		name: "rate",
		sortable: false,
		title: "Rate",
	},
	{
		customRender: ({ item }: any) => (
			<div className='flex flex-row justify-start items-center'>
				{getDurationPeriod(item.term)}
			</div>
		),
		name: "duration",
		sortable: false,
		title: "Duration",
	},
	{
		customRender: ({ item }: any) => (
			<div className='flex flex-row justify-start items-center'>
				{numeral(item?.derivations?.marketplaceAPR ?? 0).format("0,0.00")}%
			</div>
		),
		name: "apr",
		sortable: false,
		title: "APR",
	},
	{
		customRender: ({ item }: any) => (
			<div className='flex flex-row justify-center items-center'>
				<TokenAmount
					amount={item?.derivations?.calculatedValues?.totalRepayment ?? 0}
					isSmall
					token={item.paymentTokenName || "FUSD"}
				/>
			</div>
		),
		name: "repayment",
		sortable: false,
		title: "Repayment Amount",
	},
	{
		customRender: ({ item }: any) => (
			<div
				onClick={e => {
					e.preventDefault()
					e.stopPropagation()
					navigate(`/user/${item?.flowtyStorefrontAddress}`)
				}}
				className='flex flex-row justify-start items-center hover:text-green-300'
			>
				<a href={`/user/${item?.flowtyStorefrontAddress}`}>
					{item?.flowtyStorefrontAddress || "--"}
				</a>
			</div>
		),
		name: "borrower",
		sortable: false,
		title: "Borrower",
	},
]
