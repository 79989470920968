import { ReactComponent as ComingSoonBadge } from "../../../../assets/comingSoonBadge.svg"
import { ReactComponent as multiImage } from "../../../../assets/multiImage.svg"
import { ReactComponent as singleImage } from "../../../../assets/singleImage.svg"
import { CollectionType } from "../../../../types/FlowtyCreatorHubTypes"
import { CreatorHubRadioSelectGroup } from "../../../common/CreatorHubRadioGroup/CreatorHubRadioSelectGroup"

export const collectionTypeNames: Record<CollectionType, string> = {
	[CollectionType.SingleImage]: "Single Image",
	[CollectionType.MultiImage]: "Multiple Images",
}

export const CreateCollectionFormStep: React.FunctionComponent = () => {
	const options = [
		{
			description:
				"Create and manage a single image NFT collection. Customize collection metadata to highlight your unique creation.",
			disabled: false,
			image: singleImage,
			labelMapping: collectionTypeNames,
			name: "collectionType",
			pillContent: null,
			value: CollectionType.SingleImage,
		},
		{
			description:
				"Create and manage a multi-image NFT collection. Offer collectors more choices with multiple NFTs in one collection. Introduce trait variety for your collectors.",
			disabled: true,
			image: multiImage,
			labelMapping: collectionTypeNames,
			name: "collectionType",
			pillContent: "Coming Soon",
			value: CollectionType.MultiImage,
		},
	]

	return (
		<div className='flex flex-1 flex-col justify-between items-start self-stretch h-full'>
			<div className='flex flex-col gap-4 3xl:gap-6'>
				<h1 className='text-white text-base 3xl:text-2xl font-bold'>
					Collection Type
				</h1>
				<CreatorHubRadioSelectGroup
					options={options}
					classNames='flex-col'
					isLarge
				/>
			</div>
		</div>
	)
}
