import { useField } from "formik"
import React, { ReactNode, useCallback, useState } from "react"
import { useDropzone } from "react-dropzone"
import { Oval } from "react-loader-spinner"
import { cn } from "../../../../utils/cn"
import { FLOWTY_QUICKNODE_IPFS_URL } from "../../../../utils/constants"
import { ReactComponent as DeleteIcon } from "../../../assets/delete.svg"
import { ReactComponent as UploadIcon } from "../../../assets/upload-icon.svg"
import { FileType } from "../../../types/FlowtyCreatorHubTypes"

type ImageType = "THUMBNAIL" | "BANNER"

interface CreatorHubUploadImageProps {
	title: string
	name: string
	imageType: ImageType
	toolTip?: ReactNode
	inputError?: boolean
	errorMessage?: string | null
	uploadImageFn?: (file: File) => Promise<{ cid: string } | null>
	setFieldError: (field: string, message: string) => void
	isLoggedUser?: boolean
}

export const CreatorHubUploadImage: React.FC<CreatorHubUploadImageProps> = ({
	title,
	name,
	imageType,
	toolTip,
	inputError,
	errorMessage,
	uploadImageFn,
	setFieldError,
	isLoggedUser = false,
}) => {
	const [image, , { setValue }] = useField(name)
	const [isLoading, setIsLoading] = useState(false)
	const [imageLoaded, setImageLoaded] = useState(false)
	const [fieldErrorMessage, setFieldErrorMessage] = useState<string | null>(
		null
	)
	const [warningMessage, setWarningMessage] = useState<string | null>(null)
	const [imageUploadError, setImageUploadError] = useState<boolean>(false)

	const handleImageLoad = (): void => {
		setImageLoaded(true)
	}

	const onDrop = useCallback(async (files: File[]) => {
		setIsLoading(true)
		const file = files[0]
		if (file) {
			if (file.size > 5000000) {
				setFieldError(name, "Max. file size 5MB")
				setFieldErrorMessage("Max. file size 5MB")
				setIsLoading(false)
				return
			}
			setFieldErrorMessage(null)
			const reader = new FileReader()
			reader.onloadend = async () => {
				const img = new Image()
				img.src = reader.result as string
				img.onload = async () => {
					const { width, height } = img
					if (imageType === "THUMBNAIL" && width / height !== 1) {
						setWarningMessage(
							"For optimal display, we recommend using a square image (1:1 aspect ratio)"
						)
					} else if (imageType === "BANNER" && width / height !== 4) {
						setWarningMessage(
							"For optimal display, we recommend using an image with a 4:1 aspect ratio"
						)
					} else {
						setWarningMessage(null)
					}
				}
				if (uploadImageFn && isLoggedUser) {
					const response = await uploadImageFn(file)
					if (!response) {
						setImageUploadError(true)
						setFieldError(name, "Failed to upload image")
						setIsLoading(false)
						return
					}
					const value: FileType = {
						file: file,
						fileBase64: reader.result as string,
						fileCid: String(response?.cid),
						fileName: file.name,
					}
					setImageUploadError(false)
					setValue(value)
					setIsLoading(false)
				} else {
					setValue({
						file: file,
						fileBase64: reader.result as string,
						fileCid: null,
						fileName: file.name,
					})
					setIsLoading(false)
				}
			}
			reader.readAsDataURL(file)
		} else {
			setIsLoading(false)
		}
	}, [])

	const { getRootProps, getInputProps } = useDropzone({
		accept: {
			"image/gif": [],
			"image/jpeg": [],
			"image/png": [],
			"image/svg+xml": [],
		},
		maxFiles: 1,
		onDrop,
	})
	return (
		<div className='w-full flex flex-col gap-[8px] font-montserrat'>
			<div className='flex items-center gap-x-2'>
				<div className='text-white text-xs 3xl:text-lg font-bold'>{title}</div>
				{toolTip}
			</div>
			<div
				className={cn(
					"bg-[linear-gradient(153deg,_#ffffff12_0%,_#ffffff0a_100%)] overflow-hidden w-full h-[128px] space-y-1 flex flex-col items-center justify-center rounded-lg border border-[#495057] cursor-pointer",
					{
						"border-[#495057]": !inputError,
						"border-[#FF6E25]":
							inputError || fieldErrorMessage || imageUploadError,
					}
				)}
				{...getRootProps()}
			>
				{!image.value ? (
					<>
						{" "}
						<input {...getInputProps()} data-testid={name} />
						<div className='flex flex-col items-center justify-center w-full h-full gap-2 3xl:gap-4'>
							<div>
								{isLoading ? (
									<Oval
										width={40}
										height={46}
										color='#6C757D'
										secondaryColor='#000'
									/>
								) : (
									<UploadIcon />
								)}
							</div>
							<div className='gap-[2.667px] 3xl:gap-1'>
								<div className='text-[9.333px] leading-[13.333px] 3xl:text-sm 3xl:leading-5 text-center'>
									<span className='text-white text-[9.333px] leading-[13.333px] 3xl:text-sm 3xl:leading-5'>
										Click to Upload
									</span>
									<span className='text-[#6C757D] ml-1 text-[9.333px] leading-[13.333px] 3xl:text-sm 3xl:leading-5'>
										or drag and drop
									</span>
								</div>
								<div className='text-[#6C757D] text-[8px] 3xl:text-xs leading-3 3xl:leading-5 text-center'>
									SVG, PNG, JPG, or GIF (max. 800x400px)
								</div>
							</div>
						</div>
					</>
				) : (
					<div className='w-full h-[94px] px-[24px] flex justify-between'>
						<div className='w-full flex items-center gap-[24px]'>
							<img
								src={
									image.value?.fileCid
										? `${FLOWTY_QUICKNODE_IPFS_URL}${image.value?.fileCid}`
										: image.value.fileBase64
								}
								alt='uploaded'
								className={`rounded-lg ${!imageLoaded ? "hidden" : ""} ${
									imageType === "BANNER"
										? "w-[240px] h-[60px]"
										: "w-[94px] h-[94px]"
								}`}
								onLoad={handleImageLoad}
							/>
							<div
								className={`w-[94px] h-[94px] rounded-lg animate-pulse bg-[#edf3f60a] ${
									imageLoaded ? "hidden" : ""
								}`}
							/>
							<p className='text-white max-w-[180px] text-xs 3xl:text-sm overflow-hidden text-ellipsis'>
								{image.value?.fileName}
							</p>
						</div>
						<button
							data-testid='delete-image'
							className='self-start'
							onClick={e => {
								e.stopPropagation()
								setValue(null)
							}}
						>
							<DeleteIcon className=' h-5 w-5 3xl:h-6 3xl:w-6' />
						</button>
					</div>
				)}
			</div>
			{imageUploadError && (
				<p
					data-testid={`${name}-error`}
					className='w-auto text-end text-[#FF6E25] text-xs font-[400] ml-auto'
				>
					Image error. If this issue persists, contact us via{" "}
					<a
						href='https://discord.com/invite/flowty'
						target='_blank'
						rel='noreferrer'
						className='text-white hover:underline'
					>
						Discord
					</a>{" "}
					or{" "}
					<a
						href='https://x.com/flowty_io'
						target='_blank'
						rel='noreferrer'
						className='text-white hover:underline'
					>
						Twitter
					</a>
					.
				</p>
			)}
			{!imageUploadError &&
				(errorMessage || fieldErrorMessage || warningMessage) && (
					<p
						data-testid={`${name}-error`}
						className='w-full text-end text-[#FF6E25] text-xs whitespace-nowrap font-[400]'
					>
						{errorMessage ?? fieldErrorMessage ?? warningMessage}
					</p>
				)}
		</div>
	)
}
