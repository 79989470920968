import { getSquareImage } from "flowty-common"
import { Tooltip } from "react-tooltip"
import CheckMark from "../../../../assets/media/CatalogVerified2.png"
import FallbackImg from "../../../../assets/new_fallback_image.svg"
import { addFallbackImage } from "../../../../util/images"
import { useCollectionPage } from "../../hooks/useCollectionPage"
import { CollectionMetrics } from "../CollectionMetrics/CollectionMetrics"
import { EnableAccountBox } from "../EnableAccountBox/EnableAccountBox"
import { SocialLinks } from "../SocialLinks/SocialLinks"
import { LoadingCollectionBanner } from "./LoadingCollectionBanner"

interface CollectionPageBanner {
	collectionName: string | undefined
	loggedUserAddr: string
	loggedIsDapper: boolean
	totalItems: number | undefined
	floorPrice: number | undefined
	totalOwners: number | undefined
	weekTopSales: number | undefined
	createdDate: string | undefined
}

export const CollectionPageBanner: React.FunctionComponent<
	CollectionPageBanner
> = ({
	collectionName,
	loggedUserAddr,
	loggedIsDapper,
	totalItems,
	floorPrice,
	totalOwners,
	weekTopSales,
	createdDate,
}) => {
	const {
		collection,
		isLoading: isCollectionLoading,
		collectionDropData,
	} = useCollectionPage()

	const socials = collection?.collectionDisplay?.socials
	const twitter = socials?.twitter?.url
	const discord = socials?.discord?.url
	const instagram = socials?.instagram?.url
	const floatExternalLink = "https://floats.city/"
	const websiteLink =
		collectionName && collectionName.includes("FLOAT")
			? floatExternalLink
			: collection?.collectionDisplay?.externalURL.url
	const collectionDescription = collection?.collectionDisplay?.description || ""

	return (
		<>
			{isCollectionLoading ? (
				<LoadingCollectionBanner />
			) : (
				<div className='flex flex-col gap-[1.2rem] py-8'>
					<div className='flex flex-row space-x-4 items-center flex-grow'>
						<div className='flex flex-row space-x-4 items-center gap-4 grow'>
							<div>
								<img
									className='h-[96px] w-[96px] min-w-[96px] xl:h-[92px] xl:w-[92px] xl:min-w-[92px] bg-cover bg-no-repeat rounded-[12px] sm:h-18 sm:w-18 shadow-customCollectionPage border border-[#6C757D] backdrop-blur-[32px] bg-glassFillGray bg-blur'
									src={
										collectionName
											? getSquareImage(collectionName, collection)
											: FallbackImg
									}
									alt={
										collection?.collectionDisplay?.name
											? collection?.collectionDisplay?.name
											: ""
									}
									onError={addFallbackImage}
								/>
							</div>
							<div className='flex flex-col items-start justify-start gap-y-3'>
								<div className='flex flex-row items-center'>
									<span className='text-2xl sm:text-3xl font-bold truncate max-sm:max-w-[240px] max-w-[640px]'>
										{collectionName?.replaceAll("-", " ")}
									</span>
									{collection?.isNFTCatalog && (
										<div className='pl-3'>
											<Tooltip
												id={`${collection?.name}-verified-tooltip`}
												style={{
													backdropFilter: "blur(100px)",
													backgroundColor: "#33333380",
													zIndex: 999,
												}}
												anchorSelect='#anchor-select'
												clickable
											>
												<div className='flex flex-col gap-2 z-50'>
													<div className='flex gap-1'>
														<img
															src={CheckMark}
															alt='Catalog check mark'
															className='w-[16px] h-[16px]'
														/>
														<p className='text-sm font-medium text-[#F8F9FA]'>
															Verified Collection
														</p>
													</div>
													<div className='flex flex-col space-y-1.5'>
														<p className='text-sm font-normal text-[#ADB5BD] leading-none'>
															Verified by the
														</p>
														<a
															href='https://www.flow-nft-catalog.com/'
															target='_blank'
															rel='noreferrer'
															className=' text-sm font-normal text-[#04E5A3] leading-none'
														>
															Flow NFT Catalog
														</a>
													</div>
												</div>
											</Tooltip>
											<button id='anchor-select'>
												<img
													className='w-[18px] h-[18px] sm:w-[22px] sm:h-[22px]'
													src={CheckMark}
													alt='Catalog check mark'
													data-tooltip-place='top'
													data-tooltip-id={`${collection?.name}-verified-tooltip`}
												/>
											</button>
										</div>
									)}
								</div>
								<div>
									<SocialLinks
										websiteLink={websiteLink}
										discord={discord}
										twitter={twitter}
										instagram={instagram}
									/>
								</div>
							</div>
						</div>
						<div className='hidden md:block'>
							<EnableAccountBox
								isNFTCatalog={collection?.isNFTCatalog}
								loggedIsDapper={loggedIsDapper}
								loggedUserAddr={loggedUserAddr}
								websiteLink={websiteLink}
							/>
						</div>
					</div>
					<div className='flex flex-row max-md:flex-col max-md:items-start gap-3 items-center flex-grow'>
						{collectionDescription && (
							<div className='grow w-full max-w-4xl py-4 px-6 rounded-md bg-white bg-opacity-5 backdrop-blur-lg custom-collection-border custom-collection-bg'>
								<p className='text-[#ADB5BD] text-sm font-medium'>
									{collectionDescription}
								</p>
							</div>
						)}
						<div className='w-full flex justify-end max-md:justify-start m-0'>
							<CollectionMetrics
								createdDate={createdDate}
								floorPrice={floorPrice}
								totalItems={totalItems}
								totalOwners={totalOwners}
								weekTopSales={weekTopSales}
								collectionRoyalty={collectionDropData?.royaltyRate}
							/>
						</div>
					</div>
				</div>
			)}
		</>
	)
}
