import { FlowtyCreatorHubFormValues } from "../types/FlowtyCreatorHubTypes"

export const creatorHubCacheFn = (values: FlowtyCreatorHubFormValues) => {
	const valuesWithImages = {
		...values,
		dropThumbnailImage: {
			...values.dropThumbnailImage,
			fileBase64: undefined,
		},
		nftBannerImage: {
			...values.nftBannerImage,
			fileBase64: undefined,
		},
		nftImage: {
			...values.nftImage,
			fileBase64: undefined,
		},
		nftThumbnailImage: {
			...values.nftThumbnailImage,
			fileBase64: undefined,
		},
	}
	localStorage.setItem("creatorHubCache", JSON.stringify(valuesWithImages))
}

export const getCreatorHubCachedValues =
	(): FlowtyCreatorHubFormValues | null => {
		const cachedValues = localStorage.getItem("creatorHubCache")
		if (!cachedValues) {
			return null
		}

		return JSON.parse(cachedValues)
	}

export const cleanCreatorHubCache = async (): Promise<void> => {
	await localStorage.removeItem("creatorHubCache")
}
