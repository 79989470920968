import { SupportedTokens } from "flowty-common"
import { FlowtyTokenIcon } from "../../../../FlowtyTokenIcon"
import { SpotPriceValue } from "../SpotPriceValue/SpotPriceValue"
import { Tooltip } from "react-tooltip"
import numeral from "numeral"

interface NftPriceProps {
	price: number
	spotPriceValue?: number
	token: SupportedTokens
	toolTipCustomId?: string
	isCreatorHub?: boolean
}

export const NftPrice = ({
	price,
	spotPriceValue,
	token,
	toolTipCustomId,
	isCreatorHub,
}: NftPriceProps) => {
	if (!token) return null

	const isSpotPrice =
		token !== "DUC" && token !== "FUSD" && token !== "USDC" && token !== "USDT"

	let tooltip
	switch (token?.toLowerCase() ?? "") {
		case "fusd":
			tooltip = "FUSD"
			break
		case "flow":
			tooltip = "FLOW"
			break
		case "fut":
			tooltip = "Dapper FLOW"
			break
		case "usdc":
			tooltip = "USDC"
			break
		case "duc":
			tooltip = "Dapper Balance"
			break
		case "sloppy":
		case "sloppystakes":
			tooltip = "Sloppy Stakes"
			break
		case "usdt":
			tooltip = "USDT"
			break
		default:
			return null
	}

	return (
		<>
			<Tooltip
				id={`${toolTipCustomId}-${token}-${price}-tooltip`}
				style={{
					backgroundColor: "white",
					color: "#031021",
					fontSize: "0.875rem",
					fontWeight: 500,
					zIndex: 10,
				}}
			/>
			<div
				data-tooltip-id={`${toolTipCustomId}-${token}-${price}-tooltip`}
				data-tooltip-content={tooltip}
				data-tooltip-place='right'
				className={`flex flex-col justify-center`}
			>
				<div className='flex items-center flex-shrink-0 w-auto justify-end gap-[0.25rem]'>
					<FlowtyTokenIcon token={token} />
					<span
						className={`text-white font-montserrat ${
							isCreatorHub ? "text-[9.33px] 3xl:text-[16px]" : "text-[1rem]"
						} font-[700]`}
					>
						{numeral(price).format("0,0.00")}
					</span>
				</div>
				{isSpotPrice && (spotPriceValue || spotPriceValue === 0) ? (
					<div className='ml-auto text-white flex'>
						<SpotPriceValue
							includeParenthesis
							value={Number(spotPriceValue || 0)}
							classNames={`${
								isCreatorHub ? "text-[8px] 3xl:text-[12px]" : "text-[.75rem]"
							} font-montserrat font-normal text-[#ADB5BD]`}
						/>
					</div>
				) : null}
			</div>
		</>
	)
}
