import {
	OpensearchStorefrontAvailableData,
	SupportedTokens,
} from "flowty-common"
import { Log } from "./Log"

export type ParsedFMVDisplayData = {
	href: string
	percent: number
	source: string
}

export function getFMVHrefFromSource(source: string): string {
	switch (source.toUpperCase()) {
		case "OTM":
			return "https://www.otmnft.com/nbatopshot/articles/introducing-otm-true-value-account-valuation"
		default:
			return "404"
	}
}

export function getSourceFromSourceList(sources: string): string {
	const sourceList = sources.split(",")

	if (sourceList.length > 1) return "blended"

	return sourceList[0]
}

export function getFMVDisplayLabelFromSource(source: string): string {
	switch (source.toUpperCase()) {
		case "OTM":
			return "OTM's True Value model"
		default:
			return source
	}
}

export function parseFMVDataFromOrder(
	order: OpensearchStorefrontAvailableData
): ParsedFMVDisplayData {
	const decimal =
		order?.valuationDifference && order?.valuations?.blended.usdValue
			? order.valuationDifference / order.valuations.blended.usdValue
			: 0
	const percent = Math.floor(decimal * 100)
	const source = order?.valuations?.blended?.source || ""
	const href = getFMVHrefFromSource(source)

	return {
		href,
		percent,
		source,
	}
}

export function calcDiscountWithAmountAndValuation({
	salePrice,
	spotPrice,
	token,
	valuation,
}: {
	salePrice: number
	spotPrice: number
	token: SupportedTokens
	valuation: number
}) {
	if (!valuation || !salePrice) return 0

	let percentAsDecimal: number

	if (token === "FLOW") {
		const dollarAmount = salePrice * spotPrice
		percentAsDecimal = 1 - dollarAmount / valuation
	} else {
		percentAsDecimal = 1 - salePrice / valuation
	}

	return Math.floor(percentAsDecimal * 100)
}
