import * as fcl from "@onflow/fcl"
import { FlowtyButton } from "ds-flowty"
import {
	OpensearchFlowNFT,
	OpensearchStorefrontAvailableData,
	Order,
} from "flowty-common"
import { inject, observer } from "mobx-react"
import numeral from "numeral"
import { useCallback, useMemo } from "react"
import { ListingIndicatorPill } from "../../../../../../components/Shared/ListingIndicatorPill/ListingIndicatorPill"
import { AuthStoreProp } from "../../../../../../stores/AuthStore"
import { truncateCurrencyAmount } from "../../../../../../util/truncateValueUtil"
import { AssetOffersTable } from "../OffersTable/AssetOffersTable"
import { NoListingPreview } from "./NoListingsPreview/NoListingPreview"
import { TokenIcon } from "../../../../../../components/Tokens/tokenDisplays"

interface StorefrontPreviewProps extends AuthStoreProp {
	asset: OpensearchFlowNFT
	orders: OpensearchStorefrontAvailableData[]
	setSelectedListing: ({
		listing,
		listingType,
		singleDelistAction,
		singleDelistOrder,
	}: {
		listing?: Order
		listingType?: "loan" | "rent" | "transfer"
		singleDelistOrder?: Order
		singleDelistAction?: "delistLoan" | "delistRental" | "delistSale"
	}) => void
	isAssetOwner: boolean
}

const StorefrontsPreviewComponent: React.FC<StorefrontPreviewProps> = ({
	asset,
	orders,
	authStore,
	setSelectedListing,
	isAssetOwner,
}) => {
	const loggedUser = authStore?.loggedUser

	const userAddresses = authStore?.getAccountSummaryAddresses() ?? []

	const connectWallet = () => {
		fcl.authenticate()
	}

	const AuthService = useMemo(() => {
		return loggedUser?.services[0].uid.toLocaleLowerCase() || ""
	}, [loggedUser])

	const isDapper = AuthService.includes("dapper")

	const returnActionButtonsBasedOnAuth = useCallback(
		(isOwner: boolean, order: OpensearchStorefrontAvailableData) => {
			if (!loggedUser) {
				return (
					<FlowtyButton
						text={
							<b>
								{loggedUser ? (
									<div
										className='animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent rounded-full'
										role='status'
										aria-label='loading'
									>
										<span className='sr-only'>Loading...</span>
									</div>
								) : (
									<span className='text-base font-montserrat'>Sign in</span>
								)}
							</b>
						}
						onClick={connectWallet}
						variant={"secondary"}
						btnSize='small'
					/>
				)
			}
			if (isOwner) {
				return (
					<>
						<FlowtyButton
							text={"Delist"}
							onClick={() =>
								setSelectedListing({
									singleDelistAction: "delistSale",
									singleDelistOrder: order,
								})
							}
							variant={"secondary"}
							btnSize='small'
							bgColor='danger'
						/>
						<FlowtyButton
							text={"Edit listing"}
							onClick={() =>
								setSelectedListing({
									singleDelistAction: undefined,
									singleDelistOrder: undefined,
								})
							}
							variant={"secondary"}
							btnSize='small'
							bgColor='primary'
						/>
					</>
				)
			}
			return (
				<FlowtyButton
					text={"Buy Now"}
					onClick={() => setSelectedListing({ listing: order })}
					variant={"secondary"}
					btnSize='small'
				/>
			)
		},
		[loggedUser]
	)
	return (
		<div className='mt-[1.5rem] xl:min-h-[17.5rem] flex flex-row flex-wrap gap-[1.5rem] items-stretch'>
			{orders?.length > 0 ? (
				orders.map((order, idx) => {
					const isOwner =
						userAddresses.includes(order.flowtyStorefrontAddress) &&
						!!loggedUser?.loggedIn

					return (
						<div
							key={idx}
							className='rounded-[0.5rem] flex flex-col p-[1.5rem] bg-[#606e7d33] w-full lg:w-[25rem] gap-y-[1.5rem] gap-x-[1.5rem] items-start justify-between'
						>
							<div>
								<ListingIndicatorPill symbol='BUY NOW' />
							</div>
							<div className='flex flex-col w-full justify-center items-center'>
								<p className='font-montserrat text-[0.875rem]'>
									Purchase Price
								</p>
								<div className='flex flex-row'>
									<TokenIcon token={order?.paymentTokenName} isLarge />
									<p className='font-bold text-[2rem] font-montserrat ml-1'>
										{numeral(order?.amount?.toFixed(2))?.format("0,0.00")}
									</p>
								</div>
								{order.valuations?.blended?.usdValue &&
								order.valuations?.blended?.usdValue > 0 ? (
									<p className='font-montserrat text-[0.78125rem]'>
										Third Party FMV:{" "}
										{truncateCurrencyAmount(order.valuations?.blended.usdValue)}
									</p>
								) : null}
							</div>
							<div className='w-full flex gap-[0.5rem]'>
								{returnActionButtonsBasedOnAuth(isOwner, order)}
							</div>
						</div>
					)
				})
			) : (
				<div className='min-h-full w-full md:w-auto flex flex-col items-stretch'>
					<NoListingPreview
						type='BUY NOW'
						isOwner={isAssetOwner}
						isDapper={isDapper}
						updateListing={() => setSelectedListing({})}
					/>
				</div>
			)}
			<AssetOffersTable nft={asset} />
		</div>
	)
}

export const StorefrontsPreview = inject("authStore")(
	observer(StorefrontsPreviewComponent)
)
