import { FormikTouched, FormikErrors } from "formik"

export const renderInputError = <T>(
	name: keyof T,
	touched: FormikTouched<T>,
	errors: FormikErrors<T>
): boolean => {
	return touched[name] && !!errors[name]
}

export const renderErrorMessage = <T>(
	name: keyof T,
	touched: FormikTouched<T>,
	errors: FormikErrors<T>
): string | null => {
	if (touched[name]) {
		return errors[name] as string
	}
	return null
}
