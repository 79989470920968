import { IS_FLOW_EMULATOR, IS_MAINNET } from "./settings"
import { SupportedTokens } from "flowty-common"

export const FUNGIBLE_TOKEN_CONTRACT_ADDRESS = IS_MAINNET
	? "0xf233dcee88fe0abe" // mainnet
	: IS_FLOW_EMULATOR
	? "0xee82856bf20e2aa6" // emulator
	: "0x9a0766d93b6608b7" // testnet

export const DUC_IDENTIFIER = IS_MAINNET
	? "A.ead892083b3e2c6c.DapperUtilityCoin.Vault" // mainnet
	: IS_FLOW_EMULATOR
	? "A.f8d6e0586b0a20c7.DapperUtilityCoin.Vault" // emulator
	: "A.82ec283f88a62e65.DapperUtilityCoin.Vault" // testnet

export const FUT_IDENTIFIER = IS_MAINNET
	? "A.ead892083b3e2c6c.FlowUtilityToken.Vault" // mainnet
	: IS_FLOW_EMULATOR
	? "A.f8d6e0586b0a20c7.FlowUtilityToken.Vault" // emulator
	: "A.82ec283f88a62e65.FlowUtilityToken.Vault" // testnet

export const FLOW_IDENTIFIER = IS_MAINNET
	? "A.1654653399040a61.FlowToken.Vault" // mainnet
	: IS_FLOW_EMULATOR
	? "A.0ae53cb6e3f42a79.FlowToken.Vault" // emulator
	: "A.7e60df042a9c0868.FlowToken.Vault" // testnet

export const USDC_IDENTIFIER = IS_MAINNET
	? "A.f1ab99c82dee3526.USDCFlow.Vault" // mainnet
	: IS_FLOW_EMULATOR
	? "A.f8d6e0586b0a20c7.USDCFlow.Vault" // emulator
	: "A.64adf39cbc354fcb.USDCFlow.Vault" // testnet

export const PaymentTokenToIdentifier: Record<SupportedTokens, string> = {
	DUC: DUC_IDENTIFIER,
	FLOW: FLOW_IDENTIFIER,
	FUSD: "", // not supported anymore
	USDC: USDC_IDENTIFIER,
	USDT: "",
}

export const getTokenTypeFromName = (tokenName: string): string => {
	const tokens: Record<string, string> = {
		DUC: DUC_IDENTIFIER,
		FLOW: FLOW_IDENTIFIER,
		USDC: USDC_IDENTIFIER,
	}
	return tokens[tokenName]
}
