import { TwitterShareButton } from "../TwitterShareButton"

function createNewPostMintTwitterHref({
	collectionDisplayName,
	dropPageLink,
}: {
	collectionDisplayName?: string
	dropPageLink?: string
}) {
	return `https://twitter.com/intent/tweet?text=I%20just%20minted%20an%20NFT%20from%20%22${collectionDisplayName}%22%20on%20%40flowty_io%0A%0AMinting%20is%20LIVE%20here%3A&url=${dropPageLink} `
}

type ShareNewPostMintProps = {
	collectionDisplayName?: string
	dropPageLink?: string
}
export const ShareNewPostMint: React.FC<ShareNewPostMintProps> = ({
	collectionDisplayName,
	dropPageLink,
}) => {
	const href = createNewPostMintTwitterHref({
		collectionDisplayName,
		dropPageLink,
	})

	return <TwitterShareButton href={href} />
}
