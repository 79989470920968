import { useState } from "react"
import { FormTabs } from "../types/FlowtyCreatorHubContextTypes"

export const useFormTab = () => {
	const [formTab, setFormTab] = useState<FormTabs>(FormTabs.CreateCollection)

	const changeSelectedFormTab = (tab: FormTabs): void => {
		setFormTab(tab)
	}

	const moveToNextTab = (): void => {
		setFormTab(prev => prev + 1)
	}

	const moveToPreviousTab = (): void => {
		setFormTab(prev => prev - 1)
	}

	return { changeSelectedFormTab, formTab, moveToNextTab, moveToPreviousTab }
}
