import { ListingAvailableBase, NftMetadata } from "./firestore"
import {
	Card,
	FlowNFT,
	FlowNFTContract,
	ImpactedContract,
	NFTLocationData,
} from "./flow"
import { NFTMedia, Trait } from "./metadataStandards"
import { flowSeriesToTopShotSeries } from "./topshot"
import { NFT, NFTMetadata } from "./alchemy"
import { formatAddress, truncateString } from "./string"
import {
	AllDayBanner,
	AllDayThumbnail,
	DefaultBanner,
	DefaultThumbnail,
	LaLigaBanner,
	LaLigaThumbnail,
	StrikeBanner,
	StrikeThumbnail,
	TopShotBanner,
	TopShotSquare,
	TopShotThumbnail,
} from "./images"
import { OpensearchFlowNFT } from "./search"
import { tryHandleDataUrl } from "./urlData"

const pinataIpfsPrefix = "https://flowty-public.mypinata.cloud/ipfs/"
const pinataGatewayKey =
	"xFpPpu5fb5PZMLV6RF8ueOm08-BkzG5BLYJf1jVw1NL8RUORAi5yj3BwsexCJWIz"

const quicknodeIpfsGateway = "https://flowty.quicknode-ipfs.com/ipfs/"
const ipfsioPrefix = "https://ipfs.io/ipfs/"
const flowverseFilebasePrefix = "https://flowverse.myfilebase.com/ipfs/"

const overrideTS = false
const overrideAD = false
const overrideStrike = false
const overrideLL = false

export const flattenFlowNFTTraits = (nft: FlowNFT): NFTMetadata => {
	if (!nft?.nftView?.traits?.traits) {
		return {
			metadata: {},
		}
	}
	const metadata: { [key: string]: any } = {}
	nft.nftView.traits.traits.forEach(val => {
		metadata[val.name] = val.value
	})

	if (nft.nftView?.editions && nft.nftView.editions.infoList.length > 0) {
		const editionInfo = nft.nftView.editions.infoList[0]
		if (editionInfo.max) {
			metadata.editionMax = editionInfo.max
		}

		if (editionInfo.name) {
			metadata.editionName = editionInfo.name
		}
		metadata.editionNumber = editionInfo.number
	}

	return {
		metadata,
	}
}

export const nftTypeToContract = (nftType: string): ImpactedContract => {
	const segments = nftType.split(".")
	return {
		address: formatAddress(segments[1]),
		name: segments[2],
	}
}

export const nftTypeAndIdToLocationData = (
	nftType: string,
	nftID: string
): NFTLocationData => {
	const segments = nftType?.split(".")
	if (!segments || segments.length < 4) {
		// This is a default NFTLocationData object
		return {
			contract: {
				address: "",
				name: "",
			},
			nftID,
			resourceName: "",
		}
	}

	return {
		contract: {
			address: formatAddress(segments[1]),
			name: segments[2],
		},
		nftID,
		resourceName: segments[3],
	}
}

export const nftTypeToContractID = (nftType: string): string => {
	const segments = nftType.split(".")
	return `${formatAddress(segments[1])}.${segments[2]}`
}

export const idToNFTLocationData = (id: string): NFTLocationData => {
	const segments = id.split(".")
	if (segments.length === 4) {
		return {
			contract: {
				address: segments[0],
				name: segments[1],
			},
			nftID: segments[3],
			resourceName: segments[2],
		}
	}

	return {
		contract: {
			address: segments[0],
			name: segments[1],
		},
		nftID: segments[segments.length - 1],
		resourceName: "NFT",
	}
}

export const nftLocationDataFromListing = (
	listing: ListingAvailableBase
): NFTLocationData => {
	const typeSegments = listing.nftType.split(".")
	const resourceName = typeSegments.length === 4 ? typeSegments[3] : "NFT"

	return {
		contract: {
			address: formatAddress(typeSegments[1]),
			name: typeSegments[2],
		},
		nftID: listing.nftID,
		resourceName,
	}
}

export const nftLocationDataToType = (
	nftLocationData: NFTLocationData
): string => {
	const { address, name } = nftLocationData.contract
	if (nftLocationData.resourceName) {
		return `A.${address.substring(2)}.${name}.${nftLocationData.resourceName}`
	}

	return `A.${address.substring(2)}.${name}.NFT`
}

export const getNFTIdentifier = (nft: NFTLocationData): string => {
	if (nft.resourceName !== "NFT") {
		return `${nft.contract.address}.${nft.contract.name}.${nft.resourceName}.${nft.nftID}`
	}

	return `${nft.contract.address}.${nft.contract.name}.${nft.nftID}`
}

export const getTitleFromFlowNFT = (id: string, flowNFT: FlowNFT): string => {
	// handle some special cases
	if (flowNFT.type && flowNFT.type.includes("TopShot")) {
		// check if the Dynamic Duos trait is present
		if (flowNFT.nftView?.traits?.traits) {
			let teamName = ""
			let fullName = ""
			let playCategory = ""

			flowNFT.nftView.traits.traits.forEach(t => {
				if (t.name === "TeamAtMoment") {
					teamName = t.value.toString()
				}

				if (t.name === "FullName") {
					fullName = t.value.toString()
				}

				if (t.name === "PlayCategory") {
					playCategory = t.value.toString()
				}
			})

			if (fullName !== "") {
				return fullName
			}

			if (teamName) {
				if (playCategory === "Redemption") {
					return `${teamName} - Redemption`
				}

				return teamName
			}
		}
	}

	if (flowNFT.type && flowNFT.type.includes("AllDay")) {
		const flattened = flattenFlowNFTTraits(flowNFT)
		if (
			flattened.metadata.playerFirstName &&
			flattened.metadata.playerLastName
		) {
			const { playerFirstName, playerLastName } = flattened.metadata
			return `${playerFirstName} ${playerLastName}`
		}

		if (flattened.metadata.teamName) {
			return `${flattened.metadata.teamName} ${
				flattened.metadata.playType ?? ""
			}`
		}
	}

	if (flowNFT.type && flowNFT.type.includes("Golazos")) {
		const flattened = flattenFlowNFTTraits(flowNFT)
		const { metadata } = flattened

		const firstAndLast =
			`${metadata.PlayerFirstName} ${metadata.PlayerLastName}`.trim()

		const name = metadata.PlayerKnownName || firstAndLast
		if (name) {
			return name
		}
	}

	if (flowNFT.nftView?.display?.name) {
		return flowNFT.nftView.display.name
	}

	if (flowNFT.nft?.title) {
		return flowNFT.nft.title
	}

	const locationData = idToNFTLocationData(id)
	return `${locationData.contract.name} #${locationData.nftID}`
}

function isAbsolute(url: string) {
	return /^(?:[a-z]+:)?\/\//i.test(url)
}

function removeLeadingSlash(url: string) {
	return url.replace(/^\/+/, "")
}

const ensureIpfsGatewayKey = (url: string): string => {
	const urlObject = new URL(url)
	if (!urlObject.searchParams.has("pinataGatewayToken")) {
		urlObject.searchParams.set("pinataGatewayToken", pinataGatewayKey)
	}

	return urlObject.toString()
}

export const getImagesFromFlowNFT = (flowNFT: FlowNFT): NFTMedia[] | null => {
	if (flowNFT.nftView && flowNFT.nftView.display) {
		let url = flowNFT.nftView.display.thumbnail
		if (url.startsWith("ipfs://")) {
			url = ensureIpfsGatewayKey(`${pinataIpfsPrefix}${url.substring(7)}`)
		}
		const media: NFTMedia = {
			mediaType: "image",
			url,
		}
		return [media]
	}

	if (flowNFT.nft && flowNFT.nft.media) {
		return flowNFT.nft.media.map((val): NFTMedia => {
			return {
				mediaType: val.mimeType || "",
				url: val.uri,
			}
		})
	}

	return null
}

export const hasTopShotDetailFields = (flowNFT: FlowNFT): boolean => {
	if (!flowNFT?.nft?.metadata?.metadata) {
		return false
	}

	const { Set, Series, Serial, Tier } = flowNFT.nft.metadata.metadata
	return Set && Series && Serial && Tier
}

const getEditionNum = (nft: FlowNFT): string => {
	if (
		!nft.nftView?.editions?.infoList ||
		nft.nftView.editions.infoList.length === 0
	) {
		return nft?.id?.toString() || ""
	}

	return (
		nft.nftView.editions.infoList[0].number?.toString() ||
		nft?.id?.toString() ||
		""
	)
}

const getSerialNum = (nft: FlowNFT): string => {
	if (nft?.nftView?.serial) {
		return nft.nftView.serial
	}

	return getEditionNum(nft)
}

const getEditionSize = (nft: FlowNFT): string | null => {
	if (
		!nft.nftView?.editions?.infoList ||
		nft.nftView.editions.infoList.length === 0
	) {
		return null
	}

	return nft.nftView.editions.infoList[0].max?.toString() ?? null
}

export const getAdditionalDetailsFromFlowNFT = (
	flowNFT: FlowNFT
): string[] | null => {
	if (flowNFT.contractName === "TopShot" && flowNFT?.nftView?.traits) {
		const dict: { [key: string]: any } = {}
		flowNFT.nftView.traits.traits.forEach(t => {
			dict[t.name] = t.value
		})

		let supply = "N/A"
		if (
			flowNFT.nftView?.editions &&
			flowNFT.nftView.editions.infoList.length > 0 &&
			flowNFT.nftView.editions?.infoList[0]?.max
		) {
			supply = flowNFT.nftView.editions.infoList[0].max.toString()
		}

		const serial = `#${dict.SerialNumber} / ${supply}`
		const tsSeries = flowSeriesToTopShotSeries(dict.SeriesNumber)
		const series = `${dict.SetName} (${
			Number.isNaN(+tsSeries) ? tsSeries : `Series ${tsSeries}`
		})`

		return [series, `${dict.Tier} ${serial}`]
	}

	if (
		flowNFT.contractName === "TopShot" &&
		flowNFT?.nft?.metadata.metadata &&
		flowNFT?.nft?.metadata.metadata.Set && // if Set is present, the others should be as well
		hasTopShotDetailFields(flowNFT)
	) {
		const { Set, Series, Serial, Supply, Tier } = flowNFT.nft.metadata.metadata
		const serial = `#${Serial} / ${Supply}`
		const tsSeries = flowSeriesToTopShotSeries(Series)
		const series = `${Set} (${
			Number.isNaN(+tsSeries) ? tsSeries : `Series ${tsSeries}`
		})`

		return [series, `${Tier} ${serial}`]
	}

	if (flowNFT.contractName === "UFC_NFT" && flowNFT?.nftView?.traits?.traits) {
		const traitsDict: { [key: string]: any } = {}
		flowNFT.nftView.traits.traits.forEach(value => {
			traitsDict[value.name] = value.value
		})

		const editionNum =
			traitsDict.EditionNum ||
			traitsDict.editionNum ||
			getEditionNum(flowNFT) ||
			getSerialNum(flowNFT)
		const editionSize = traitsDict["EDITION SIZE"] || getEditionSize(flowNFT)

		const serial = `#${editionNum} / ${editionSize}`
		const series =
			traitsDict.SET && traitsDict.SERIES
				? `${traitsDict.SET} (Series ${traitsDict.SERIES})`
				: ""
		const tierSummary = `${traitsDict.TIER ?? ""} ${serial}`.trim()
		return [series, tierSummary]
	}

	if (flowNFT.contractName === "AllDay" && flowNFT?.nftView?.traits?.traits) {
		const traitsDict: { [key: string]: any } = {}
		flowNFT.nftView.traits.traits.forEach(value => {
			traitsDict[value.name] = value.value
		})

		const sn = traitsDict.serialNumber || traitsDict["Serial Number"]
		let nm = 0
		if (
			flowNFT?.nftView?.editions &&
			flowNFT.nftView.editions.infoList.length > 0 &&
			flowNFT.nftView.editions.infoList[0].max
		) {
			nm = flowNFT.nftView.editions.infoList[0].max
		} else {
			nm = Number(traitsDict["Num Minted"])
		}

		const setName = traitsDict.setName || traitsDict["Set Name"]
		const seriesName =
			traitsDict.seriesName || `Series ${traitsDict["Series Number"]}`

		const serial = `#${sn} / ${nm}`
		const series = `${setName} (${seriesName})`
		const tier = traitsDict.editionTier || traitsDict.Tier
		return [series, `${tier} ${serial}`]
	}

	if (flowNFT.contractName === "Golazos" && flowNFT?.nftView?.traits?.traits) {
		const traitsDict: { [key: string]: any } = {}
		flowNFT.nftView.traits.traits.forEach(value => {
			traitsDict[value.name] = value.value
		})

		const editionSize = flowNFT.nftView.editions?.infoList[0]?.max || "N/A"

		const { setName, seriesName, serialNumber, editionTier } = traitsDict
		return [
			`${setName} (${seriesName})`,
			`${editionTier} #${serialNumber} / ${editionSize}`,
		]
	}

	return null
}

export const getTraitsByName = (
	names: Set<string>,
	traits: Trait[]
): Trait[] => {
	const foundTraits: Record<string, Trait> = {}
	traits.forEach(t => {
		if (names.has(t.name)) {
			foundTraits[t.name] = t
		}
	})

	const res: Trait[] = []
	Array.from(names.keys()).forEach(n => {
		if (foundTraits[n]) {
			res.push(foundTraits[n])
		}
	})
	return res
}

export const getHeaderTraits = (flowNFT: FlowNFT): Trait[] => {
	if (!flowNFT.nftView?.traits) {
		return []
	}

	let s: Set<string> = new Set()
	switch (flowNFT.contractName) {
		case "TopShot":
			s = new Set<string>(["Tier", "SetName", "SeriesName"])
			break
		case "AllDay":
			s = new Set<string>(["editionTier", "setName", "seriesName"])
			break
		case "UFC_NFT":
			s = new Set<string>(["TIER", "SET", "SERIES"])
			break
		default:
			break
	}

	return getTraitsByName(s, flowNFT.nftView.traits.traits)
}

export const flowNFTToCard = (id: string, flowNFT: FlowNFT): Card | null => {
	const locationData = idToNFTLocationData(id)

	return {
		additionalDetails: getAdditionalDetailsFromFlowNFT(flowNFT),
		collectionAddress: flowNFT.contractAddress || locationData.contract.address,
		collectionName: flowNFT.contractName || locationData.contract.name,
		headerTraits: getHeaderTraits(flowNFT),
		images: getImagesFromFlowNFT(flowNFT),
		max: getEditionSize(flowNFT),
		num: getSerialNum(flowNFT),
		title: getTitleFromFlowNFT(id, flowNFT),
		video: null,
	}
}

interface Images {
	[key: string]: {
		banner: string
		square: string
	}
}

export const specialCaseImages: Images = {
	AllDay: {
		banner: AllDayBanner,
		square: AllDayThumbnail,
	},
	LaLiga: {
		banner: LaLigaBanner,
		square: LaLigaThumbnail,
	},
	TopShot: {
		banner: TopShotBanner,
		square: TopShotSquare,
	},
	UFC: {
		banner: StrikeBanner,
		square: StrikeThumbnail,
	},
	// TODO add Flowty default banner
	default: {
		banner: DefaultBanner,
		square: DefaultThumbnail,
	},
}

export const getImageURL = (url: string, allowedImageUrl?: boolean): string => {
	if (allowedImageUrl) {
		return url
	}

	if (!url) {
		return "https://storage.googleapis.com/flowty-images/fallback_image.svg"
	}

	if (url.startsWith("ipfs://")) {
		return ensureIpfsGatewayKey(`${pinataIpfsPrefix}${url.substring(7)}`)
	}

	if (url.startsWith(quicknodeIpfsGateway)) {
		return ensureIpfsGatewayKey(
			url.replace(quicknodeIpfsGateway, pinataIpfsPrefix)
		)
	}

	const lower = url.toLowerCase()

	// if this isn't a URL, then we
	// should treat it like a relative URL off
	// the ifps endpoint
	// that means doesn't include the //
	if (!isAbsolute(url)) {
		if (url.startsWith("data:")) {
			const data = tryHandleDataUrl(url)
			return data
		}

		return ensureIpfsGatewayKey(`${pinataIpfsPrefix}${removeLeadingSlash(url)}`)
	}

	if (url.startsWith(ipfsioPrefix)) {
		return ensureIpfsGatewayKey(url.replace(ipfsioPrefix, pinataIpfsPrefix))
	}

	if (url.startsWith(flowverseFilebasePrefix)) {
		return ensureIpfsGatewayKey(
			url.replace(flowverseFilebasePrefix, pinataIpfsPrefix)
		)
	}

	if (
		overrideAD &&
		(lower.includes("allday") || lower.includes("flowty-images/football.jpeg"))
	) {
		return AllDayThumbnail
	}

	if (overrideStrike && lower.includes("ufc_nft")) {
		return StrikeThumbnail
	}

	if (
		lower === "https://nbatopshot.com/static/img/og/og.png" || // this image is returning a 404 right now
		lower.includes("/flowty-images/basketball.jpg") ||
		lower.includes("flowty-images/topshot/350x350.png") ||
		(overrideTS && lower.includes("topshot"))
	) {
		return TopShotThumbnail
	}

	if (overrideLL && (lower.includes("laliga") || lower.includes("golazos"))) {
		return specialCaseImages.LaLiga.square
	}

	if (
		(lower.includes("nbatopshot") && !overrideTS) ||
		(lower.includes("allday") && !overrideAD)
	) {
		const urlObj = new URL(url)

		urlObj.searchParams.set("width", "1451")
		urlObj.searchParams.set("format", "webp")
		urlObj.searchParams.set("quality", "80")
		urlObj.searchParams.set("cv", "1")

		return urlObj.href
	}

	return url
}

export const getBannerImage = (
	collectionName: string,
	collection: FlowNFTContract | null
): string => {
	const bannerFile = collection?.collectionDisplay?.bannerImage?.file
	if (!bannerFile) {
		return specialCaseImages.default.banner
	}

	const { url, cid, path } = bannerFile
	let bannerImage = url || cid
	if (!bannerImage) {
		return specialCaseImages.default.banner
	}

	if (path) {
		bannerImage = `${bannerImage}/${path}`
	}

	if (!bannerImage.startsWith("http") && !bannerImage.startsWith("ipfs://")) {
		bannerImage = `${pinataIpfsPrefix}${bannerImage}`
	}

	if (bannerImage.startsWith(ipfsioPrefix)) {
		return ensureIpfsGatewayKey(
			bannerImage.replace(ipfsioPrefix, pinataIpfsPrefix)
		)
	}

	if (bannerImage.startsWith(flowverseFilebasePrefix)) {
		return ensureIpfsGatewayKey(
			bannerImage.replace(flowverseFilebasePrefix, pinataIpfsPrefix)
		)
	}

	const lower = collectionName.toLowerCase()
	if (lower.includes("topshot") && overrideTS) {
		return specialCaseImages.TopShot.banner
	}
	if (lower.includes("allday") && overrideAD) {
		return specialCaseImages.AllDay.banner
	}
	// name for UFC??
	if (lower.includes("ufc" || "strike") && overrideStrike) {
		return specialCaseImages.UFC.banner
	}
	if (lower.includes("laliga") && overrideLL) {
		return specialCaseImages.LaLiga.banner
	}
	if (bannerImage) {
		return bannerImage
	}
	return specialCaseImages.default.banner
}

export const getSquareImage = (
	collectionName: string,
	collection: FlowNFTContract | null
): string => {
	const squareFile = collection?.collectionDisplay?.squareImage.file
	if (!squareFile) {
		return specialCaseImages.default.square
	}

	const { cid, url, path } = squareFile

	let squareImage = url || cid
	if (!squareImage) {
		return specialCaseImages.default.square
	}

	if (path) {
		squareImage = `${squareImage}/${path}`
	}

	if (!squareImage.startsWith("http") && !squareImage.startsWith("ipfs://")) {
		squareImage = `${pinataIpfsPrefix}${squareImage}`
	}

	if (squareImage.startsWith(ipfsioPrefix)) {
		return ensureIpfsGatewayKey(
			squareImage.replace(ipfsioPrefix, pinataIpfsPrefix)
		)
	}

	if (squareImage.startsWith(pinataIpfsPrefix)) {
		return ensureIpfsGatewayKey(
			squareImage.replace(ipfsioPrefix, pinataIpfsPrefix)
		)
	}

	if (squareImage.startsWith(flowverseFilebasePrefix)) {
		return ensureIpfsGatewayKey(
			squareImage.replace(flowverseFilebasePrefix, pinataIpfsPrefix)
		)
	}

	if (squareImage) {
		return getImageURL(squareImage)
	}

	return specialCaseImages.default.square
}

export const emptyFlowNFTToNFT = (flowNFT: FlowNFT): NFT => {
	const metadata: { [key: string]: any } = {}
	if (flowNFT?.nftView?.traits) {
		flowNFT.nftView.traits.traits.forEach(val => {
			metadata[val.name] = val.value
		})
	}

	if (
		flowNFT.nftView?.editions &&
		flowNFT.nftView.editions.infoList.length > 0
	) {
		const editionInfo = flowNFT.nftView.editions.infoList[0]
		if (editionInfo.max) {
			metadata.editionMax = editionInfo.max
		}

		if (editionInfo.name) {
			metadata.editionName = editionInfo.name
		}
		metadata.editionNumber = editionInfo.number
	}

	const imageURL =
		flowNFT.card?.images?.length && flowNFT.card.images.length > 0
			? flowNFT.card.images[0].url
			: flowNFT.nftView?.display?.thumbnail || ""
	const processedURL = getImageURL(imageURL)

	const title = getTitleFromFlowNFT(`0x${flowNFT.type}.${flowNFT.id}`, flowNFT)

	let publicPath: string
	let storagePath: string

	if (flowNFT.nftView?.collectionData) {
		publicPath = `/public/${flowNFT.nftView.collectionData.publicPath.identifier}`
		storagePath = `/storage/${flowNFT.nftView.collectionData.storagePath.identifier}`
	} else {
		publicPath = `${flowNFT.contractName}.CollectionPublicPath`
		storagePath = `${flowNFT.contractName}.CollectionStoragePath`
	}

	return {
		contract: {
			address: flowNFT.contractAddress,
			contractMetadata: {
				publicCollectionName: "",
				publicPath,
				storagePath,
			},
			externalDomain: "",
			name: flowNFT.contractName,
		},
		description: "",
		externalDomainViewUrl: "",
		id: {
			tokenId: flowNFT.id?.toString() || "1",
			tokenMetadata: {
				uuid: "",
			},
		},
		media: [
			{
				mimeType: "image",
				uri: processedURL,
			},
		],
		metadata: {
			metadata,
		},
		title,
		tokenUri: {
			raw: "",
		},
	}
}

export const getMetadataFields = (
	metadata: NftMetadata | NFTMetadata,
	type: string
): Record<string, any> => {
	const initialFields = metadata?.metadata ?? {}
	let data: Record<string, any> = {}

	if (type.includes("TopShot")) {
		const tsSeries = flowSeriesToTopShotSeries(
			initialFields.TopShotSeries ?? initialFields.SeriesNumber
		)
		// "editionNumber" gets you the same value, but as a number instead of string
		const tsSerial =
			initialFields.Serial ??
			initialFields.SerialNumber ??
			initialFields.editionNumber
		const tsSupply = initialFields.Supply ?? initialFields.editionMax

		data.Name = initialFields.FullName
		data.Set = initialFields.Set ?? initialFields.SetName
		data.Tier = initialFields?.Tier || "Unknown"
		data.Series = Number.isNaN(+tsSeries) ? tsSeries : `Series ${tsSeries}`
		data.Serial = `${tsSerial} / ${tsSupply}`
		data.Team = initialFields.TeamAtMoment
		data["Play Type"] = initialFields.PlayType
	} else {
		data = { ...initialFields }
	}

	return data
}

export const getCardTerms = (
	metadata: NftMetadata | NFTMetadata,
	type: string
): string[] | null[] => {
	const fields = getMetadataFields(metadata, type)
	if (type.includes("TopShot")) {
		return [
			`${truncateString(fields.Set, 15)} (${fields.Series})`,
			`${fields.Tier} ${fields.Serial}`,
		]
	}

	if (type.includes("AllDay")) {
		const setName = fields["Set Name"] || fields.setName
		const series = fields["Series Number"]
			? `Series ${fields["Series Number"]}`
			: fields.seriesName
		const serialNumber = fields["Serial Number"] || fields.serialNumber
		const tier = fields.Tier || fields.editionTier
		const numMinted = fields["Num Minted"] || fields.editionMax

		return [
			`${truncateString(setName, 15)} (${series})`,
			`${tier} #${serialNumber} / ${numMinted}`,
		]
	}

	if (type.includes("UFC_NFT")) {
		// TODO: need to handle this for strike as well!
		return [null, null]
	}

	return [null, null]
}

export const getNFTDetail = (
	nft: NFT | null,
	card: Card | null = null
): string => {
	let details = ""
	if (card) {
		details = `${card.title || ""}\n ${
			(card.additionalDetails && card.additionalDetails.join("\n")) || ""
		}`
	}

	// TODO: there are some fields that can come back to us as undefined when showing
	// activity from cards. Until that is patched, we need to fallback to the old way of
	// pulling this data
	if (details && !details.includes("undefined") && !details.includes("N/A")) {
		return details
	}

	if (!nft?.metadata?.metadata) {
		return ""
	}

	const { name, address } = nft.contract
	const contractType = `${address}.${name}`

	if (nft.contract.name === "TopShot") {
		const terms = getCardTerms(nft.metadata, contractType)

		return `${nft.title || ""} \n ${terms.join(" ")}`
	}

	return nft.title
}

export const getNftId = (nft: FlowNFT | OpensearchFlowNFT): string => {
	const locationData = nftTypeAndIdToLocationData(nft.type, nft.id)
	return getNFTIdentifier(locationData)
}
