import { Config } from "../types"

export const getAccountCollections = (
	config: Config
): string => `import ContractManager from ${config.contractAddresses.ContractManager}
import BaseCollection from ${config.contractAddresses.BaseCollection}
import NFTMetadata from ${config.contractAddresses.NFTMetadata}

access(all) struct AccountContract {
    access(all) let address: Address
    access(all) let contracts: AnyStruct

    init(address: Address, contracts: AnyStruct) {
        self.address = address
        self.contracts = contracts
    }
}

access(all) fun main(userAddress: Address): AccountContract {
    let contractsData: [AnyStruct] = []

    let userAccount = getAccount(userAddress)
    let contractManagerCap = userAccount.capabilities.borrow<&ContractManager.Manager>(ContractManager.PublicPath)

    if (contractManagerCap == nil) {
        return AccountContract(address: userAddress, contracts: contractsData)
    }

    let managerAccount: &Account = contractManagerCap!.getAccount()
    let managerAddress = contractManagerCap!.getAccount().address
    let contracts: &[String] = managerAccount.contracts.names


    if contracts.length == 0 {
        return AccountContract(address: userAddress, contracts: contractsData)
    }

    for c in contracts {
        let uniqueContract = managerAccount.contracts.borrow<&{BaseCollection}>(name: c) ?? panic("collection not found")
        let metadataCap = uniqueContract.MetadataCap.borrow()!
        contractsData.append(metadataCap.collectionInfo)
    }
    return AccountContract(address: managerAddress, contracts: contractsData)
}`
