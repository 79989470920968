import React from "react"
import { cn } from "../../../../utils/cn"
import { DROPSTATUS } from "../../../types/DropType"

interface DropStatusPillProps {
	dropStatus: DROPSTATUS
	smallChip?: boolean
	card?: boolean
}

export const DropStatusPill: React.FunctionComponent<DropStatusPillProps> = ({
	dropStatus,
	smallChip,
	card,
}) => {
	return (
		<div
			className={`bg-dropStatusIndicatorBackground w-fit h-fit items-center flex ${
				card
					? "gap-[4px] py-[3px] px-[7px] "
					: "gap-[12px] px-[12px] py-[5px] 2xl:py-[6px]"
			} border-[1px] rounded-[4px] border-dropStatusIndicatorBorder`}
		>
			<div
				className={cn(
					`${card ? "w-[8px] h-[8px]" : "w-[10px] h-[10px]"} rounded-full`,
					{
						"bg-[#00FFB4] shadow-pendingActiveDrop":
							dropStatus === "ACTIVE" || dropStatus === "NEVER_ENDING",
						"bg-[#ADB5BD] shadow-endedDrop": dropStatus === "ENDED",
						"bg-[#FAFF06] shadow-pendingActiveDrop": dropStatus === "PENDING",
					}
				)}
			/>
			<span
				className={`uppercase text-neutral-500 font-montserrat ${
					smallChip
						? "text-[8px]"
						: card
						? "text-[10px]"
						: "text-[10px] 2xl:text-[12px]"
				} font-[600] leading-[16px]`}
			>
				{dropStatus === "PENDING"
					? card
						? "SOON"
						: "COMING SOON"
					: dropStatus === "ACTIVE" || dropStatus === "NEVER_ENDING"
					? "LIVE"
					: card
					? "END"
					: "DROP ENDED"}
			</span>
		</div>
	)
}
