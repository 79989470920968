import {
	OfferCreated,
	OpensearchStorefrontAvailableData,
	SpotPrice,
	SupportedTokens,
} from "flowty-common"
import { Flowty } from "flowty-sdk"
import { useEffect, useMemo, useRef } from "react"
import { FlowtyDiscountBanner } from "../../../../FlowtyDiscountBanner"
import { FlowtyListBoxTokenSelector } from "../../../../FlowtyListboxTokenSelector/FlowtyListboxTokenSelection"
import {
	FlowtyWalletSelector,
	walletDisabledMessage,
} from "../../../../FlowtyWalletSelector/FlowtyWalletSelector"
import { AccountSummaries, AccountSummary } from "../../../../Types/GlobalTypes"
import { useFlowtyPurchaseModalContext } from "../../../contexts/FlowtyPurchaseModalContext/FlowtyPurchaseModalContext"
import { ModalWarning } from "../../common/ModalWarning/ModalWarning"
import { BestOfferDisplay } from "../../common/NftCardOrderDisplay/BestOfferDisplay/BestOfferDisplay"
import { NftPrice } from "../../common/NftPrice"
import OfferNotes from "./OfferNotes"
import { OfferWarning } from "./OfferWarning/OfferWarning"
import {
	allowDecimalInDuration,
	getMinListingDuration,
	MAX_LISTING_DURATION,
} from "../../../utils/constants"
import { FlowtyNumericInput } from "../../../../FlowtyNumericInput"
import RoyaltyWarning from "../../common/RoyaltyWarning"

interface OfferMakeSectionProps {
	isDapper: boolean
	payWithSelectedAccount?: AccountSummary
	setPayWithSelectedAccount?: (account: AccountSummary) => void
	sendToSelectedAccount?: AccountSummary
	setSendToSelectedAccount?: (account: AccountSummary) => void
	selectedAccount?: AccountSummary
	offerDuration: number | undefined
	offerValue: number
	offerToken: SupportedTokens
	accountSummaries: AccountSummaries
	hasChildAccounts: boolean
	isMakeOfferFormError: boolean
	spotPrice?: SpotPrice
	dapperOffer?: OfferCreated | null
	nonCustodialOffer?: OfferCreated | null
	storefrontOrders?: OpensearchStorefrontAvailableData[]
	flowty: Flowty
}

export const OfferMakeSection: React.FunctionComponent<
	OfferMakeSectionProps
> = ({
	isDapper,
	payWithSelectedAccount,
	setPayWithSelectedAccount,
	sendToSelectedAccount,
	setSendToSelectedAccount,
	selectedAccount,
	offerDuration,
	offerValue,
	offerToken,
	accountSummaries,
	hasChildAccounts,
	isMakeOfferFormError,
	spotPrice,
	nonCustodialOffer,
	dapperOffer,
	storefrontOrders,
	flowty,
}) => {
	const {
		hasBalance,
		tokenProviderFilter,
		hasNftReceiver,
		orderData,
		valuationData,
		isMainnet,
		royaltyRate,
	} = useFlowtyPurchaseModalContext()

	const MIN_LISTING_DURATION = getMinListingDuration(isMainnet)

	const inputErrorRef = useRef<HTMLDivElement>(null)

	const isInvalidListingDuration =
		Number(offerDuration) < MIN_LISTING_DURATION ||
		offerDuration === undefined ||
		Number(offerDuration) > MAX_LISTING_DURATION

	useEffect(() => {
		if (isMakeOfferFormError && (offerValue <= 0 || offerValue > 10000000)) {
			if (inputErrorRef?.current?.scrollIntoView) {
				inputErrorRef?.current?.scrollIntoView({ behavior: "smooth" })
				return
			}
		}
	}, [isMakeOfferFormError])

	const showLowerOfferWarning = useMemo(() => {
		if (!isDapper) {
			const nonCustodialOfferAmount =
				nonCustodialOffer?.paymentTokenName === "FLOW"
					? Number(nonCustodialOffer?.amount) * Number(spotPrice?.value)
					: Number(nonCustodialOffer?.amount)

			const currentOfferAmount =
				offerToken === "FLOW"
					? offerValue * Number(spotPrice?.value)
					: offerValue

			return nonCustodialOfferAmount > currentOfferAmount
		} else {
			return Number(dapperOffer?.amount) > offerValue
		}
	}, [orderData, dapperOffer, nonCustodialOffer, spotPrice])

	const showOfferAboveListingWarning = (): boolean => {
		if (!hasBalance) return false
		if (storefrontOrders) {
			const firstOrder = storefrontOrders[0]
			if (offerToken === firstOrder?.paymentTokenName) {
				return firstOrder?.amount < offerValue
			} else {
				const firstOrderUsdValue =
					firstOrder?.paymentTokenName === "FLOW"
						? Number(firstOrder?.amount) * Number(spotPrice?.value)
						: Number(firstOrder?.amount)

				const currentOfferAmount =
					offerToken === "FLOW"
						? offerValue * Number(spotPrice?.value)
						: offerValue

				return currentOfferAmount > firstOrderUsdValue
			}
		}
		return false
	}

	return (
		<div
			className='w-full h-full flex flex-col justify-between gap-5'
			ref={inputErrorRef}
		>
			{hasChildAccounts && (
				<div className='flex flex-col gap-3'>
					<FlowtyWalletSelector
						selected={payWithSelectedAccount}
						setSelected={setPayWithSelectedAccount}
						label='Pay With'
						summaries={accountSummaries}
						listingType={"storefront"}
						selectorFilter={tokenProviderFilter}
						disabledMessage={walletDisabledMessage.payWith}
					/>
					<div className='flex flex-col gap-1'>
						<FlowtyWalletSelector
							selected={sendToSelectedAccount}
							setSelected={setSendToSelectedAccount}
							label='Send To'
							summaries={accountSummaries}
							listingType={"storefront"}
							selectorFilter={hasNftReceiver}
							disabledMessage={walletDisabledMessage.sendTo}
						/>
					</div>
				</div>
			)}
			<>
				<span className='font-montserrat text-base font-[600] text-white'>
					Your Offer
				</span>
				<div className='flex flex-col gap-1'>
					<div className='flex gap-3'>
						<FlowtyListBoxTokenSelector
							name='offerTokenType'
							orderType={"offer"}
							isDapper={isDapper}
							selectedAccount={selectedAccount as AccountSummary}
							flowty={flowty}
						/>
						<FlowtyNumericInput
							name={`offerAmount`}
							valueError={
								(isMakeOfferFormError &&
									(offerValue <= 0 || offerValue > 10000000)) ||
								!hasBalance
							}
							inputError={
								(isMakeOfferFormError &&
									(offerValue <= 0 || offerValue > 10000000)) ||
								!hasBalance
							}
							errorMessage={
								!hasBalance
									? "Insufficient funds to place offer"
									: isMakeOfferFormError &&
									  (offerValue <= 0 || offerValue > 10000000)
									? "Between 0.01 - 10000000"
									: null
							}
						/>
					</div>
					{showOfferAboveListingWarning() && <OfferWarning />}
				</div>
				<div className='flex gap-3 flex-row items-center'>
					<div className='w-full'>
						<p className='text-base font-semibold text-white whitespace-nowrap'>
							Offer Duration
						</p>
					</div>
					<div className='w-full flex gap-3 items-center'>
						<FlowtyNumericInput
							name={`offerDuration`}
							valueError={isMakeOfferFormError && isInvalidListingDuration}
							inputError={isMakeOfferFormError && isInvalidListingDuration}
							decimalsAllowed={allowDecimalInDuration(isMainnet)}
							errorMessage={
								isMakeOfferFormError && isInvalidListingDuration
									? `Between ${MIN_LISTING_DURATION} and ${MAX_LISTING_DURATION} days`
									: null
							}
						/>
						<p className='text-base font-semibold text-white whitespace-nowrap'>
							Days
						</p>
					</div>
				</div>
				<div className='flex justify-between'>
					<span className='text-white text-sm font-normal font-montserrat'>
						Your Offer
					</span>
					<NftPrice
						price={offerValue}
						token={offerToken}
						spotPriceValue={Number(spotPrice?.value) * offerValue}
					/>
				</div>
				<div className='flex flex-col gap-5 mt-4'>
					<BestOfferDisplay
						dapperOffer={dapperOffer}
						nonCustodialOffer={nonCustodialOffer}
					/>
					{showLowerOfferWarning && offerValue > 0 && (
						<div className='flex justify-center w-full'>
							<ModalWarning text='Your offer is lower than the current best offer.' />
						</div>
					)}
					{valuationData?.percentage ? (
						<FlowtyDiscountBanner
							source={valuationData.source}
							percent={valuationData?.percentage || 0}
						/>
					) : null}
				</div>
				<div className='flex flex-col gap-3'>
					{Boolean(royaltyRate) && Number(royaltyRate) > 0 ? (
						<RoyaltyWarning royalty={Number(royaltyRate)} />
					) : (
						<></>
					)}
					<div className='mt-auto flex flex-col gap-10'>
						<OfferNotes />
					</div>
				</div>
			</>
		</div>
	)
}
