import { FlowtyButton, useBreakpoint } from "ds-flowty"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ReactComponent as RefreshArrow } from "../../../../../assets/media/loadingArrow.svg"
import { StorefrontActivityFilter } from "../../../../../components/Activity/Filters/StorefrontActivityFilter"
import { ScrollToLoadMsg } from "../../../../../components/Loaders/ScrollToLoadMsg"
import { ActivityItem } from "../../../../../components/Shared/ActivityTableFields"
import SortableTable, {
	SortColumnOrNull,
} from "../../../../../components/SortableTable"
import { collectionStorefrontActivityFields } from "../../../../../components/SortableTable/PlatformActivity/collectionStorefrontActivityFields"
import { FilterBy } from "../../../../../components/UserProfile/Tables/Storefront/AllActivity/types"
import { DEFAULT_ACTIVITY_PAGE_SIZE } from "../hooks/useCollectionActivityTables"
import { useCollectionPage } from "../../../hooks/useCollectionPage"
import { actions as Mixpanel } from "../../../../../util/Mixpanel"
import { nftTypeAndIdToLocationData } from "flowty-common"

interface CollectionStorefrontActivityScreenProps {
	filterBy: FilterBy
	isLoading: boolean
	onFilterChangeFn: (filter: FilterBy) => void
	onSortChangeFn: (sort: SortColumnOrNull) => void
	sort: SortColumnOrNull
	allActivityDocs: ActivityItem[]
	belowTableRef: (node: HTMLElement | null) => void
	showFilter: boolean
	setShowFilter: (show: boolean) => void
	tablePage: number
}

export const CollectionStorefrontActivityScreen: React.FunctionComponent<
	CollectionStorefrontActivityScreenProps
> = ({
	filterBy,
	isLoading,
	onFilterChangeFn,
	onSortChangeFn,
	sort,
	allActivityDocs,
	belowTableRef,
	showFilter,
	setShowFilter,
	tablePage,
}) => {
	const navigate = useNavigate()
	const { collectionName } = useCollectionPage()

	const [selectedRow, setSelectedRow] = useState<{
		item: ActivityItem | null
		href: string
	} | null>(null)

	const holdThreshold = 500

	const [touchStartTimestamp, setTouchStartTimestamp] = useState(0)
	const [touchEndTimestamp, setTouchEndTimestamp] = useState(0)

	const breakpoint = useBreakpoint()

	useEffect(() => {
		// Only proceed if in a touch device environment based on breakpoint
		if (breakpoint === "xs" || breakpoint === "mobile") {
			if (touchStartTimestamp && touchEndTimestamp) {
				const touchDuration = touchEndTimestamp - touchStartTimestamp

				if (touchDuration >= holdThreshold) {
				} else {
					if (selectedRow) {
						navigate(selectedRow.href)
					}
				}

				setTouchStartTimestamp(0)
				setTouchEndTimestamp(0)
			}
		} else {
			if (selectedRow) {
				navigate(selectedRow.href)
			}
		}
	}, [
		touchStartTimestamp,
		touchEndTimestamp,
		selectedRow,
		breakpoint,
		navigate,
		holdThreshold,
	])

	useEffect(() => {
		if (filterBy.path) {
			Mixpanel.track(`${collectionName} Sale Activity Page Filtered`, {
				filter: filterBy,
			})
		}
		Mixpanel.track(`${collectionName} Activity page - Sale tab visited`, {
			tab: "Activity",
		})
	}, [filterBy])

	return (
		<div>
			<StorefrontActivityFilter
				showFilter={showFilter}
				setShowFilter={setShowFilter}
				filterBy={filterBy}
				setFilterBy={onFilterChangeFn}
			/>
			<div className='md:grid md:grid-cols-3 py-3'>
				<div className='hidden md:flex'></div>
				<div className='flex justify-center items-center'>
					<h1 className='text-3xl'>All Activity</h1>
				</div>
				<div className='flex justify-end w-[200px] justify-self-end'>
					<FlowtyButton
						variant='secondary'
						onClick={() => {
							setShowFilter(!showFilter)
						}}
						text={filterBy?.path ? "Filtering" : "Set Filter"}
					/>
				</div>
			</div>

			<div className='line-separator mb-3 md:mb-5 bg-gradient-to-r from-darkBg via-secondary to-darkBg' />

			<SortableTable
				onClickRow={({ e, item }) => {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					const event = e as any

					let buyer
					let seller

					if (item.type.toLowerCase().includes("offer")) {
						buyer = item.data?.storefrontAddress
						seller =
							typeof item?.data?.taker === "object" ? "-" : item?.data?.taker
					} else {
						buyer = item.data?.buyer
						seller = item.data?.storefrontAddress
					}

					if (event.target.classList[0] === "seller") {
						setSelectedRow({ href: `/user/${seller}`, item })
						return navigate(`/user/${seller}`)
					} else if (event.target.classList[0] === "buyer") {
						setSelectedRow({ href: `/user/${buyer}`, item })
					} else {
						const ld = nftTypeAndIdToLocationData(
							item?.data?.nftType || item?.data?.typeAndIDOffer?.nftType || "",
							item?.data?.nftID?.toString() ||
								item?.data?.typeAndIDOffer?.nftID ||
								""
						)

						setSelectedRow({
							href: `/asset/${ld.contract.address}/${ld.contract.name}/${ld.resourceName}/${ld.nftID}`,
							item,
						})
					}
				}}
				fields={collectionStorefrontActivityFields(navigate)}
				items={allActivityDocs}
				onSort={onSortChangeFn}
				initialSortConfig={sort}
				stickyHeaders
				composeRowHref={item => {
					const ld = nftTypeAndIdToLocationData(
						item?.data?.nftType || item?.data?.typeAndIDOffer?.nftType || "",
						item?.data?.nftID?.toString() ||
							item?.data?.typeAndIDOffer?.nftID ||
							""
					)

					return `/asset/${ld.contract.address}/${ld.contract.name}/${ld.resourceName}/${ld.nftID}`
				}}
			/>
			<div ref={belowTableRef}>
				{isLoading ? (
					<div className='h-10 w-full flex justify-center items-center'>
						<h2 className='justify-center items-center flex flex-row animate-pulse'>
							<RefreshArrow />
							Loading...
						</h2>
					</div>
				) : (
					tablePage * DEFAULT_ACTIVITY_PAGE_SIZE ===
						allActivityDocs?.length && <ScrollToLoadMsg />
				)}
			</div>
		</div>
	)
}
