import { inject, observer } from "mobx-react"
import React from "react"
import { LinkedAccounts } from "../components/FlowUI/LinkedAccounts/LinkedAccounts"
import { UserHeader } from "../components/FlowUI/UserHeader/UserHeader"
import { OpenSearchConnector } from "../components/OpenSearch/OpenSearchConnector"
import { PageHeader } from "../components/PageHeader"
import { User } from "../models/user"
import { AuthStoreProp } from "../stores/AuthStore"
import Layout from "./Layout"
import { ProfileOffers } from "./UserFlowScreen/components/ProfileOffers/ProfileOffers"
import { UserActions } from "./UserFlowScreen/components/UserActions/UserActions"
import { UserActivityTab } from "./UserFlowScreen/components/UserActivity/UserActivityTab"
import { UserCollectionTab } from "./UserFlowScreen/components/UserCollectionTab/UserCollectionTab"
import { TabWindow } from "./UserFlowScreen/components/UserPagesTab/TabWindow"

const ProfileFlowScreen: React.FC<AuthStoreProp> = ({ authStore }) => {
	if (!authStore?.loggedUser?.loggedIn) {
		window.location.replace("/")
	}

	const metaTitle = `${
		authStore?.loggedUser?.addr
			? "Flowty: Your NFT Assets"
			: "Flowty NFT: User Profile Page"
	}`

	const metaDescription =
		"Flowty is an open, full-featured NFT marketplace for collectors, traders, investors and creators on the Flow blockchain. Flowty's transaction capabilities range from buying and selling to NFT-backed loans and NFT rentals."

	const metaImage = "https://www.flowty.io/icons/icon192.png"

	const tabs = [
		{
			component: <UserCollectionTab profile={authStore?.loggedUser || null} />,
			name: "Collection",
		},
		{
			component: <ProfileOffers address={`${authStore?.loggedUser?.addr}`} />,
			name: "Offers",
		},
		{
			component: (
				<UserActivityTab address={authStore?.loggedUser?.addr || ""} />
			),
			name: "Activity",
		},
		{
			component: <UserActions />,
			name: "Loans + Rentals",
		},
	]

	return (
		<div className='min-h-screen bg-cover bg-no-repeat bg-center bg-fixed'>
			<OpenSearchConnector
				publicAccount={authStore?.loggedUser || null}
				endpoint='user'
			>
				{() => (
					<Layout>
						<div className='container'>
							<PageHeader
								metaTitle={metaTitle}
								metaDescription={metaDescription}
								metaImage={metaImage}
							/>
							<UserHeader profile={authStore?.loggedUser as User} />
							<div className='mt-4'>
								<LinkedAccounts user={authStore?.loggedUser as User} />
							</div>
							<TabWindow
								tabs={tabs}
								address={authStore?.loggedUser?.addr || ""}
							/>
						</div>
					</Layout>
				)}
			</OpenSearchConnector>
		</div>
	)
}

export const ProfileScreen = inject("authStore")(observer(ProfileFlowScreen))
