import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/solid"
import { SpotPrice } from "flowty-common"
import { Fragment } from "react"
import { ConfettiAnimation } from "../../ConfettiAnimation"
import { useFlowtyDropModalContext } from "../contexts/FlowtyDropModalContext"
import { DropAvatar } from "./DropAvatar/DropAvatar"
import { DropFailure } from "./DropModalState/DropFailure"
import { DropPurchasing } from "./DropModalState/DropPurchasing"
import { DropSuccess } from "./DropModalState/DropSuccess"
import { DropModalViewNav } from "./DropModalViewNav/DropModalViewNav"
import { DropQuantityTotal } from "./DropQuantityTotal/DropQuantityTotal"

interface DropModalViewProps {
	isOpen: boolean
	dropImage: string
	phasePrice: number
	spotPrice: SpotPrice | null
	mintCount: number
	increaseMintCount: () => void
	decreaseMintCount: () => void
	updateMintCount: (value: number) => void
}

export const DropModalView: React.FunctionComponent<DropModalViewProps> = ({
	isOpen,
	phasePrice,
	spotPrice,
	dropImage,
	mintCount,
	increaseMintCount,
	decreaseMintCount,
	updateMintCount,
}) => {
	const {
		error,
		sealed,
		isLoading,
		transactionExplorerLink,
		dropPageLink,
		collectionDisplayName,
		onCloseModal,
	} = useFlowtyDropModalContext()
	return (
		<Transition appear show={isOpen} as={Fragment}>
			<Dialog as='div' className='relative z-[1000]' onClose={onCloseModal}>
				<Transition.Child
					as={Fragment}
					enter='ease-out duration-300'
					enterFrom='opacity-0'
					enterTo='opacity-100'
					leave='ease-in duration-200'
					leaveFrom='opacity-100'
					leaveTo='opacity-0'
				>
					<div className='fixed inset-0 bg-black bg-opacity-75' />
				</Transition.Child>
				<div
					data-testid='flowty-listing-modal'
					className='fixed max-md:top-[40px] inset-0 overflow-y-auto'
				>
					<div className='flex min-h-full items-center justify-center text-center'>
						<Transition.Child
							as={Fragment}
							enter='ease-out duration-300'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='ease-in duration-0'
							leaveFrom='opacity-0 scale-100'
							leaveTo='opacity-0 scale-95'
						>
							<Dialog.Panel className='w-full md:w-[511px] h-full flex flex-col transform rounded-lg bg-[#04070B] text-left align-middle shadow-xl transition-all border border-[#495057]'>
								<Dialog.Title
									as='div'
									className='border-b border-[#495057] px-[40px] py-[30px] md:py-8 w-full flex justify-between items-center'
								>
									<p className='text-lg md:text-2xl leading-6 text-white font-black'>
										{"Purchase Confirmation"}{" "}
									</p>
									<XIcon
										onClick={onCloseModal}
										className='w-6 h-6 md:h-8 md:w-8 cursor-pointer fill-white'
									/>
								</Dialog.Title>
								<div
									className={`w-full py-[32px] px-[40px] h-[300px] flex justify-center items-center`}
								>
									{!error.drop && !isLoading.drop && !sealed.drop && (
										<div className={`flex flex-col w-full gap-[32px] py-[8px]`}>
											<DropAvatar
												imgUrl={dropImage}
												price={phasePrice}
												name={collectionDisplayName}
											/>
											<DropQuantityTotal
												price={phasePrice}
												spotPrice={spotPrice}
												mintCount={mintCount}
												increaseMintCount={increaseMintCount}
												decreaseMintCount={decreaseMintCount}
												updateMintCount={updateMintCount}
											/>
										</div>
									)}

									{isLoading.drop && (
										<div className='h-full flex flex-col items-center justify-center'>
											<DropPurchasing
												transactionExplorerLink={transactionExplorerLink}
											/>
										</div>
									)}

									{sealed.drop && (
										<div className='h-full flex flex-col items-center justify-center'>
											<DropSuccess
												transactionExplorerLink={transactionExplorerLink}
												dropPageLink={dropPageLink}
												collectionDisplayName={collectionDisplayName}
											/>
											<ConfettiAnimation />
										</div>
									)}

									{error.drop && (
										<div className='h-full flex flex-col items-center justify-center'>
											<DropFailure />
										</div>
									)}
								</div>
								<div className='h-full w-full flex justify-center items-center border-t border-[#495057] px-[40px] py-[20px]'>
									<DropModalViewNav />
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}
